import './QuestionTile.scss';
import React, { useState, useRef, } from 'react';
import CreateQuestion from '../../question/CreateQuestion/CreateQuestion';
import VideoInput from '../../../components/VideoInput/VideoInput';
import InfoHelper from '../../../components/InfoHelper/InfoHelper';
import TextAreaInput from '../../../components/TextAreaInput/TextAreaInput';
import { Accordion } from 'react-bootstrap';

interface IQuestionTileProps {
  questionEntity: IQuestion;
  onEdit: (question: IQuestion) => void;
}
const QuestionTile: React.FC<IQuestionTileProps> = ({
  questionEntity,
  onEdit,
}) => {
  const { id, question, answer, isAssessment } = questionEntity;
  const [isEdit, setIsEdit] = useState(true);
  
  const isVideoQuestion = question.type === 'Video';

  const toggleRef = useRef(null);
  
  // const handleAreaClick = () => {
  //   setIsEdit(true);
  // }
  
  return (
    <div className={`question-tile-ctn`}>
      <Accordion.Header>
        {!isVideoQuestion && <>
          <div className={'flex-row'}>
            {question.value}
          </div>
        </>}
        {isVideoQuestion && <div className={'flex-row'}>
          Video question
        </div>}
      </Accordion.Header>
      <Accordion.Body>
        {!isEdit && <div className={`preview-ctn`}>
          <div className="question-section">
            {!isVideoQuestion && <>
              <div className={'flex-row mb-2'}>
                Question:
              </div>
              <TextAreaInput value={question.value} disabled />
            </>}
            {isVideoQuestion && <VideoInput initialValue={question.value} disabled />}
          </div>
          <hr/>
          <div className="answer-section">
            {isAssessment && <>
              <div className={'flex-row mb-2'}>
                How do you see a candidate's perfect response?
                <InfoHelper text={`Gonna be used for evaluation. Not visible to candidates.`} />
              </div>
              <TextAreaInput value={answer.ref?.value} disabled />
            </>}
          </div>
        </div>}
        <Accordion.Button as={'div'} ref={toggleRef} style={{display: 'none'}} />
        {isEdit && <CreateQuestion onClose={() => (toggleRef.current as any).click()} questionInfo={questionEntity} onQuestionData={onEdit} />}
      </Accordion.Body>
    </div>
  )
}

export default QuestionTile;