import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Placement } from 'react-bootstrap/types';
import React from 'react';
import './TooltipComponent.scss';

interface ITooltipComponentProps {
  text: string | React.ReactNode;
  children: React.ReactNode;
  position?: Placement;
  className?: string;
}

const TooltipComponent: React.FC<ITooltipComponentProps> = ({
    text,
    children,
    position = 'top',
    className,
}) => {
  
  return (
    <OverlayTrigger
      placement={position}
      delay={{ show: 150, hide: 300 }}
      overlay={
        <Tooltip className={`tooltip ${className}`}>
          <span>
            {text}
          </span>
        </Tooltip>
      }
    >
      <span>
        {children}
      </span>
    </OverlayTrigger>
  );
}

export default TooltipComponent;
