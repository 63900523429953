import Spinner from 'react-bootstrap/Spinner';
import { FC } from 'react';
import './Loading.scss';

interface ILoadingProps {
  hideText?: boolean;
  text?: string;
  className?: string;
  fullscreen?: boolean;
}
const Loading: FC<ILoadingProps> = ({
  hideText,
  text = 'Loading...',
  className,
  fullscreen,
}) => {
  const fullscreenClass = fullscreen ? 'fullscreen' : '';
  
  return (
    <div className={`loading-ctn ${fullscreenClass} ${className}`}>
      <Spinner animation={'border'} />
      {!hideText && <div className={'text'}>
        {text}
      </div>}
    </div>
  )
}

export default Loading;