import React from 'react';
import ModalComponent from "../ModalComponent/ModalComponent";
import { CheckboxOption } from '../CheckboxGroup/CheckboxGroup'

interface IConfirmationPopupProps {
  show: boolean;
  message: string | React.ReactNode;
  onConfirm?: () => any;
  onCancel?: () => any;
  onCheckboxCheck?: (options: string[]) => any;
  confirmLabel?: string;
  cancelLabel?: string;
  confirmColor?: 'success' | 'danger';
  cancelColor?: 'secondary' | 'primary';
  size?: 'sm' | 'lg' | 'xl';
  bgColor?: string;
  isStyled?: boolean;
  checkboxesOptions?: CheckboxOption[];
}

const ConfirmationPopup: React.FC<IConfirmationPopupProps> = ({
  show, message, isStyled,
  onConfirm, onCancel, onCheckboxCheck,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  confirmColor = 'success',
  cancelColor = 'secondary',
  size = 'sm', checkboxesOptions,
}) => {
  return (
    <ModalComponent
      show={show}
      mainButtonTitle={confirmLabel}
      secondaryButtonTitle={cancelLabel}
      mainButtonVariant={confirmColor}
      secondaryButtonVariant={cancelColor}
      onClose={onCancel}
      buttonAction={onConfirm}
      size={size}
      isStyled={isStyled}
      checkboxesOptions={checkboxesOptions}
      onCheckboxCheck={onCheckboxCheck}
    >
      <div>
        {message}
      </div>
    </ModalComponent>
  )
}
export default ConfirmationPopup;