import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { setAuthUserAction } from '../redux/user.slice';
import { useCallback } from 'react';
import { logout, firebaseUserToAppUser } from '../../../services/auth.service';
import {
  signInWithPopup,
  UserCredential,
  GoogleAuthProvider,
  getAuth,
  signInWithEmailAndPassword, createUserWithEmailAndPassword, updateProfile, 
  sendEmailVerification,
  getAdditionalUserInfo,
} from 'firebase/auth';
import { auth } from '../../../services/firebase.service';
import { useNavigate } from 'react-router-dom';
import useQueryParams from '../../../hooks/useQueryParams';
import { defaultRoute } from '../../routing/Routing';
import useProductTracking from '../../../hooks/useProductTracking';
import useNotifications from '../../notification/hooks/useNotifications';
import { httpPut } from '../../../services/http.service';

const authGoogleProvider = new GoogleAuthProvider();

const redirectUrl = `${window.location.origin}/login`;
const actionCodeSettings = {
  url: `${redirectUrl}`,
};

const useAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const tracker = useProductTracking();
  const authUser = useSelector((state: RootState) => state.authUser?.user);
  const { showNotification } = useNotifications();
  
  const setAuthUser = (user: IUser | null) => {
    dispatch(setAuthUserAction(user));
  };
  
  const signOut = useCallback(async () => {
    await logout();
    tracker.setUserId(undefined);
    setAuthUser(null);
  }, []);
  
  const googleLogin = () => {
    signInWithPopup(auth, authGoogleProvider).then(async (userCredential: UserCredential) => {
      const info = getAdditionalUserInfo(userCredential);
      
      if (info?.isNewUser) {
        tracker.track('google-singup', {
          email: userCredential?.user?.email,
        });
        await httpPut('/user', {
          email: userCredential.user.email,
          name: userCredential.user.displayName,
        });
      }
      await onFirebaseUser(userCredential);
    }).catch((error: any) => {
      console.error('g auth', error);
    });
  }

  const resendVerificationEmail = async () => {
    const user = getAuth().currentUser;

    try {
      if (user) {
        await sendEmailVerification(user, actionCodeSettings);
        showNotification({
          color: 'success',
          content: 'Verification email has been sent',
        });
      }
    } catch (error) {
      console.log('error ',error);
      showNotification({
        color: 'danger',
        content: 'Error occured',
      });
    }
  }
  
  const onFirebaseUser = async (userCredential: UserCredential) => {
    const user = userCredential.user;
    tracker.setUserId(user.uid);
    const accessToken = await user.getIdToken();
    setAuthUser(firebaseUserToAppUser(user, accessToken));

    if (user.emailVerified) {
      navigate(queryParams.get('redirectTo') ?? defaultRoute);
    } else {
      navigate('/email-verification');
    }
  }
  
  const basicLogin = (email: string, password: string) => {
    const auth = getAuth();
    const formattedEmail = email.trim().toLowerCase();
    
    signInWithEmailAndPassword(auth, formattedEmail, password).then(onFirebaseUser).catch((error: any) => {
      const errorCode = error?.code;

      if (errorCode === 'auth/wrong-password') {
        showNotification({
          color: 'danger',
          content: 'Wrong password',
        });
      } else if (errorCode === 'auth/user-not-found') {
        showNotification({
          color: 'danger',
          content: 'This email is not registered',
        });
      } else {
        showNotification({
          color: 'danger',
          content: 'Error occurred',
        });
      }

      console.log('signup error', error);
    });
  }
  
  const basicSignup = (name: string, email: string, password: string) => {
    const auth = getAuth();
    const formattedEmail = email.trim().toLowerCase();
    
    return new Promise<void>(async (resolve, reject) => {
      createUserWithEmailAndPassword(auth, formattedEmail, password)
      .then(async (userCredential: UserCredential) => {
        tracker.track('email-signup', {
          email: formattedEmail,
        });
        await updateProfile(userCredential.user, {
          displayName: name,
        });
        await httpPut('/user', {
          email,
          name,
        });

        await sendEmailVerification(userCredential.user, actionCodeSettings);

        await onFirebaseUser(userCredential);
        resolve();
      }).catch((error: any) => {
        const errorCode = error?.code;
        
        if (errorCode === 'auth/invalid-email') {
          showNotification({
            color: 'danger',
            content: 'Invalid email',
          });
        } else {
          showNotification({
            color: 'danger',
            content: 'Error occurred',
          });
        }
        
        console.log('signup error', error);
        reject(error);
      });
    });
  }
  
  return {
    authUser,
    setAuthUser,
    signOut,
    googleLogin,
    basicLogin,
    basicSignup,
    resendVerificationEmail,
  }
}

export default useAuth;