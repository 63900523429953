import useFileUpload, { IUploadCallbackData } from './useFileUpload';
import {useState, useEffect, ChangeEvent, FC} from 'react';
import { IoCloudUploadOutline, IoVideocamOutline } from 'react-icons/io5';
import pdfLogo from './assets/pdf-icon.png';
import './FileUpload.scss';
import CardMenu from '../../components/CardMenu/CardMenu';
import ConfirmationPopup from '../../components/ConfirmationPopup/ConfirmationPopup';

interface IFileUploadProps {
  label?: string;
  onData?: (url: string) => void;
  readonly?: boolean;
  value?: string;
  types?: Array<'doc' | 'video' | 'image'>;
  maxFileSize?: number;
}

const MAX_SIZE_IN_MB = 200;
const DEFAULT_MAX_FILE_SIZE = MAX_SIZE_IN_MB * (1024 ** 2);
const VIDEO_EXT = '.mp4,.webm,.ogg,';
const IMAGE_EXT = '.png,.jpg,.jpeg,';
const FILE_EXT = '.pdf,';

const FileUpload: FC<IFileUploadProps> = ({
  label = '',
  onData,
  readonly,
  value,
  types = ['doc', 'image'],
  maxFileSize = DEFAULT_MAX_FILE_SIZE,
}) => {
  const { upload, progress } = useFileUpload();
  const [url, setUrl] = useState(value || '');
  const [file, setFile] = useState<File | null>(null);
  const [warningModalShow, setWarningModalShow] = useState(false);
  
  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile && selectedFile?.size >= maxFileSize) {
      setWarningModalShow(true);
      event.target.value = '';
      return;
    }

    setFile(selectedFile || null);
  }
  
  const onUploadComplete = ({ url, gsUrl }: IUploadCallbackData) => {
    setUrl(url);
    onData?.(url);
  }
  
  useEffect(() => {
    if (file) {
      upload(file, onUploadComplete);
    }
  }, [file])

  const renderPreviewByFileType = () => {
    const isPdf = file?.type === 'application/pdf' || url.includes('.pdf');
    const isVideo = file?.type.includes('video');
  
    if (isPdf || isVideo) {
      return (
        url && (
          <div className='preview-wrapper'>
            {isPdf && <img src={pdfLogo} alt="pdf" className='pdf-icon' />}
            {isVideo && <IoVideocamOutline fontSize={50} />}
            <div className='file-name'>{file?.name}</div>
          </div>
        )
      );
    }
  
    return (
      url && <img src={url} alt="img" className='image' />
    );
  };

  const handleClickDelete = () => {
    setUrl('');
    setFile(null);
  }

  useEffect(() => {
    setUrl(url);
    onData?.(url);
  }, [url])



  const getExtentionsByType = () => {
    let allowedExtensions = '';

    if (types.includes('doc')) {
      allowedExtensions += FILE_EXT;
    }
  
    if (types.includes('image')) {
      allowedExtensions += IMAGE_EXT;
    }
  
    if (types.includes('video')) {
      allowedExtensions += VIDEO_EXT;
    }
  
    return allowedExtensions;
  }

  return (
    <>
      {label && <label className="mb-2">{label}</label>}
      <div className="file-upload">
        {url && !readonly && <CardMenu
          onDelete={handleClickDelete}
        />}
        {url && readonly && <CardMenu
          onDownload={() => downloadFile(url)}
        />}
        {!url && !progress &&
          <>
            <label htmlFor="file-input" className="label">
              <div className="label-icon"><IoCloudUploadOutline /></div>
              <div className="label-text">Select file</div>
            </label>
            <input
              id="file-input"
              className='file-input'
              type="file"
              accept={getExtentionsByType()}
              onChange={handleFileUpload}
              disabled={readonly}
            />
          </>
        }
        {!url && <div className="progress-label">
        {progress ? `${Math.round(progress)}%` : ''}
        </div>}
        {renderPreviewByFileType()}
        <ConfirmationPopup 
          show={warningModalShow}
          message='Please upload files smaller than 200MB'
          onConfirm={() => {setWarningModalShow(false)}}
          confirmLabel='Got it!'
          onCancel={() => {setWarningModalShow(false)}}
        />
      </div>
    </>
  );
}

export const downloadFile = (href: string, name: string = 'test') => {
  const link = document.createElement("a");
  link.setAttribute('href', href);
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  link.remove();
  // fetch(href)
  //     // check to make sure you didn't have an unexpected failure (may need to check other things here depending on use case / backend)
  //     .then(resp => resp.status === 200 ? resp.blob() : Promise.reject('something went wrong'))
  //     .then(blob => {
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement('a');
  //       a.style.display = 'none';
  //       a.href = url;
  //       a.download = name;
  //       document.body.appendChild(a);
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //     })
}

export default FileUpload;

