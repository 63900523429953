import React, { useState, useEffect } from 'react';
import CheckboxInput from '../../../../components/CheckboxInput/CheckboxInput'
import Form from 'react-bootstrap/Form';
import './ContactsCheckboxes.scss'

export interface ICheckboxOption {
  val: string | number;
  displayVal: string;
  id: string;
  checked: boolean;
  isDisabled?:boolean;
  isRequired?: boolean;
}

interface IContactsCheckboxesProps {
  options: ICheckboxOption[];
  onInput?: (checkedOptions: ICheckboxOption[]) => void;
  className?: string;
  disabled?: boolean;
  value?: string[] | string;
}

const ContactsCheckboxes: React.FC<IContactsCheckboxesProps> = ({
  options, onInput,
  className = '',
  disabled, value,
}) => {
  const [allCheckboxes, setAllCheckboxes] = useState<ICheckboxOption[]>(options);

  const requiredCheckboxId = (id: string) => `${id}-required`;
  
  const handleCheckboxChange = (checkboxOptions: ICheckboxOption, isRequiredChange = false) => {
    const idOfChangedCheckbox = checkboxOptions.id;
  
    const updatedCheckboxes = allCheckboxes.map((checkbox: ICheckboxOption) => {
      if (checkbox.id === idOfChangedCheckbox || (isRequiredChange && requiredCheckboxId(checkbox.id) === idOfChangedCheckbox)) {
        const updatedCheckbox = {
          ...checkbox,
          checked: isRequiredChange ? checkbox.checked : checkboxOptions.checked,
        };

        if (isRequiredChange) {
          updatedCheckbox.isRequired = checkboxOptions.checked;
        }

        return updatedCheckbox;
      }

      return checkbox;
    });
  
    setAllCheckboxes(updatedCheckboxes);
    onInput?.(updatedCheckboxes);
  };

  useEffect(() => {
    if (value?.length && options?.length) {
      const updatedCheckboxes = allCheckboxes.map((checkbox: ICheckboxOption) => {
        return {
          ...checkbox,
          checked: (value?.length ? value.includes(checkbox.id) : value === checkbox.id) || checkbox.checked,
        }
      });
      
      setAllCheckboxes(updatedCheckboxes);
    }
  }, [value]);

  useEffect(() => {
    setAllCheckboxes(currentCheckboxes => {
      const updatedCheckboxes = options.map(option => {
        const existingCheckbox = currentCheckboxes.find(checkbox => checkbox.id === option.id);

        if (existingCheckbox) {
          return {
            ...option,
            checked: existingCheckbox.checked,
            isRequired: existingCheckbox.isRequired ?? option.isRequired,
          };
        }

        return option;
      });

      return updatedCheckboxes;
    });
  }, [options]);
  
  const renderContactsCheckboxes = () => {
    return allCheckboxes?.map((option) => (
      <div className="checkbox-pair" key={option.id}>
        <CheckboxInput
          id={option.id}
          label={option.displayVal}
          checked={option.checked}
          val={option.val}
          onInput={handleCheckboxChange}
          disabled={option.isDisabled ?? disabled}
        />
        {(option.checked && !option?.isDisabled) && (
          <CheckboxInput
            id={requiredCheckboxId(option.id)}
            label="Required"
            checked={option.isRequired ?? false}
            val="Required"
            onInput={(updatedOption) => handleCheckboxChange(updatedOption, true)}
            disabled={option.isDisabled ?? disabled}
          />
        )}
      </div>
    ))
  }
  
  return (
    <div className={`checkbox-group ${className}`}>
      <p className='mb-4'>Which contacts do you want candidates to share?</p>
      <Form>
        {renderContactsCheckboxes()}
      </Form>
    </div>
  );
};

export default ContactsCheckboxes;
