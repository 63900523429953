import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, set, get, onValue } from 'firebase/database';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCBkro4q-UDqZUnTQaf6idBvQPedzZwHqI",
  authDomain: "intrvw-app.firebaseapp.com",
  databaseURL: "https://intrvw-app-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "intrvw-app",
  storageBucket: "intrvw-app.appspot.com",
  messagingSenderId: "466125955001",
  appId: "1:466125955001:web:692a441ccc0c8419a8e068",
  measurementId: "G-RZDX3QQRMN"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const db = getDatabase(firebaseApp);
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);

export const getRef = (path: string) => {
  return ref(db, path)
}

export const refSet = async (path: string, data: Record<string, any>) => {
  return set(getRef(path), data);
}

export const refGetValue = async (path: string) => {
  const snapshot = await get(getRef(path));
  
  if (snapshot.exists()) {
    return snapshot.val();
  }
  
  return null;
}

export const getTimeOffset = async () => {
  return new Promise<number>((resolve) => {
    const ref = getRef('.info/serverTimeOffset');
    onValue(ref, (snap) => {
      const offset = snap.val();
      resolve(offset || 0);
    });
  });
  
}