import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./ModalComponent.scss"
import useCurrentCompany from '../../modules/company/hooks/useCurrentCompany';
import CheckboxGroup from '../CheckboxGroup/CheckboxGroup';
import { CheckboxOption } from '../CheckboxGroup/CheckboxGroup'

interface IModalComponentProps {
  children: React.ReactNode;
  title?: string;
  size?: "sm" | "lg" | "xl";
  secondaryButtonTitle?: string;
  mainButtonTitle?: string;
  mainButtonVariant?: "success" | "danger"
  secondaryButtonVariant?: "secondary" | "primary"
  show: boolean;
  backdrop?: boolean | 'static'
  onClose?: () => any;
  buttonAction?: () => any;
  onCheckboxCheck?: (options: string[]) => any;
  isStyled?: boolean; 
  hasHeader?: boolean;
  checkboxesOptions?: CheckboxOption[];
  bodyClassName?: string;
}

const ModalComponent: React.FC<IModalComponentProps> = ({
  children, title,
  mainButtonTitle,
  show, onClose,
  buttonAction,
  backdrop = true,
  size = "sm", isStyled = false,
  secondaryButtonTitle = 'Cancel',
  mainButtonVariant = "success",
  secondaryButtonVariant = "secondary",
  hasHeader = false, checkboxesOptions,
  onCheckboxCheck, bodyClassName,
}) => {
  const hideClose = !onClose;
  const { companyInfo } = useCurrentCompany();
  const [checkedOptions, setCheckedOptions] = useState<string[]>([])
  const colors = companyInfo?.colors;

  const rootStyle: any = {
    '--bg-color': colors?.contentBackground,
    '--text-color': colors?.text,
    backgroundColor: colors?.background || 'transparent',
  }
  
  useEffect(() => {
    onCheckboxCheck?.(checkedOptions);
  }, [checkedOptions, onCheckboxCheck]);

  return (
    <>
      <Modal
        className='modal'
        backdrop={backdrop}
        show={show}
        onHide={onClose}
        size={size}
        centered
        style={isStyled ? rootStyle : null}
      >
          {hasHeader && <Modal.Header closeButton={!hideClose}>
            {title && <Modal.Title>{title}</Modal.Title>}
          </Modal.Header>}
          <Modal.Body className={`body ${bodyClassName}`}>
            {children}
          </Modal.Body>
          {buttonAction && <Modal.Footer className='footer'>
            <div className='buttons'>
              {!hideClose && <Button variant={secondaryButtonVariant} onClick={onClose}>
              {secondaryButtonTitle}
              </Button>}
              <Button variant={mainButtonVariant} onClick={buttonAction}>
                {mainButtonTitle}
              </Button>
            </div>
            {checkboxesOptions && <CheckboxGroup 
              options={checkboxesOptions}
              onInput={setCheckedOptions}
            />}
          </Modal.Footer>}
      </Modal>
    </>
  )
}

export default ModalComponent;
