import React from 'react';
import './CompanyProfileLayout.scss';
import { Accordion, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CompanyHeader from '../../../components/CompanyHeader/CompanyHeader';
import CompanyContacts from '../../../components/CompanyContacts/CompanyContacts';


interface ICompanyProfileLayoutProps {
  data: any;
  isPreview?: boolean; 
}

const CompanyProfileLayout: React.FC<ICompanyProfileLayoutProps> = ({ data, isPreview = false }) => {
  const { 
    title, tagline, 
    description, contacts, 
    colors, interviews,
  } = data;

  const navigate = useNavigate();
  
  const rootStyle: any = {
    '--banner-color': colors?.banner,
    '--background-color': colors?.background,
    '--content-background-color': colors?.contentBackground,
    '--text-color': colors?.text,
    '--icons-color': colors?.icons,
    '--buttons-color': colors?.buttons,
    '--buttons-text-color': colors?.buttonsText,
    '--banner-image': colors?.typeOfBanner === 'color-id' ? 'none' : `url(${colors?.backgroundImage})`,
  }

  const handleApplyButtonClick = (interviewId: string) => {
    navigate(`/interview/${interviewId}`);
  }

  const renderPositions = (interviews: any) => {
    if (interviews?.length) {
      const activePositions = interviews
        .filter((interview: any) => interview.isActive)
        .map((interview: any) => (
          <Accordion.Item eventKey={interview.id}> 
            <Accordion.Header>{interview.title}</Accordion.Header>
            <Accordion.Body>
              {interview.textIntro}
              <Button 
                className='apply-button'
                onClick={() => handleApplyButtonClick(interview.id)}
                disabled={isPreview}
              >
                Apply
              </Button>
            </Accordion.Body>
          </Accordion.Item>
        ));
  
      if (activePositions.length > 0) {
        return activePositions;
      }
    }
  
    return (
      <p className='no-vacancies-warning'>
        The company has no open vacancies yet
      </p>
    );
  };

  return (
    <div className="CompanyProfileLayout" style={rootStyle}>
      <div className="scroll-area">
        <div className="content-area">
          <CompanyHeader settingsData={data}/>
          <div className="title-area">
            <CompanyContacts contactsInfo={contacts} iconsColor={colors?.icons}/>
            <div className="title mb-3">
              {title}
            </div>
            <div className="tagline mb-3">
              {tagline}
            </div>
            <div className="description mb-3">
              {description}
            </div>
          </div>
          <hr/>
          <div className="positions-ctn">
            <div className="positions-title">
              Open positions
            </div>
            <Accordion className='mb-5'>
              {renderPositions(interviews)}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyProfileLayout;