import { auth } from './firebase.service';
import { User } from "firebase/auth";
import { store } from '../store';

export const getToken = async (): Promise<string | undefined> => {
  // console.log('###a', auth, auth?.currentUser, auth?.currentUser?.getIdToken())
  return auth?.currentUser?.getIdToken() ;
}
export const firebaseUserToAppUser = (user: User | null, accessToken?: string): IUser => ({
  email: user?.email ?? '',
  displayName: user?.displayName ?? '',
  uid: user?.uid ?? '',
  accessToken,
  avatarUrl: user?.photoURL ?? '',
  isEmailVerified: user?.emailVerified ?? false,
});

export const logout = async () => {
  return auth.signOut();
}
