import axios from 'axios';
import { store } from '../store';
import { showNotificationAction } from '../modules/notification/redux/notification.slice';
import { getToken } from './auth.service';

const serverUrl = process.env.REACT_APP_API_URL;

interface IHttpOptions {
  queryObject?: Record<string, any>,
  successMessage?: string,
  headersObj?: Record<string, any>,
  skipAuth?: boolean;
}

export const httpPost = async (
    url: string,
    data?: Record<string, any>,
    options: IHttpOptions = {},
) => {
  return httpWithBody('post', url, data, options);
};

export const httpPut = async (
  url: string,
  data?: Record<string, any>,
  options: IHttpOptions = {},
) => {
  return httpWithBody('put', url, data, options);
};

export const httpDelete = async (
  url: string,
  options: IHttpOptions = {},
) => {
  return httpWithoutBody('delete', url, options);
}

export const httpGet = async (
  url: string,
  options: IHttpOptions = {}
) => {
  return httpWithoutBody('get', url, options);
}

const httpWithBody = async (
  method: 'post' | 'put',
  url: string,
  data?: Record<string, any>,
  options: IHttpOptions = {},
) => {
  const {
    queryObject,
    successMessage,
    headersObj,
    skipAuth,
  } = options;
  
  const headers = {
    ...headersObj,
  };
  
  if (!skipAuth) {
    const token = await getToken();
    
    if (!token) {
      window.location.href = '/login';
    }
    headers.Authorization = `Bearer ${token}`;
  }
  
  try {
    const fullUrl = `${serverUrl}${url}`;
    const options = {
      params: queryObject,
      headers,
    };
    
    const res = await axios[method](fullUrl, data, options);
    
    if (res?.data && res.status.toString().startsWith('2')) {
      if (successMessage) {
        store.dispatch(showNotificationAction({
          color: 'success',
          content: successMessage,
        }));
      }
      
      return res.data;
    } else if (res?.status === 401) {
      window.location.href = '/login';
    }
  } catch (e: any) {
    if (e?.response?.status === 401) {
      window.location.href = '/login';
    } else {
      store.dispatch(showNotificationAction({
        color: 'danger',
        content: e?.response?.data?.error || 'Error occurred...',
      }));
      console.error('Error http: ', e);
    }
  }
}

const httpWithoutBody = async (
  method: 'get' | 'delete',
  url: string,
  options: IHttpOptions = {},
) => {
  const {
    queryObject,
    successMessage,
    headersObj,
    skipAuth,
  } = options;
  
  const headers = {
    ...headersObj,
  };
  
  if (!skipAuth) {
    const token = await getToken();
    
    if (!token) {
      window.location.href = '/login';
    }
    headers.Authorization = `Bearer ${token}`;
  }
  
  try {
    const res = await axios[method](`${serverUrl}${url}`, {
      params: queryObject,
      headers,
    });
    
    if (res?.data && res.status.toString().startsWith('2')) {
      if (successMessage) {
        store.dispatch(showNotificationAction({
          color: 'success',
          content: successMessage,
        }));
      }
      
      return res.data;
    } else if (res?.status === 401) {
      window.location.href = '/login';
    }
  } catch (e: any) {
    if (e?.response?.status === 401) {
      window.location.href = '/login';
    } else {
      store.dispatch(showNotificationAction({
        color: 'danger',
        content: e?.response?.data?.error || 'Error occurred...',
      }));
      console.error('Error http: ', e);
    }
  }
}

