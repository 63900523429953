import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  values: [] as IInterview[],
  isLoading: false,
};


export const interviewsSlice = createSlice({
  name: 'interviews',
  initialState,
  reducers: {
    setInterviewsAction: (state, action: PayloadAction<IInterview[]>) => {
      state.values = action.payload;
    },
    setIsInterviewsLoadingAction: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setInterviewsAction ,
  setIsInterviewsLoadingAction,
} = interviewsSlice.actions;

export default interviewsSlice.reducer;