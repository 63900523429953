import './UserMenu.scss';
import useAuth from '../auth/hooks/useAuth';
import { ListGroup } from 'react-bootstrap';

const UserMenu = () => {
  const { signOut } = useAuth();
  
  return (
    <ListGroup className={`UserMenu-ctn`} variant="flush">
      {/*<ListGroup.Item>My profile</ListGroup.Item>*/}
      <ListGroup.Item onClick={signOut}>Log out</ListGroup.Item>
    </ListGroup>
  );
}

export default UserMenu;