import React, { useState, useCallback } from 'react';
import Form from 'react-bootstrap/Form';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import './TypeaheadInput.scss';


export type SelectValueType = string;
export type Option = Record<'val' | 'displayVal' | 'id', SelectValueType>;

interface ITypeaheadInput {
  options?: Option[];
  selectedOptions?: Option[];
  label?: string;
  className?: string;
  disabled?: boolean;
  multiple?: boolean;
  onInput?: (val: SelectValueType | SelectValueType[]) => void;
}

const TypeaheadInput: React.FC<ITypeaheadInput> = ({
  label,
  onInput,
  className = '',
  options,
  disabled,
  multiple,
  selectedOptions,
}) => {
  const [inputValue, setInputValue] = useState<Option[]>(selectedOptions || []);
  
  const onChange = useCallback((selected: Record<string, any>) => {
    setInputValue(selected as any);
    const value = multiple ? selected : selected?.[0];
    
    onInput?.(value);
  }, [onInput]);
  
  return (
    <Form.Group className={`TypeaheadInput ${className}`}>
      {label && <Form.Label>{label}</Form.Label>}
      {options && <Typeahead
        id={'typeahead'}
        labelKey={'displayVal'}
        options={options}
        selected={inputValue}
        onChange={onChange}
        disabled={disabled}
        multiple={multiple}
        clearButton
      />}
    </Form.Group>
  )
};

export default TypeaheadInput;