import React, { useEffect, useState, useRef } from 'react';
import { Duration } from 'luxon';

interface ITimerProps {
  start: boolean;
  limit?: number; // seconds
  onLimit: () => void;
}

const formatSeconds = (seconds: number) => {
  return Duration.fromObject({ seconds: seconds }).toFormat('mm:ss');
}

let elapsed = 0;

const Timer: React.FC<ITimerProps> = ({
  start,
  limit = 60,
  onLimit,
}) => {
  const [displayCurrent, setDisplayCurrent] = useState('00:00');
  const [displayLimit, setDisplayLimit] = useState(formatSeconds(limit));
  const intervalRef = useRef<any>(null);
  
  
  useEffect(() => {
    if (start) {
      intervalRef.current = setInterval(() => {
        elapsed++;
        setDisplayCurrent(formatSeconds(elapsed));
        
        if (elapsed >= limit) {
          clear();
          onLimit?.();
        }
      }, 1000);
    } else {
      clear();
    }
  }, [start]);
  
  const clear = () => {
    clearInterval(intervalRef.current);
    setDisplayCurrent(formatSeconds(0));
    setDisplayLimit(formatSeconds(limit));
    elapsed = 0;
  }
  
  return (
    <div className={'timer-ctn'}>
      {`${displayCurrent} / ${displayLimit}`}
    </div>
  )
}

export default Timer;