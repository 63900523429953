import React from 'react';
import './CompanyHeader.scss';
import useCurrentCompany from '../../modules/company/hooks/useCurrentCompany';

interface ICompanyHeaderProps {
  settingsData?: Record<string, any>
}

const CompanyHeader: React.FC<ICompanyHeaderProps> = ({ settingsData }) => {
  const { companyInfo } = useCurrentCompany();
  const logoUrl = settingsData ? settingsData?.logoUrl : companyInfo?.logoUrl;
  const title = settingsData ? settingsData?.title : companyInfo?.title;
  const colors = settingsData ? settingsData?.colors : companyInfo?.colors;

  const rootStyle: any = {
    '--banner-color': colors?.banner,
    '--background-color': colors?.contentBackground,
    '--banner-image': colors?.typeOfBanner === 'color-id' ? 'none' : `url(${colors?.backgroundImage})`,
  } 

  return (
    <>
      <div className="custom-bg" style={rootStyle}>
      <div className="logo-ctn">
        <img
          src={logoUrl}
          alt={title}
          referrerPolicy='no-referrer'
        />
      </div>
      </div>
      </>
  )
}

export default CompanyHeader;