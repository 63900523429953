import React, { useState, FC, useRef, useCallback } from 'react';
import './QuestionPreview.scss';
import DynamicInput from '../../../components/DynamicInput';
import Form from 'react-bootstrap/Form';
import { fixVideoDuration } from '../../media/mediaUtils';

interface IQuestionPreviewProps {
  questionEntity: any;
  onInput?: (answerType: IBasePropType, questionId: string, value: string) => void;
  storedAnswer?: any;
}

const QuestionPreview: FC<IQuestionPreviewProps> = ({
  questionEntity,
  onInput, storedAnswer,
}) => {
  const { answer, question, id } = questionEntity;
  const questionVideoRef = useRef<any>(null);

  const modifiedAnswer = {
    ...answer,
    value: storedAnswer
  }
  
  const onVideoReady = () => {
    fixVideoDuration(questionVideoRef.current);
  }
  
  const renderQuestion = () => {
    return (
      <div className={`question-ctn mb-3`}>
        {question.type === 'Text'
          ?
          <Form.Label>
            {question.value}
          </Form.Label>
          :
          <video
            width={'100%'}
            src={question.value}
            ref={questionVideoRef}
            onLoadedMetadata={onVideoReady}
            controls
          />
        }
        <hr/>
      </div>
    );
  }
  
  const handleOnInput = useCallback((val: string) => {
    onInput?.(answer?.type, id, val);
  }, [onInput, answer?.type, id]);
  
  const renderAnswer = () => {
    return (
      <div className={`answer-ctn mb-3`}>
        <DynamicInput data={modifiedAnswer} onInput={handleOnInput} />
      </div>
    );
  }
  
  if (!question || !answer) {
    return null;
  }
  
  return (
    <div className="QuestionPreview">
      {renderQuestion()}
      {renderAnswer()}
    </div>
  )
}

export default QuestionPreview;