import React from "react";
import { FaEnvelope, FaLinkedin } from "react-icons/fa6";
import './CompanyContacts.scss'
import useCurrentCompany from "../../modules/company/hooks/useCurrentCompany";

interface ICompanyContactsProps {
  contactsInfo?: Record<string, any>
  iconsColor?: string;
}

const CompanyContacts: React.FC<ICompanyContactsProps> = ({ contactsInfo, iconsColor }) => {
  const { companyInfo } = useCurrentCompany();
  const contacts = contactsInfo || companyInfo?.contacts;
  const color = iconsColor || companyInfo?.colors?.icons;
  const rootStyle: any = {
    '--icons-color': color,
  }

  return (
    <div className="contacts-ctn" style={rootStyle}>
    {contacts?.email && <a href={`mailto:${contacts?.email}`}>
      <FaEnvelope />
    </a>}
    {contacts?.linkedin && <a
      target={'_blank'}
      href={contacts?.linkedin}
    >
      <FaLinkedin />
    </a>}
  </div>
  )
}

export default CompanyContacts;