import Image from 'react-bootstrap/Image';
import useAuth from '../auth/hooks/useAuth';
import './UserAvatar.scss';
import React from 'react';
import DefaultUser from '../../components/svg/DefaultUser';

const UserAvatar = () => {
  const { authUser } = useAuth();
  
  if (!authUser) {
    return null;
  }
  
  return (
    <div className={`UserAvatar-ctn`}>
      {authUser.avatarUrl && <Image
        height={`100%`}
        src={authUser.avatarUrl}
        roundedCircle
        referrerPolicy='no-referrer'
      />}
      {!authUser.avatarUrl && <DefaultUser />}
    </div>
  );
}

export default UserAvatar;