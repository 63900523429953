import React from 'react';
import './App.scss';
import AuthProvider from './modules/auth/AuthProvider';

const App = () => {
  return (
    <AuthProvider />
  );
}

export default App;
