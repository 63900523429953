import React, { FC, useState, useCallback, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import useProductTracking from '../../../hooks/useProductTracking';
import { httpGet } from '../../../services/http.service';
import { getAuth, signInAnonymously } from 'firebase/auth';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Loading from '../../../components/Loading/Loading';
import { setHSUserEmail } from '../../../services/hubspotService';
import { ICheckboxOption } from '../../interviews/CreateInterview/ContactsCheckboxes/ContactsCheckboxes';

export type candidatesContactsType = Record<string, string>;

interface IPreSignUpProps {
  className?: string;
  onInput?: (candidatesContacts: candidatesContactsType) => void;
  contacts: ICheckboxOption[];
}

const PreSignUp: FC<IPreSignUpProps> = ({
                                          onInput,
                                          className = '',
                                          contacts,
                                        }) => {
  const { id: interviewId } = useParams();
  const tracker = useProductTracking();
  const [candidatesContacts, setCandidatesContacts] = useState<candidatesContactsType>({});
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const displayVal = e.target.dataset.displayval;

      if (displayVal) {
        setCandidatesContacts((prevData) => ({ ...prevData, [displayVal]: value }));
      } 
    },
    []
  );
  
  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setValidated(true);
    const form = e.currentTarget;
    
    if (!form.checkValidity()) {
      return;
    }
    
    setIsLoading(true);

    const email = candidatesContacts['Email'];
    console.log('email',email)
    
    const response = await httpGet('/interview-results/validate-email', {
      queryObject: { email, interviewId },
      skipAuth: true,
    });
    
    if (response?.status === 'ok') {
      const auth = getAuth();
      
      signInAnonymously(auth)
      .then(() => {
        tracker.track(`Candidate Start`, {
          email,
          interviewId,
        });
        setHSUserEmail(email);
        onInput?.(candidatesContacts);
      })
      .catch((error) => {
        const errorCode = error?.code;
        const errorMessage = error?.message;
        console.error("Error on candidate signup", errorCode, errorMessage);
      });
    }
    
    setIsLoading(false);
  }
  
  return (
    <div className="contacts-block">
      <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
      {contacts
        .filter((contact) => contact.checked)
        .sort((a, b) => Number(b.isRequired) - Number(a.isRequired))
        .map((contact) => (
          <Form.Group
            key={contact.id}
            className={`TextInput mb-2 ${className}`}
          >
            <Form.Label>
              {contact.displayVal}{contact.isRequired ? ' *' : ''}
            </Form.Label>
            <Form.Control
              data-displayval={contact.displayVal}
              type={contact.id === 'email' ? 'email' : 'text'}
              name={contact.id}
              value={candidatesContacts[contact.displayVal] || ''}
              onChange={handleInputChange}
              required={contact.isRequired}
            />
            <Form.Control.Feedback type="invalid">
              {contact.isRequired && `${contact.displayVal} is required.`}
            </Form.Control.Feedback>
          </Form.Group>
      ))}
        <Button
          variant={'success'}
          type={'submit'}
          className={'mt-3 mb-3'}
        >
          Start interview
        </Button>
      </Form>
      {isLoading && <Loading hideText fullscreen />}
    </div>
  );
}

export default PreSignUp;