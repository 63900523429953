import { useCallback } from 'react';
import { httpPost, httpDelete, httpPut } from '../../../services/http.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { setIsInterviewsLoadingAction, setInterviewsAction } from '../redux/interviews.slice';

const useInterviews = () => {
  const dispatch = useDispatch();
  const interviews = useSelector((state: RootState) => state.interviews.values);
  const isInterviewLoading = useSelector((state: RootState) => state.interviews.isLoading);
  
  const createInterview = useCallback(async (data: any) => {
    setInterviewsLoading(true);
    let isSuccess = false;
    const interviewsResponse = await httpPost(
      '/interviews',
      data,
      {
        successMessage: 'Interview successfully created!',
      },
    );
    if (interviewsResponse) {
      isSuccess = true;
      setInterviews(interviewsResponse);
    }
    setInterviewsLoading(false);
    
    return isSuccess;
  }, []);
  
  const updateInterview = useCallback(async (data: any, skipLoading: boolean = false) => {
    if (!skipLoading) {
      setInterviewsLoading(true);
    }
    let isSuccess = false;
    const interviewsResponse = await httpPut(
      `/interviews/${data?.id}`,
      data,
      {
        successMessage: 'Interview successfully updated!',
      },
    );
    if (interviewsResponse) {
      isSuccess = true;
      setInterviews(interviewsResponse);
    }
    if (!skipLoading) {
      setInterviewsLoading(false);
    }
    return isSuccess;
  }, []);
  
  const deleteInterview = useCallback(async (id: string) => {
    setInterviewsLoading(true);
    let isSuccess = false;
    const interviewsResponse = await httpDelete(
      `/interviews/${id}`,
      {
        successMessage: 'Interview successfully deleted!',
      },
    );
    if (interviewsResponse) {
      isSuccess = true;
      setInterviews(interviewsResponse);
    }
    setInterviewsLoading(false);
    
    return isSuccess;
  }, []);
  
  const setInterviews = useCallback((interviews: any) => {
    dispatch(setInterviewsAction(interviews));
  }, []);
  
  const setInterviewsLoading = useCallback((isLoading: boolean) => {
    dispatch(setIsInterviewsLoadingAction(isLoading));
  }, []);
  
  return {
    interviews,
    isInterviewLoading,
    createInterview,
    setInterviews,
    setInterviewsLoading,
    deleteInterview,
    updateInterview,
  };
}

export default useInterviews;