import React, { useState, useCallback, useEffect, useMemo } from 'react';
import Stack from 'react-bootstrap/Stack';
import TextInput from '../../../components/TextInput/TextInput';
import BasePropsPicker from '../../baseProp/BasePropsPicker';
import SelectInput, { SelectValueType } from '../../../components/SelectInput/SelectInput';
import './CreateQuestion.scss';
import CreateOptions from '../../../components/CreateOptions/CreateOptions';
import useBaseProps from '../../baseProp/hooks/useBaseProps';
import RadioInput from '../../../components/RadioInput/RadioInput';
import VideoInput from '../../../components/VideoInput/VideoInput';
import { Button, Form } from 'react-bootstrap';
import NumberInput from '../../../components/NumberInput/NumberInput';
import { v4 as uuidv4 } from 'uuid';
import QuestionImportancePicker from '../QuestionImportancePicker/QuestionImportancePicker';
import useAuth from '../../auth/hooks/useAuth';
import useProductTracking from '../../../hooks/useProductTracking';
import VoiceInput from '../../../components/VoiceInput/VoiceInput';

export type Option = {
  val: SelectValueType;
  displayVal: SelectValueType;
  id: SelectValueType;
  isCorrect?: boolean;
}

const ASSESSMENT_ID = 'assessment-id';
const INFORMATIONAL_ID = 'informational-id';

const AT_LEAST_ID = 'at-least-id';
const LESS_THAN_ID = 'less-than-id';
const EQUALS_ID = 'equals-id';

const optionsRadioIsAssessment = [{
  val: 'Informational question',
  displayVal: 'Informational question',
  id: INFORMATIONAL_ID,
}, {
  val: 'Assessment question',
  displayVal: 'Assessment question',
  id: ASSESSMENT_ID,
}];

const optionsNumberIsAssessment = [{
  val: 'At least',
  displayVal: 'At least',
  id: AT_LEAST_ID,
}, {
  val: 'Less than',
  displayVal: 'Less than',
  id: LESS_THAN_ID,
}, {
  val: 'Equals',
  displayVal: 'Equals',
  id: EQUALS_ID,
}]

interface ICreateQuestionProps {
  onQuestionData: (data: any) => void
  questionInfo?: IQuestion | null;
  onClose?: () => any;
  buttonAction?: () => any;
}

const CreateQuestion: React.FC<ICreateQuestionProps> = ({ onQuestionData, questionInfo, onClose, buttonAction }) => {
  const [selectedQuestionType, setSelectedQuestionType] = useState<SelectValueType>(questionInfo?.question.type || '');
  const [textQuestionValue, setTextQuestionValue] = useState(questionInfo?.question?.value || '');
  const [selectedAnswerType, setSelectedAnswerType] = useState<SelectValueType>(questionInfo?.answer.type || '');
  const [refValue, setRefValue] = useState(questionInfo?.answer?.ref?.value || '');
  const [refNumberCondition, setRefNumberCondition] = useState(questionInfo?.answer?.ref?.condition || '');
  const [options, setOptions] = useState<Option[]>(questionInfo?.answer.options || []);
  const [isAssessment, setIsAssessment] = useState(questionInfo?.isAssessment || false);
  const [question, setQuestion] = useState<any>(null);
  const [videoUrl, setVideoUrl] = useState<string | null>(questionInfo?.question?.value || null);
  const [questionTitle, setQuestionTitle] = useState(questionInfo?.question?.title);
  const [validated, setValidated] = useState(false);
  const [importance, setImportance] = useState(questionInfo?.answer?.importance || '1');
  const [maxVideoAnswerDuration, setMaxVideoAnswerDuration] = useState<number | string>(questionInfo?.answer?.maxVideoDuration || '1');
  const [isRecognizingInProgress, setIsRecognizingInProgress] = useState(false);

  const id = useMemo(uuidv4, []);
  const { questionTypesOptions } = useBaseProps();
  const { authUser } = useAuth()
  const tracker = useProductTracking();
  const userId = authUser?.uid;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    setValidated(true);
    
    if (!form?.checkValidity()) {
      return;
    }
    onQuestionData?.(question);
  };
  
  useEffect(() => {
    if (questionInfo?.answer.type && selectedAnswerType !== questionInfo?.answer.type) {
      resetAnswerInputs();
    }
  }, [selectedAnswerType]);

  const resetAnswerInputs = useCallback(() => {
    setRefValue('');
  }, []);
  
  useEffect(() => {
    if (questionInfo?.question.type && selectedQuestionType !== questionInfo?.question.type) {
      resetQuestionInputs();
    }
  }, [selectedQuestionType]);
  
  const resetQuestionInputs = useCallback(() => {
    setTextQuestionValue('');
    setVideoUrl(null);
  }, []);
  
  const onQuestionKindChanged = (id: string) => {
    setIsAssessment(id === ASSESSMENT_ID);
  }
  
  const onVideoReceived = (url: string) => {
    setVideoUrl(url);
  }

  const renderQuestionInputByType = useCallback(() => {
    switch (selectedQuestionType) {
      case 'Text':
        return (
        <div className='question-ctn'>      
          <TextInput 
            placeholder='Type your question here'
            onInput={setTextQuestionValue}
            className={`mb-3 ${'question-input'}`}
            value={textQuestionValue}
            required
            validationLabel={'Please enter your question'}
            disabled={isRecognizingInProgress}
          />
          <VoiceInput onRecognizingProgress={setIsRecognizingInProgress} onSpeechRecognized={setTextQuestionValue} className='mb-3'/>
        </div>)
      case 'Video':
        return <VideoInput
          initialValue={questionInfo?.question?.value || ''}
          onVideoUrl={onVideoReceived}
          noPreview={false}
        />;
    }
  }, [questionInfo?.question?.value, selectedQuestionType, textQuestionValue]);
  
  const renderAnswerInputByType = useCallback(() => {
    switch (selectedAnswerType) {
      case 'Video':
        return <NumberInput 
          label={'Max recoding duration (minutes)'}
          onInput={setMaxVideoAnswerDuration}
          value={maxVideoAnswerDuration.toString()}
          defaultValue='1'
          minValue={1}
          maxValue={10}
        />
      case 'Text': 
        if (isAssessment) {
          return <TextInput
            onInput={setRefValue}
            className='mb-3'
            label={'Perfect answer for your question'}
            placeholder="Type here"
            value={refValue}
            required
            validationLabel={'Please enter perfect answer'}
          />;
        }
      break;
      case 'Select':
        return <CreateOptions 
                  onQuestions={setOptions}
                  isAssessmentTypeQuestion={isAssessment}
                  initialOptions={questionInfo?.answer.options}
                />;
      case 'Checkbox':
        return <CreateOptions 
                  onQuestions={setOptions}
                  isAssessmentTypeQuestion={isAssessment} 
                  initialOptions={questionInfo?.answer.options}
                />;
      case 'Radio':
        return <CreateOptions 
                  onQuestions={setOptions}
                  isAssessmentTypeQuestion={isAssessment}
                  initialOptions={questionInfo?.answer.options}
                />
      case 'File':
        if (isAssessment) {
          return `File inputs can not be evaluated. Please, use question type 'Informational'`
        }
        break;
      case 'Number':
        if (isAssessment) {
          return (
            <>
              <label>{'Perfect answer for your question'}</label>
              <Stack gap={3} direction="horizontal" className='mt-2 mb-3 number-ref-row'>
                <SelectInput
                  className='condition-select' 
                  options={optionsNumberIsAssessment} 
                  onInput={setRefNumberCondition}
                  value={questionInfo?.answer?.ref?.condition}
                />
                <NumberInput 
                  onInput={setRefValue}
                  value={refValue}
                  required
                  validationLabel='Number is required'
                />
              </Stack>
            </>
          )
        }
      break;
      default:
        return 'Type is not supported';
    }
  }, [selectedAnswerType, isAssessment, questionInfo, refValue]);

  useEffect(() => {
    if (!isAssessment) {
      setRefValue('');
      setRefNumberCondition('');
    }

    if (selectedAnswerType !== 'Number') {
      setRefNumberCondition('');
    }
  }, [isAssessment, selectedAnswerType]);

  useEffect(() => {
    setTextQuestionValue(questionInfo?.question.value || '');
  }, [questionInfo]);
  
  useEffect(() => {
    const questionValue = selectedQuestionType === 'Text' 
    ? textQuestionValue
    : videoUrl;

    const questionSection = {
      type: selectedQuestionType,
      value: questionValue,
      title: questionTitle,
      attachments: [],
    }
    
    const answerSection = {
      type: selectedAnswerType,
      options: options,
      ref: {
        value: refValue,
        condition: selectedAnswerType === 'Number' ? refNumberCondition : '',
      },
      importance,
      maxVideoDuration: selectedAnswerType === 'Video' ? Number(maxVideoAnswerDuration) : 0,
    }
    
    const formInfo = {
      question: questionSection,
      answer: answerSection,
      isAssessment,
      id: questionInfo?.id || id,
    }
    
    setQuestion(formInfo);
  }, [
    refValue, refNumberCondition,
    selectedAnswerType, isAssessment,
    selectedQuestionType, textQuestionValue, options,
    videoUrl, questionTitle, questionInfo?.id,
    importance, maxVideoAnswerDuration,
  ]);

  const onTrack = () => {
    if(!questionInfo) {
      tracker.track(`create interview - save question`, {
        userId,
      });
    }
  }
  
  return (
    <div className={'CreateQuestion'}>
      <Form 
        noValidate 
        validated={validated} 
        onSubmit={handleSubmit}
      >
        {/* <Stack gap={3} className={'create-area'}> */}
        {/*  <TextInput*/}
        {/*      label={`Question's title (not visible to candidates)`}*/}
        {/*      onInput={setQuestionTitle}*/}
        {/*      className='mb-3'*/}
        {/*      value={questionInfo?.question?.title || ''}*/}
        {/*      required*/}
        {/*      validationLabel={`Please enter question's title`}*/}
        {/*  />*/}
          <SelectInput
            label='Select your question type'
            className='mb-3'
            options={questionTypesOptions}
            onInput={setSelectedQuestionType}
            value={questionInfo?.question.type}
          />
          {renderQuestionInputByType()}
          <RadioInput
            name={'isAssessment'}
            options={optionsRadioIsAssessment}
            onInput={onQuestionKindChanged}
            selectedByDefault={isAssessment ? ASSESSMENT_ID : INFORMATIONAL_ID}
          />
          <hr/>
          <BasePropsPicker
            className='mb-3' 
            onInput={setSelectedAnswerType} 
            label={'Select expected answer type'}
            initialValue={questionInfo?.answer.type}
          />
          {renderAnswerInputByType()}
          {isAssessment && <QuestionImportancePicker 
            answerType={selectedAnswerType} 
            importanceData={importance} 
            onInput={setImportance}
          />}
          <div className='buttons-group'>
            <Button variant='secondary' onClick={onClose}>Cancel</Button>
            <Button variant="success" type="submit" onClick={onTrack}>
              {questionInfo ? 'Update' : 'Create'}
            </Button>
          </div>
        {/* </Stack> */}
      </Form>
      {/* <Stack gap={3}  className="preview-area">
        <h1>Preview</h1>
        {question && <QuestionPreview questionEntity={question} />}
      </Stack> */}
    </div>
  );
};

export default CreateQuestion;