import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import './Signup.scss';
import { Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import LogoVertical from '../../../components/svg/LogoVertical';
import { FaGoogle } from 'react-icons/fa6';
import React, { useState, ChangeEvent, useEffect } from 'react';
import Loading from '../../../components/Loading/Loading';

const Signup = () => {
  const { basicSignup, googleLogin } = useAuth();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const handleGoogleLogin = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    googleLogin();
  }
  
  const handleEmailSignup = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);
    await basicSignup(name, email, password);
    setIsLoading(false);
  }
  
  const handleNameInput = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }
  
  const handleEmailInput = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }
  
  const handlePasswordInput = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }

  useEffect(() => {
    const isDisabled = !password || !email || !name;
    setIsButtonDisabled(isDisabled);
  },[name, password, email]);
  
  return (
    <Stack gap={3} className={'sign-up-ctn'}>
      {isLoading && <Loading text={'Creating new account...'} fullscreen />}
      <div className="logo">
        <LogoVertical colorMode={'dark'}/>
      </div>
      <Button
        variant="light"
        className={'google-btn mb-3'}
        onClick={handleGoogleLogin}
      >
        <FaGoogle /> Sign up with Google
      </Button>
      <hr className={'mb-2'}/>
      <Form className={'login-form'} onSubmit={handleEmailSignup}>
        <Form.Group className="mb-3" controlId="formBasicName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            value={name}
            onChange={handleNameInput}
            type="text"
            maxLength={40}
            placeholder="Enter your name..."
          />
        </Form.Group>
        <Form.Group className="email-group mb-3" controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={handleEmailInput}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            minLength={6}
            maxLength={40}
            value={password}
            onChange={handlePasswordInput}
          />
        </Form.Group>
        
        <Button
          disabled={isButtonDisabled}
          className={'login-btn mb-3'}
          variant="outline-light"
          type={'submit'}
        >
          Sign up
        </Button>
        <div className={'signup-link'}>
          <span>Already have an account?</span>
          <Link to="/login">
            Log in
          </Link>
        </div>
      </Form>
    </Stack>
  );
};

export default Signup;