import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import { FC, useState, useEffect } from "react";
import { Accordion, Button, Form } from "react-bootstrap";
import CreateQuestion from "../../question/CreateQuestion/CreateQuestion";
import QuestionsList from "../QuestionsList/QuestionsList";
import './QuestionsEditor.scss'
import useAuth from "../../auth/hooks/useAuth";
import useProductTracking from "../../../hooks/useProductTracking";
import { useTour } from '@reactour/tour'
import { InterviewsTutorialSteps } from '../../tutorials/interviewsTutorial';

interface IQuestionsEditorProps {
  initialValue: IQuestion[];
  onSave: (questions: IQuestion[]) => void;
}

const remapOrder = (arr: IQuestion[]) => {
  return arr?.map((item, i) => {
    return {
      ...item,
      ordinalNum: i,
    };
  })
}

const QuestionsEditor: FC<IQuestionsEditorProps> = ({ initialValue, onSave }) => {
  const [questions, setQuestions] = useState<IQuestion[]>(initialValue ?? []);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false);
  const [showWarningMessage, setShowWarningMessage] = useState<boolean>(false);
  const { authUser } = useAuth()
  const tracker = useProductTracking();
  const userId = authUser?.uid;
  const { setCurrentStep } = useTour();
  
  const handleDragAndDrop = (results: any) => {
    const {source, destination, type} = results;
    
    if (!destination) return;
    
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) return;
    
    if (type === 'questions') {
      const reorderedQuestions = [...questions];
      const sourceIndex = source.index;
      const destinationIndex = destination.index;
      
      const [removedQuestion] = reorderedQuestions.splice(sourceIndex, 1);
      reorderedQuestions.splice(destinationIndex, 0, removedQuestion);
      
      setQuestions(remapOrder(reorderedQuestions));
      // onQuestions?.(remapOrder(reorderedQuestions));
    }
  }
  
  const onNewQuestion = (question: IQuestion) => {
    setQuestions([...questions, question]);
    setIsCreating(false);
  }
  
  const onEdit = (question: IQuestion) => {
    const updatedQuestions = questions.map((oldQuestion: IQuestion) => {
      if (oldQuestion.id === question.id) {
        return question;
      }
      return oldQuestion;
    });
    
    setQuestions(updatedQuestions);
  }
  
  const handleSaveClick = () => {
    onSave(questions);
  }

  useEffect(() => {
    setIsSaveButtonDisabled(questions.length === 0);
  }, [questions]);
  
  // const removeQuestion = (id: string) => {
  //   const updatedQuestions = questions.filter((field) => field.id !== id);
  //   const mapped = remapOrder(updatedQuestions);
  //   setQuestions(mapped);
  //   onQuestions?.(mapped);
  // };

  const onAddQuestionClick = () => {
    const isValidNumberOfQuestions = questions.length < 10;
    setIsCreating(isValidNumberOfQuestions);
    setShowWarningMessage(!isValidNumberOfQuestions);

    tracker.track(`create interview - add question`, {
      userId,
    });
  }
  
  return (
    <>
      <div className='question-list'>
        <DragDropContext onDragEnd={handleDragAndDrop}>
          <Form.Label className={'create-questions-title'}>
            Create questions for your candidates:
          </Form.Label>
          <hr/>
          <Droppable droppableId="ROOT" type="questions">
            {(provided: any) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <Accordion>
                  <QuestionsList onEdit={onEdit} questions={questions} />
                </Accordion>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {showWarningMessage && (
          <p className="warning-message">
            You can create up to 15 questions
          </p>
        )}
      </div>
      {!isCreating && <>
        <Button
          variant={'light'}
          className={`mb-3`}
          onClick={onAddQuestionClick}
        >
          Add question
        </Button>
        <Button
          className={`mb-3`}
          variant={'success'}
          onClick={handleSaveClick}
          disabled={isSaveButtonDisabled}
        >
          Save Interview
        </Button>
      </>}
      {isCreating && <CreateQuestion onClose={() => setIsCreating(false)} onQuestionData={onNewQuestion} />}
    </>
  );
}

export default QuestionsEditor;
