import React, { useEffect, useState } from 'react';
import SelectInput from '../../../components/SelectInput/SelectInput';

const devicesToOptions = (devices: MediaDeviceInfo[]) => {
  return devices?.map(d => {
    return {
      val: `${d.deviceId}`,
      id: `${d.deviceId}_${d.kind}`,
      displayVal: d.label,
    }
  });
}
interface IVideoInputProps {
  onCameraChange?: (deviceId: any) => void;
  onMicChange?: (deviceId: any) => void;
  onSpeakerChange?: (deviceId: any) => void;
  disabled?: boolean;
}

const DevicePicker: React.FC<IVideoInputProps> = ({
  onCameraChange,
  onMicChange,
  onSpeakerChange,
  disabled,
}) => {
  const [mics, setMics] = useState<MediaDeviceInfo[]>([]);
  const [cams, setCams] = useState<MediaDeviceInfo[]>([]);
  const [speakers, setSpeakers] = useState<MediaDeviceInfo[]>([]);
  
  useEffect(() => {
    const onLoad = async () => {
      const updatedDevices = await navigator.mediaDevices.enumerateDevices();
      setMics(updatedDevices.filter(d => d.kind === 'audioinput'));
      setCams(updatedDevices.filter(d => d.kind === 'videoinput'));
      setSpeakers(updatedDevices.filter(d => d.kind === 'audiooutput'));
    }
    onLoad();
  }, []);
  
  return (
    <div className="device-input-ctn mb-3">
      {onMicChange && <SelectInput
        className={'mb-3'}
        onInput={onMicChange}
        label={'Choose microphone'}
        options={devicesToOptions(mics)}
        disabled={disabled}
      />}
      {onCameraChange && <SelectInput
        onInput={onCameraChange}
        className={'mb-3'}
        label={'Choose camera'}
        options={devicesToOptions(cams)}
        disabled={disabled}
      />}
      {onSpeakerChange && <SelectInput
        onInput={onSpeakerChange}
        className={'mb-3'}
        label={'Choose audio output device'}
        options={devicesToOptions(speakers)}
        disabled={disabled}
      />}
    </div>
  );
}

export default DevicePicker;