import { Draggable } from "@hello-pangea/dnd";
import { FC } from "react";
import { Accordion } from "react-bootstrap";
import { MdDragIndicator } from "react-icons/md";
import QuestionTile from "../QuestionTile/QuestionTile";
import './QuestionsList.scss'

interface IQuestionsListProps {
  questions: IQuestion[];
  onEdit: (q: IQuestion) => void;
}
const QuestionsList: FC<IQuestionsListProps> = ({ questions, onEdit }) => {
  
  const renderList = () => {
    return questions.map((question: IQuestion, i: number) => (
      <Draggable
        key={question.id}
        draggableId={question.id}
        index={i}
        disableInteractiveElementBlocking
      >
        {(provided: any) => (
          <div
            className='question-row'
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <span {...provided.dragHandleProps}>
              <MdDragIndicator fontSize={24} />
            </span>
            <span style={{ margin: (i + 1) > 9 ? '0 7px 0 8px' : '0 16px 0 8px' }}>{i+1}.</span>
            <Accordion.Item eventKey={`${i}`}>
              <QuestionTile onEdit={onEdit} questionEntity={question} />
            </Accordion.Item>
            {/*{questions.length > 1 && (*/}
            {/*  <>*/}
            {/*    <Button*/}
            {/*      variant="danger"*/}
            {/*      className='button-outline'*/}
            {/*      size="sm"*/}
            {/*      type="button"*/}
            {/*      onClick={() => removeQuestion(question.id)}*/}
            {/*    >*/}
            {/*      <TfiClose/>*/}
            {/*    </Button>*/}
            {/*  </>*/}
            {/*)}*/}
          </div>
        )}
      </Draggable>
    ));
  }
  
  return (
    <div>
      {renderList()}
    </div>
  )
}

export default QuestionsList;
