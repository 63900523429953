
const DefaultUser = () => {
  return (
    <div
      className={'defaultUser'}
      style={{
        borderRadius: '50%',
        border: '1px solid #dedede',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        backgroundColor: '#002956',
      }}
    >
      <svg viewBox="0 0 36 36" style={{'marginTop': '5px'}}>
        <path
          fill={'#dedede'}
          d="M22.325 21.547v-2.224a1.539 1.539 0 0 1 .392-.993A11.746 11.746 0 0 0 25.388 11c0-5.547-2.942-8.647-7.387-8.647s-7.47 3.222-7.47 8.647a11.873 11.873 0 0 0 2.8 7.329 1.54 1.54 0 0 1 .392.993v2.214a1.528 1.528 0 0 1-1.333 1.542c-8.931.777-10.326 6.886-10.326 9.3 0 .268.032 1.321.051 1.585h31.857S34 32.642 34 32.374c0-2.309-1.577-8.4-10.346-9.286a1.535 1.535 0 0 1-1.329-1.541z"
        />
      </svg>
    </div>
    
  );
}

export default DefaultUser;