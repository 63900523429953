import React, { useState, FC } from 'react';
import './InterviewsToolbar.scss';
import Button from 'react-bootstrap/Button';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import CreateInterview from '../CreateInterview/CreateInterview';
import SelectInput, { Option } from '../../../components/SelectInput/SelectInput';
import ConfirmationPopup from '../../../components/ConfirmationPopup/ConfirmationPopup';
import useAuth from '../../auth/hooks/useAuth';
import useProductTracking from '../../../hooks/useProductTracking';
import useBilling from '../../billing/useBilling';
import { useTour } from '@reactour/tour'
import { InterviewsTutorialSteps } from '../../tutorials/interviewsTutorial';

export type Sorting = 'dateDesc' | 'dateAsc';
export const SORTS: Record<string, Sorting> = {
  dateDesc: 'dateDesc',
  dateAsc: 'dateAsc',
}
const sortOptions: Option[] = [{
  val: SORTS.dateDesc,
  displayVal: 'Newest first',
  id: SORTS.dateDesc,
}, {
  val: SORTS.dateAsc,
  displayVal: 'Oldest first',
  id: SORTS.dateAsc,
}];

interface IInterviewsToolbarProps {
  onSortChanged?: (sort: Sorting) => void;
}

const InterviewsToolbar: FC<IInterviewsToolbarProps> = ({
  onSortChanged,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [confirmationShow, setConfirmationShow] = useState(false);
  const { authUser } = useAuth()
  const tracker = useProductTracking();
  const userId = authUser?.uid;
  const { isAccessRestricted } = useBilling();
  const { setCurrentStep, setIsOpen } = useTour();
  
  const handleSortChanged = (val: string) => {
    onSortChanged?.(val as Sorting);
  }
  
  const onClose = () => {
    setModalShow(false);
  }

  const onCreateInterviewClick = () => {
    setModalShow(true);
    setCurrentStep(InterviewsTutorialSteps.MODAL_FIRST_STEP);

    tracker.track(`create interview - step 1`, {
      userId,
    });
  }

  const onCreateInterviewCloseConfirm = () => {
    setModalShow(false);
    setConfirmationShow(false);
    tracker.track(`create interview - closed`, {
      userId,
    });
  }
  
  return (
    <div className={`assignment-toolbar-ctn`}>
      <Button 
        onClick={onCreateInterviewClick} 
        disabled={isAccessRestricted} 
        className='create-intrvw-btn' 
        id='interview-tutorial-first-step' 
      >
        Create Interview
      </Button>
      <ModalComponent
        hasHeader={true} 
        title='Create Interview'
        mainButtonTitle='Create'
        size="lg"
        backdrop={'static'}
        show={modalShow}
        onClose={() => setConfirmationShow(true)}
        bodyClassName={'interview-tutorial-second-step'}
      >
          <CreateInterview onClose={onClose} />
      </ModalComponent>
      <SelectInput
        label={`Sort by`}
        options={sortOptions}
        onInput={handleSortChanged}
      />
      <ConfirmationPopup 
        show={confirmationShow}
        message='Are you sure you want to close this window? The created interview will not be saved'
        onConfirm={() => {
          onCreateInterviewCloseConfirm();
          setIsOpen(false);
        }}
        onCancel={() => setConfirmationShow(false)}
      />
    </div>
  );
}

export default InterviewsToolbar;