import React, { useEffect, ReactElement, useState } from 'react';
import { httpGet } from '../../services/http.service';
import Loading from '../../components/Loading/Loading';
import useCurrentCompany from './hooks/useCurrentCompany';
import { useParams } from 'react-router-dom';

interface ICurrentCompanyLoaderProps {
  children: ReactElement;
}

const PublicCompanyPageLoader: React.FC<ICurrentCompanyLoaderProps> = ({ children }) => {
  const { setCurrentCompany } = useCurrentCompany();
  const { companyCode } = useParams();
  const [isLoading, setLoading] = useState<boolean>(true);
  
  useEffect(() => {
    const load = async () => {
      const companyInfo = await httpGet(`/company/${companyCode}`, {
        skipAuth: true,
      });
      setCurrentCompany(companyInfo || {});

      setLoading(false);
    };

    load();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return children;
};

export default PublicCompanyPageLoader;

