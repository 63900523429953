import React, { useEffect, useState } from "react";
import FileUpload from "../../fileUpload/FileUpload";
import TextInput from "../../../components/TextInput/TextInput";
import TextAreaInput from "../../../components/TextAreaInput/TextAreaInput";
import './ContentTab.scss'
import CopyUrl from "../../../components/CopyUrl/CopyUrl";

export type ContentTabDataType = Record<string, any>;

interface IContentTabProps {
  onData: (data: ContentTabDataType) => void;
  data?: any;
}

const ContentTab: React.FC<IContentTabProps> = ({ data, onData }) => {
  const {
    logoUrl: logoURL, 
    title, tagline, 
    description, code,
  } = data;

  const [logoUrl, setLogoUrl] = useState(logoURL || '');
  const [companyName, setCompanyName] = useState(title || '');
  const [companyCode, setCompanyCode] = useState(code || '');
  const [taglineText, setTaglineText] = useState(tagline || '');
  const [companyDescription, setCompanyDescription] = useState(description || '');

  useEffect(() => {
    const contentTabData = {
      logoUrl,
      companyName,
      companyCode,
      taglineText,
      companyDescription,
    }

    onData(contentTabData);
  },[
    logoUrl, companyName, 
    companyCode, taglineText,
    onData, companyDescription,
  ]);
  
  const onCompanyNameChange = (name: string) => {
    setCompanyName(name);
    setCompanyCode(name.toLowerCase().replace(/[^a-z0-9]/gi, '-'));
  }

  return (
    <div className="content-tab">
      <div className="logo mb-3">
        <FileUpload 
          onData={setLogoUrl} 
          label="Company's logo"
          value={logoUrl}
          types={['image']}
        /> 
      </div>
      <div className="name mb-3">
        <TextInput 
          label="Name"
          value={companyName}
          onInput={onCompanyNameChange}
          placeholder="Type here"
          maxLength={40}
        />
      </div>
      <div className="url mb-3">
        <label>Public URL</label>
        <span className="url-input-row">
          <span>https://app.intrvw.ai/company/</span>
          <TextInput
            placeholder="Type here"
            value={companyCode}
            onInput={setCompanyCode}
          />
          {code && <CopyUrl 
            path={`/company/${companyCode}`}
            title="Copy URL to public company page"
          />}
        </span>
      </div>
      <div className="tagline mb-3">
        <TextInput
          label="Tagline"
          placeholder="Type here"
          value={taglineText}
          onInput={setTaglineText}
          maxLength={90}
        />
      </div>
      <div className="company-description">
        <TextAreaInput
          label="Company description"
          rows={5}
          value={companyDescription}
          onInput={setCompanyDescription}
          maxLength={1000}
        />
      </div>
    </div>
  )
}

export default ContentTab;