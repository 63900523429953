import { FC } from 'react';
import RadioInput from './RadioInput/RadioInput';
import SelectInput from './SelectInput/SelectInput';
import CheckboxGroup from './CheckboxGroup/CheckboxGroup';
import FileUpload from "../modules/fileUpload/FileUpload";
import VideoInput from './VideoInput/VideoInput';
import NumberInput from './NumberInput/NumberInput';
import TextAreaInput from './TextAreaInput/TextAreaInput';

interface IDynamicInputProps {
  data: any;
  onInput?: (val: any) => void;
}
const DynamicInput: FC<IDynamicInputProps> = ({ data, onInput }) => {
  const {
    disabled, value, label,
    placeholder, className, options,
    type, name, onSpeechRecognized,
    maxVideoDuration,
  } = data;
  
  switch (type) {
    case 'Text':
      return <TextAreaInput
        disabled={disabled}
        value={value}
        onInput={onInput}
        label={label}
        placeholder={placeholder}
        className={className}
      />
    case 'Number':
      return <NumberInput
        disabled={disabled}
        value={value}
        onInput={onInput}
        label={label}
        className={className}
      />
    case 'Radio':
      return <RadioInput
        options={options}
        disabled={disabled}
        onInput={onInput}
        label={label}
        className={className}
        name={name || 'preview'}
        selectedByDefault={value}
      />
    case 'Select':
      return <SelectInput
        value={value}
        options={options}
        disabled={disabled}
        onInput={onInput}
        label={label}
        className={className}
      />
    case 'Checkbox':
      return <CheckboxGroup
        disabled={disabled}
        options={options}
        onInput={onInput}
        className={className}
        value={value}
      />
    case 'File':
      return <FileUpload
        value={value}
        readonly={disabled}
        onData={onInput}
      />
    case 'Video':
      return <VideoInput
        initialValue={value}
        disabled={disabled}
        onVideoUrl={onInput}
        onSpeechRecognized={onSpeechRecognized}
        maxDuration={maxVideoDuration}
      />
    default:
      return <>Incorrect data provided</>;
  }
}

export default DynamicInput;