import { httpPut } from '../../services/http.service';
import { MdCheck, MdClear, MdOutlineQuestionMark } from 'react-icons/md';
import React from 'react';
import './ChangeStatusButtons.scss';
import TooltipComponent from '../Tooltip/TooltipComponent';

export const FILTERS_BY_STATUS = {
  EMPTY: 'empty',
  REJECTED: 'rejected',
  QUALIFIED: 'qualified',
  NOT_SURE: 'notSure',
}

interface IChangeStatusButtonsProps {
  resultId: string;
  onStatusUpdated: (resultId: string, status: string) => void;
  currentStatus?: string;
}

const ChangeStatusButtons: React.FC<IChangeStatusButtonsProps> = ({
                                                                    resultId,
                                                                    onStatusUpdated,
                                                                    currentStatus,
}) => {
  
  const setCandidateStatus = async (status: string) => {
    if (currentStatus === status) {
      return;
    }
    const data = await httpPut(`/interview-results/${resultId}/status`, {
      status,
    });
    
    if (data?.message && !data?.error) {
      onStatusUpdated?.(resultId, status);
    }
  }
  
  return (
    <span className={'ChangeStatusButtons'}>
      <span className={`icon ${currentStatus === FILTERS_BY_STATUS.QUALIFIED ? 'active' : ''}`}>
        <TooltipComponent 
          text={'Change status to "Qualified"'}
          >
          <MdCheck
            onClick={() => setCandidateStatus(FILTERS_BY_STATUS.QUALIFIED)}
          />
        </TooltipComponent>
      </span>
      <span className={`icon ${currentStatus === FILTERS_BY_STATUS.REJECTED ? 'active' : ''}`}>
        <TooltipComponent 
          text={'Change status to "Rejected"'} 
          >
          <MdClear
            onClick={() => setCandidateStatus(FILTERS_BY_STATUS.REJECTED)}
          />
        </TooltipComponent>
      </span>
      <span className={`icon ${currentStatus === FILTERS_BY_STATUS.NOT_SURE ? 'active' : ''}`}>
        <TooltipComponent 
          text={'Change status to "Check later"'} 
          >
          <MdOutlineQuestionMark
            onClick={() => setCandidateStatus(FILTERS_BY_STATUS.NOT_SURE)}
          />
        </TooltipComponent>
      </span>
    </span>
  );
}

export default ChangeStatusButtons;