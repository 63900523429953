import { useParams } from "react-router-dom";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { httpGet } from '../../services/http.service';
import Button from 'react-bootstrap/Button';
import './AttendInterview.scss';
import Loading from '../../components/Loading/Loading';
import VideoInput from '../../components/VideoInput/VideoInput';
import useInterviewResults from './useInterviewResults';
import ConfirmationPopup from "../../components/ConfirmationPopup/ConfirmationPopup";
import VideoRecordingConsent from './VideoRecordingConsent';
import useProductTracking from '../../hooks/useProductTracking';
import QuestionsCarousel from './QuestionsCarousel/QuestionsCarousel';
import PreSignUp, { candidatesContactsType } from './PreSignUp/PreSignUp';
import useCurrentCompany from "../company/hooks/useCurrentCompany";
import CompanyHeader from "../../components/CompanyHeader/CompanyHeader";
import CompanyContacts from "../../components/CompanyContacts/CompanyContacts";

const getCachedData = (key: string) => {
  const storedData = localStorage.getItem(key);

  if (storedData) {
    try {
      return JSON.parse(storedData);
    } catch (error) {
      console.error('Parsing error in getCachedData:', error);
      return null;
    }
  }

  return null;
}

const AttendInterview = () => {
  const { id } = useParams();
  const tracker = useProductTracking();
  const [formData, setFormData] = useState<any>(null);
  const [inputsData, setInputsData] = useState<Record<string, any>>((id && getCachedData('interviewState')?.[id]?.responses) || {});
  const { title, textIntro, videoIntro, questions } = formData || {};
  const [candidatesContacts, setCandidatesContacts] = useState<candidatesContactsType>((id && getCachedData('interviewState')?.[id]?.allContacts ) || {});
  const [done, setDone] = useState<boolean>((id && getCachedData('interviewState')?.[id]?.isFinished) || false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const { createInterviewResult, isLoading } = useInterviewResults();
  const [hasVideoQuestion, setHasVideoQuestion] = useState<boolean>(false);
  const [videoApproved, setVideoApproved] = useState<boolean>((id && getCachedData('interviewState')?.[id]?.videoApproved) || false);
  const { setCurrentCompany } = useCurrentCompany();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>((id && getCachedData('interviewState')?.[id]?.currentQuestionIndex) || 0);
  
  const colors = formData?.company?.colors;
  const IS_CONSENT_APPROVED = !hasVideoQuestion || videoApproved;
  const candidatesContactsInputs = formData?.candidatesContacts;
  const email = candidatesContacts?.Email ?? (id && getCachedData('interviewState')?.[id]?.Email);
  const name = candidatesContacts?.Name ?? (id && getCachedData('interviewState')?.[id]?.Name );

  const rootStyle: any = {  
    '--content-background-color': colors?.contentBackground,
    '--text-color': colors?.text,
    '--icons-color': colors?.icons,
    '--buttons-color':colors?.buttons,
    '--buttons-text-color': colors?.buttonsText,
  }

  const defaultRootStyle: any = {
    '--content-background-color': '#212529',
    '--text-color': '#dee2e6',
    '--icons-color': '#0d6efd',
    '--buttons-color': '#198754',
    '--buttons-text-color': '#dee2e6',
  }

  const saveToLocalStorage = () => {
    if (id && IS_CONSENT_APPROVED) {
      const interviewData = {
        [id]: {
          email,
          name,
          allContacts: candidatesContacts,
          isFinished: done,
          responses: inputsData,
          currentQuestionIndex,
          hasVideoQuestion,
          videoApproved,
        }
      };

      localStorage.setItem('interviewState', JSON.stringify(interviewData));
    }
  }

  useEffect(() => {
    saveToLocalStorage();
  }, [
      email, name, done, inputsData, 
      currentQuestionIndex, videoApproved,
      hasVideoQuestion, candidatesContacts,
  ]);

  useEffect(() => {
    const load = async () => {
      const data = await httpGet(`/interviews/${id}`, {
        skipAuth: true,
      });
      if (data && !data?.error) {
        setFormData(data);
        setHasVideoQuestion(data.hasVideoQuestion);
        setCurrentCompany(data.company);
        
        tracker.track(`Candidate Visit`, {
          interviewId: id,
          hasVideoQuestion: !!data.hasVideoQuestion, // must be boolean
        });
      }
    }
    load();
  }, [id]);
  
  const handleAnswerChange = useCallback((answerType: IBasePropType, questionId: string, answerVal: string) => {
    setInputsData(prevData => ({
      ...prevData,
      [questionId]: {
        question: questionId,
        answerVal,
        answerType,
      },
    }));
  }, [inputsData]);
  
  const handleSave = useCallback(async () => {
    const responses = Object.values(inputsData);
    const data = {
      email,
      name,
      allContacts: candidatesContacts,
      interview: id,
      responses,
    };
    const isSuccess = await createInterviewResult(data);
    
    if (isSuccess) {
      tracker.track(`Candidate Finish`, {
        email,
        interviewId: id,
      });
      setDone(true);
    }
  }, [inputsData, email, name, id]);
  
  const submitDisabled = useMemo(() => {
    const amountFilled = Object
      .values(inputsData || {})
      .filter((data: any) => !!data.answerVal)
      .length;
    const overallAmount = questions?.length;
    
    return amountFilled < overallAmount || isLoading;
  }, [inputsData, questions?.length, isLoading]);
  
  const onConsentGiven = () => {
    tracker.track(`Candidate Video Consent Provided`, {
      email,
      interviewId: id,
    });
    setVideoApproved(true);
  }
  
  if (!formData) {
    return <Loading text={'Loading form...'}/>
  }
  
  if (done) {
    return (
      <div className={'AttendInterview-form-ctn'} style={formData?.company ? rootStyle : defaultRootStyle}>
        <div className="middle-ctn">
          Your responses have been saved and will be reviewed soon.
        </div>
      </div>
    );
  }
  
  const interviewStarted = !!email;
  
  if (interviewStarted && hasVideoQuestion && !videoApproved) {
    return <VideoRecordingConsent onConfirm={onConsentGiven} />
  }
  
  return (
      <div 
        className={'AttendInterview-form-ctn'} 
        style={formData?.company ? rootStyle : defaultRootStyle}
      >
        {isLoading && <Loading text={'Saving...'} fullscreen />}
        {!interviewStarted && <CompanyHeader />}
        <div className="middle-ctn">
          {!interviewStarted && <CompanyContacts />}
          {!interviewStarted && <>
            {!!title && <h2 className={'form-title mb-3'}>
              {title}
            </h2>}
            {!!textIntro && <div className={'form-description mb-3'}>
              {textIntro}
            </div>}
            {!!videoIntro && <VideoInput initialValue={videoIntro} disabled />}
            <hr />
            <PreSignUp onInput={setCandidatesContacts} contacts={candidatesContactsInputs}/>
          </>}
          {interviewStarted && <>
            <QuestionsCarousel
              questions={questions}
              handleAnswerChange={handleAnswerChange}
              answersMap={inputsData}
              assignCurrentQuestionIndex={setCurrentQuestionIndex}
              defaultQuestionIndex={id && getCachedData('interviewState')?.[id]?.currentQuestionIndex}
            />
            <Button
              onClick={() => setShowConfirmationModal(true)}
              disabled={submitDisabled}
            >
              Finish the interview
            </Button>
            <ConfirmationPopup 
              isStyled
              show={showConfirmationModal} 
              message="Are you sure you want to submit your answers and finish the interview?"
              onConfirm={handleSave}
              onCancel={() => setShowConfirmationModal(false)}
            />
          </>}
        </div>
      </div>
  );
}

export default AttendInterview;

