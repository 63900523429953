import React, { useEffect, ReactElement } from 'react';
import { firebaseUserToAppUser } from "../../services/auth.service";
import Loading from '../../components/Loading/Loading';
import { onAuthStateChanged, setPersistence, browserLocalPersistence, getAuth } from 'firebase/auth';
import { auth } from '../../services/firebase.service';
import { setAuthUserAction } from './redux/user.slice';
import { useDispatch } from 'react-redux';
import Notification from '../notification/Notification';
import { RouterProvider } from 'react-router-dom';
import Routing from '../routing/Routing';
import { setHSUserEmail } from '../../services/hubspotService';

interface IAuthProps {
  children?: ReactElement;
}

const AuthProvider: React.FC<IAuthProps> = ({ children }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = React.useState<boolean>(true);
  
  const setAuthUser = (user: IUser | null) => {
    dispatch(setAuthUserAction(user));
  }
  
  useEffect(() => {
    setLoading(true);
    const unsub = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        console.log('no user', user);
        setAuthUser(null);
        setLoading(false);
        setHSUserEmail('');
        
        return;
      }
      
      
      setHSUserEmail(user.email || '');
      const accessToken = await user?.getIdToken();
      setAuthUser(firebaseUserToAppUser(user, accessToken));
      setPersistence(auth, browserLocalPersistence);
      setLoading(false);
    });
    
    return () => {
      unsub();
    }
  }, []);
  
  if (isLoading) {
    return <Loading />;
  }
  
  return (
    <div className="App">
      <RouterProvider
        router={Routing}
        fallbackElement={<Loading className={'fullscreen'} />}
      />
      <Notification />
    </div>
  );
}

export default AuthProvider;
