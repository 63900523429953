import { useCallback, useMemo, useState, useEffect } from 'react';
import { refGetValue, getTimeOffset } from '../../services/firebase.service';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPlanAction, setPlansAction } from './redux/billing.slice';
import { RootState } from '../../store';
import { DateTime } from 'luxon';

const useBilling = () => {
  const dispatch = useDispatch();
  const [trialTimeLeft, setTrialTimeLeft] = useState('');
  const [isAccessRestricted, setIsAccessRestricted] = useState(false);
  const billingPlans = useSelector((state: RootState) => state.billing?.plans);
  const currentPlan = useSelector((state: RootState) => state.billing?.currentPlan);
  
  useEffect(() => {
    const calcTime = async () => {
      const offset = await getTimeOffset();
      const currentTimeGMT = Date.now() + offset;
      const currTS = DateTime.fromMillis(currentTimeGMT);
      const endTS = DateTime.fromMillis(currentPlan?.startedAt).plus({
        days: currentPlan?.duration,
      });
      const diff = endTS.diff(currTS, ['days', 'hours', 'minutes']).toObject();
      
      if (diff.days && diff.days > 0) {
        setTrialTimeLeft(`${diff.days + 1} days left`);
        setIsAccessRestricted(false);
        return;
      }
      
      if (diff.hours && diff.hours > 0) {
        setTrialTimeLeft(`${diff.hours} hours left`);
        setIsAccessRestricted(false);
        return;
      }
      
      if (diff.minutes && diff.minutes > 0) {
        setTrialTimeLeft(`${diff.minutes} minutes left`);
        setIsAccessRestricted(false);
        return;
      }
      
      setTrialTimeLeft('Ended');
      setIsAccessRestricted(true);
    }
    
    if (currentPlan?.plan === 'trial') {
      calcTime();
    }
  }, [currentPlan]);
  
  const getBillingPlans = useCallback(async () => {
    const plans = await refGetValue(`billing/plans`);
    dispatch(setPlansAction(plans));
  }, []);
  
  const getCurrentPlan = useCallback(async (userId: string) => {
    const plan = await refGetValue(`billing/users/${userId}`);
    dispatch(setCurrentPlanAction(plan));
  }, []);
  
  const isTrial = useMemo(() => {
    return currentPlan?.plan === 'trial';
  }, [currentPlan?.plan]);
  
  
  return {
    getBillingPlans,
    getCurrentPlan,
    billingPlans,
    isTrial,
    trialTimeLeft,
    isAccessRestricted,
  };
}

export default useBilling;