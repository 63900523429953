import React, { useState, FC, useEffect } from 'react';
import TextAreaInput from '../../../components/TextAreaInput/TextAreaInput';
import './CreateInterview.scss';
import Button from 'react-bootstrap/Button';
import { httpPost } from '../../../services/http.service';
import Loading from '../../../components/Loading/Loading';
import TextInput from '../../../components/TextInput/TextInput';
import useInterviews from '../hooks/useInterviews';
import VideoAttachment from '../../../components/VideoAttachment/VideoAttachment';
import QuestionsEditor from '../QuestionsEditor/QuestionsEditor';
import ConfirmationPopup from '../../../components/ConfirmationPopup/ConfirmationPopup';
import NumberInput from '../../../components/NumberInput/NumberInput';
import useProductTracking from '../../../hooks/useProductTracking';
import useAuth from '../../auth/hooks/useAuth';
import { v4 as uuidv4 } from 'uuid';
import ContactsCheckboxes, { ICheckboxOption } from './ContactsCheckboxes/ContactsCheckboxes';
import { useTour } from '@reactour/tour'
import { InterviewsTutorialSteps } from '../../tutorials/interviewsTutorial';


interface ICreateInterviewProps {
  onClose?: () => void;
  initialValue?: Record<string, any>
  isEdit?: boolean;
}

const STEPS = {
  INTRO: 1,
  CONTACTS: 2,
  JD: 3,
  QUESTIONS: 4,
}

const contactTypes = ['Phone number','LinkedIn','X (Twitter)','Facebook','GitHub','Personal website'];
const defaultContacts = [
  {
    val: 'Email',
    displayVal: 'Email',
    checked: true,
    id: 'email',
    isDisabled: true,
    isRequired: true,
  }, {
    val: 'Name',
    displayVal: 'Name',
    checked: true,
    id: 'name',
    isDisabled: true,
    isRequired: true,
  },
];

const getInitialContacts = (contactTypes: string[]): ICheckboxOption[] => {
  const initialContacts = [...defaultContacts];

  for (const contact of contactTypes) {
    const id = uuidv4();
    const newContact = {
      val: contact,
      displayVal: contact,
      checked: false,
      id,
      isDisabled: false,
      isRequired: false,
    }

    initialContacts.push(newContact)
  }

  return initialContacts;
}

const MAX_AI_QUESTIONS = 15;

const CreateInterview: FC<ICreateInterviewProps> = ({ onClose, initialValue, isEdit = false }) => {
  const [title, setTitle] = useState(initialValue?.title || '');
  const [introText, setIntroText] = useState(initialValue?.textIntro || '');
  const [description, setDescription] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [generatedQuestions, setGeneratedQuestions] = useState<IQuestion[]>([]);
  const [currentCreatingStep, setCurrentCreatingStep] = useState(STEPS.INTRO);
  const [isLoading, setIsLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState(initialValue?.videoIntro || '');
  const { createInterview, updateInterview, isInterviewLoading } = useInterviews();
  const [showJDConfirm, setShowJDConfirm] = useState(false);
  const [numberOfAiQuestions, setNumberOfAiQuestions] = useState('10');
  const [contacts, setContacts] = useState<ICheckboxOption[]>(initialValue?.candidatesContacts || getInitialContacts(contactTypes));
  const [showInput, setShowInput] = useState(false);
  const [newContactType, setNewContactType] = useState('');
  const { authUser } = useAuth()
  
  const aiQuestionsNumber = Number(numberOfAiQuestions);
  const questionGenerationAllowed = aiQuestionsNumber > 0 && aiQuestionsNumber <= MAX_AI_QUESTIONS;
  const tracker = useProductTracking();
  const userId = authUser?.uid;
  const { setCurrentStep } = useTour();


  const handleNextClick = async () => {
    if (initialValue && currentCreatingStep === STEPS.CONTACTS) {
      setCurrentCreatingStep(currentCreatingStep + 2);
    } else {
      setCurrentCreatingStep(currentCreatingStep + 1);
    }

    if (currentCreatingStep === STEPS.CONTACTS) {
      tracker.track(`create interview - step 2`, {
        userId,
      });
    }

    if (currentCreatingStep === STEPS.JD) {
      tracker.track(`create interview - step 3`, {
        userId,
      });
    }

    if (currentCreatingStep === STEPS.QUESTIONS) {
      tracker.track(`create interview - step 4`, {
        userId,
      });
    }
  }

  const handleBackClick = async () => {
    if (initialValue && currentCreatingStep === STEPS.QUESTIONS) {
      setCurrentCreatingStep(currentCreatingStep - 2);
    } else {
      setCurrentCreatingStep(currentCreatingStep - 1);
    }
  }
  
  const handleJDClick = async () => {
    setShowJDConfirm(false);
    setIsLoading(true);

    const res = await httpPost(`/ai/jd`, {
      description,
      numberOfAiQuestions: aiQuestionsNumber,
    });

    setGeneratedQuestions(res?.questions);
    setCurrentCreatingStep(currentCreatingStep + 1);
    setIsLoading(false);
    setCurrentStep(InterviewsTutorialSteps.MODAL_FOURTH_STEP);

    tracker.track(`create interview - ai used`, {
      userId,
    });
  }

  const handleAddContactType = () => {
    if (newContactType.trim()) {
      const newContact = {
        val: newContactType.trim(),
        displayVal: newContactType.trim(),
        checked: false,
        id: uuidv4(),
        isDisabled: false,
        isRequired: false,
      };
      
      setContacts(prevContacts => [...prevContacts, newContact]);
      setNewContactType('');
      setShowInput(false);
    }
  };

  const renderBackButton = (callback?: () => void) => {
    return (
      <Button
        variant={'outline-secondary'}
        className={''}
        onClick={() => {
          handleBackClick();
          callback?.();
        }}
      >
        Back
      </Button>
    )
  }

  const onCreateQuestionsManuallyClick = () => {
    handleNextClick();

    tracker.track(`create interview - ai skipped`, {
      userId,
    });
  }
  
  const renderJD = () => {
    return (
      <>
        <TextAreaInput
          label='Paste a job description below, so we can suggest some questions to ask candidates'
          placeholder='Job description here'
          className='mb-3'
          onInput={setDescription}
          value={description}
          rows={12}
        />
        <NumberInput 
          label='Number of questions to generate (from 1 to 15):'
          minValue={1}
          maxValue={15}
          onInput={setNumberOfAiQuestions}
          value={numberOfAiQuestions}
        />
        {!questionGenerationAllowed 
          && <span className='mt-1' style={{color: '#dc3545'}}>
              Please enter number from 1 to 15
             </span>
        }
        <Button
          className={'mb-3 mt-3'}
          variant={'success'}
          disabled={!description?.length || !questionGenerationAllowed}
          onClick={() => setShowJDConfirm(true)}
        >
          Continue
        </Button>
        {renderBackButton(() => setCurrentStep(InterviewsTutorialSteps.MODAL_SECOND_STEP))}
        <Button
          className={''}
          variant={'link'}
          onClick={() => {
            onCreateQuestionsManuallyClick();
            setCurrentStep(InterviewsTutorialSteps.MODAL_FOURTH_STEP);
          }}
        >
          I want to create questions manually
        </Button>
        <ConfirmationPopup
          show={showJDConfirm}
          onConfirm={handleJDClick}
          confirmLabel={'I will'}
          message={'Please, carefully review questions provided by AI on the next step'}
        />
      </>
    )
  }
  
  const renderIntroduction = () => {
    return (
      <>
        <TextInput
          label='Title'
          placeholder='Type title here'
          className='mb-3'
          onInput={setTitle}
          value={title}
          helperContent={`Will be shown to candidate before start of the interview`}
        />
        <TextAreaInput
          label='Introduction'
          placeholder='Type introduction text here'
          className='mb-3'
          onInput={setIntroText}
          value={introText}
          helperContent={`Will be shown to candidate before start of the interview`}
        />
        <VideoAttachment
          helperContent={`Will be shown to candidate before start of the interview`}
          onVideoUrl={setVideoUrl}
        />
        <Button
          className={''}
          variant={'success'}
          disabled={!title}
          onClick={() => {
            handleNextClick();
            setCurrentStep(InterviewsTutorialSteps.MODAL_SECOND_STEP);
          }}
        >
          Continue
        </Button>
      </>
    );
  }

  const renderContacts = () => {
    return (
      <>
        <div>
          <ContactsCheckboxes 
            options={contacts}
            onInput={checkedOptions => setContacts(checkedOptions)}
          />
          {showInput ? (
            <div>
              <TextInput
                value={newContactType}
                onInput={setNewContactType}
                placeholder="Enter custom contact label"
              />
              <Button 
                className='mb-4 mt-3'
                onClick={handleAddContactType}
              >
                Add
              </Button>
            </div>
            ) : (
              <Button
                className='mb-4'
                onClick={() => setShowInput(true)}
              >
                Add Custom
              </Button>
            )
          }
        </div>
        <Button
          className='mb-3'
          variant='success'
          onClick={() => {
            handleNextClick();
            setCurrentStep(InterviewsTutorialSteps.MODAL_THIRD_STEP)
          }}
        >
          Continue
        </Button>
        {renderBackButton(() => setCurrentStep(InterviewsTutorialSteps.MODAL_FIRST_STEP))}
      </>
    )
  }
  
  const handleSaveClick = async (questions: IQuestion[]) => {    
    const data = {
      title,
      textIntro: introText,
      videoIntro: videoUrl,
      isActive,
      questions,
      candidatesContacts: contacts,
    }
    
    tracker.track(`create interview - saved`, {
      userId,
    });

    const isSuccess = await createInterview(data);
    
    if (isSuccess) {
      onClose?.();
      setCurrentStep(InterviewsTutorialSteps.FINAL);
    }
  }

  const handleUpdateClick = async (questions: IQuestion[]) => {
    const data = {
      id: initialValue?.id,
      title,
      textIntro: introText,
      videoIntro: videoUrl,
      isActive,
      questions,
      candidatesContacts: contacts,
    }
    const isSuccess = await updateInterview(data);
    
    if (isSuccess) {
      onClose?.();
    }
  }
  
  const isEditQuestionsStep = currentCreatingStep === STEPS.QUESTIONS;
  const isIntroStep = currentCreatingStep === STEPS.INTRO;
  const isJDStep = currentCreatingStep === STEPS.JD;
  const isContactsStep = currentCreatingStep === STEPS.CONTACTS;
  
  return (
    <div className={'CreateInterview'}>
      {isInterviewLoading && <Loading text={`Saving...`} />}
      {isLoading && <Loading text={`Generating questions. Please, be patient, it might take up to 1 minute`} />}
      {isIntroStep && renderIntroduction()}
      {isJDStep && renderJD()}
      {isEditQuestionsStep &&
        <>
          <QuestionsEditor
            onSave={isEdit ? handleUpdateClick : handleSaveClick}
            initialValue={initialValue?.questions ?? generatedQuestions}
          />
          {renderBackButton(() => setCurrentStep(InterviewsTutorialSteps.MODAL_THIRD_STEP))}
        </>
      }
      {isContactsStep && renderContacts()}
    </div>
  )
}

export default CreateInterview;