import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import React from 'react';
import { Variant } from 'react-bootstrap/types';

export interface INotificationState {
  content: React.ReactNode | React.ReactNode[] | string | null;
  header?: React.ReactNode | React.ReactNode[] | string | null;
  color?: Variant;
}

const initialState: INotificationState = {
  content: null,
  header: null,
  color: 'primary',
};


export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    showNotificationAction: (state, action: PayloadAction<INotificationState>) => {
      state.content = action.payload.content;
      state.header = action.payload.header;
      state.color = action.payload.color;
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  showNotificationAction ,
} = notificationSlice.actions;

export default notificationSlice.reducer;