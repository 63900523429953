import { FC } from 'react';
import { themeLogo } from './Logo';

interface ILogoProps {
  colorMode?: 'dark' | 'light';
  width?: string;
  height?: string;
}
const LogoVertical: FC<ILogoProps> = ({
                                colorMode = 'dark',
                                width = '100%',
                                height = '100%',
                              }) => {
  const { dot, text, bg, textSecondary } = themeLogo[colorMode];
  
  return (
    <svg width={width} height={height} viewBox="0 0 220 270" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_236_111)">
        <path d="M185.9 219.6H34.1498C15.6798 219.6 0.569824 204.49 0.569824 186.02V34.2699C0.569824 15.7999 15.6798 0.689941 34.1498 0.689941H185.9C204.37 0.689941 219.48 15.7999 219.48 34.2699V186.01C219.48 204.49 204.37 219.6 185.9 219.6Z" fill={text}/>
        <path d="M181.1 59.5601H207.56L151.49 161.38H130.46L105.74 116.52L117.8 94.3801L140.97 133.44L181.1 59.5601Z" fill={bg}/>
        <path d="M103.38 59.5601H129.55L74.1 161.38H53.2999L12.46 91.7001H38.63L63.7 134.16L103.38 59.5601Z" fill={bg}/>
        <path d="M12.46 85.6302V59.2402H38.64V85.6302H12.46Z" fill={dot}/>
        
        <path d="M0.569824 269.78V243.35H4.97981V269.78H0.569824Z" fill={textSecondary}/>
        <path d="M30.9899 269.78L14.6199 251.43V269.78H9.43994V243.35H14.3699L30.6899 261.68V243.35H35.8699V269.78H30.9899Z" fill={textSecondary}/>
        <path d="M54.79 248.57V269.78H49.61V248.57H39V243.35H65.43V248.57H54.79Z" fill={textSecondary}/>
        <path d="M91.0896 269.78V262.94C91.0896 260.43 89.8296 259.18 87.3196 259.18H75.0496V269.78H69.8296V243.35H91.5496C94.6896 243.35 96.2596 244.92 96.2596 248.06V252.31C96.2596 253.68 95.2096 254.99 93.1096 256.25C95.2096 256.73 96.2596 258.25 96.2596 260.81V269.78H91.0896ZM91.0896 248.57H75.0496V253.97H87.3396C89.0696 253.97 90.1896 253.43 90.6896 252.35C90.9796 251.71 91.1096 250.45 91.0896 248.57Z" fill={textSecondary}/>
        <path d="M115.17 269.78H110.28L99.27 243.35H104.57L112.73 261.79L120.89 243.35H126.19L115.17 269.78Z" fill={textSecondary}/>
        <path d="M144.92 269.78H140.03L129.02 243.35H134.32L142.48 261.79L150.64 243.35H155.94L144.92 269.78Z" fill={textSecondary}/>
        <path d="M174.83 269.66V264.24H180.23V269.66H174.83Z" fill={textSecondary}/>
        <path d="M205.42 269.78V259.14H189.36V269.78L184.14 269.76V248.06C184.14 244.92 185.72 243.35 188.88 243.35H205.89C209.03 243.35 210.6 244.92 210.6 248.07V269.78H205.42ZM205.42 248.57H189.36V253.97H205.42V248.57Z" fill={textSecondary}/>
        <path d="M215.07 269.78V243.35H219.48V269.78H215.07Z" fill={textSecondary}/>
        <path d="M166.16 243.35L158 261.79L154.85 254.67L151.98 261.2L155.56 269.78H160.45L171.46 243.35H166.16Z" fill={textSecondary}/>
      </g>
      <defs>
        <clipPath id="clip0_236_111">
          <rect width="220" height="270" fill={bg}/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoVertical;