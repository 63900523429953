import React, { ChangeEvent, useState, useCallback, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import InfoHelper from '../InfoHelper/InfoHelper';

type ValueType = string;
interface ITextInput {
  value?: ValueType;
  defaultValue?: ValueType;
  label?: string;
  placeholder?: string;
  className?: string;
  onInput?: (val: ValueType) => void;
  disabled?: boolean;
  required?: boolean;
  validationLabel?: string;
  helperContent?: string | React.ReactNode;
  maxLength?: number; 
}

const TextInput: React.FC<ITextInput> = ({
                                           value,
                                           defaultValue,
                                           label,
                                           placeholder,
                                           onInput,
                                           className = '',
                                           disabled,
                                           required = false,
                                           validationLabel,
                                           helperContent,
                                           maxLength,
}) => {
  const [inputValue, setInputValue] = useState(value || '');
  
  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onInput?.(newValue);
  }, [onInput]);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);
  
  return (
    <Form.Group className={`TextInput ${className}`}>
      {label && <Form.Label>
        <span>{label}</span>
        {helperContent && <InfoHelper text={helperContent} />}
      </Form.Label>}
      <Form.Control
        type='text'
        required={required}
        disabled={disabled}
        placeholder={placeholder ?? ''}
        value={inputValue}
        defaultValue={defaultValue}
        onChange={onChange}
        maxLength={maxLength}
      />
      {maxLength && (
        <div style={{ fontSize: 12, textAlign: 'right', marginTop: '5px' }}>
          {`${inputValue.length} / ${maxLength}`}
        </div>
      )}
      {validationLabel && <Form.Control.Feedback type="invalid">
        {validationLabel}
      </Form.Control.Feedback>}
    </Form.Group>
  )
};

export default TextInput;