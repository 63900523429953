const TEXT_FOR_TOOLTIP = (
  <>
  <p>"Minor" - answer will get only half of the score during evaluation</p>
  <p>"Normal" - answer will get normal score during evaluation</p>
  <p>"Important" - answer will get double score during evaluation</p>
  </>
);

const ADDITIONAL_TEXT_FOR_TOOLTIP = (
  <p>"Must have" - if condition not met, candidate won't be displayed in results</p>
)

interface IQuestionImportanceTooltipTextProps {
  answerType: string;
}

const QuestionImportanceTooltipText: React.FC<IQuestionImportanceTooltipTextProps> = ({ answerType }) => {
  const isMustHave = answerType === 'Radio' || answerType === 'Select' || answerType === 'Number';

  return (
    <>
    {TEXT_FOR_TOOLTIP}
    {isMustHave && ADDITIONAL_TEXT_FOR_TOOLTIP}
    </>
  )
}

export default QuestionImportanceTooltipText;