import Loading from "../../../components/Loading/Loading";
import CompanyProfileLayout from "../CompanyProfileLayout/CompanyProfileLayout";
import useCurrentCompany from "../hooks/useCurrentCompany";

const PublicCompanyPage = () => {
  const { companyInfo, isCurrentCompanyLoading } = useCurrentCompany();

  if (isCurrentCompanyLoading) {
    return <Loading />;
  }

  return <CompanyProfileLayout data={companyInfo}/>;
}

export default PublicCompanyPage;