import { useCallback, useState } from 'react';
import { httpPost } from '../../services/http.service';

const useInterviewResults = () => {
  const [isLoading, setIsLoading] = useState(false);
  
  const createInterviewResult = useCallback(async (data: any) => {
    setIsLoading(true);
    let isSuccess = false;
    const response = await httpPost(
      '/interview-results',
      data,
      {
        successMessage: 'Your interview successfully saved and will be reviewed soon!',
        skipAuth: true,
      },
    );
    if (response && !response.error) {
      isSuccess = true;
    }
    setIsLoading(false);
    
    return isSuccess;
  }, []);
  
  return {
    createInterviewResult,
    isLoading,
  }
}

export default useInterviewResults;