import SelectInput from "../../../components/SelectInput/SelectInput";
import QuestionImportanceTooltipText from "../QuestionImportanceTooltipText/QuestionImportanceTooltipText";
import React from 'react';

const MINOR_IMPORTANCE = 'minor-importance';
const NORMAL_IMPORTANCE = 'normal-importance';
const IMPORTANT_IMPORTANCE = 'important-importance';
const MUST_HAVE_IMPORTANCE = 'must-have-importance';

const MUST_HAVE_OPTION =   {
  val: 'mustHave',
  displayVal: 'Must have',
  id: MUST_HAVE_IMPORTANCE,
}

const importanceOptions = [
  {
    val: '0.5',
    displayVal: 'Minor',
    id: MINOR_IMPORTANCE,
  },
  {
    val: '1',
    displayVal: 'Normal',
    id: NORMAL_IMPORTANCE,
  },
  {
    val: '2',
    displayVal: 'Important',
    id: IMPORTANT_IMPORTANCE,
  },
]

const MUST_HAVE_TYPES = ['Radio', 'Select', 'Number'];

const getImportanceOptions = (answerType: string) => {
  if (MUST_HAVE_TYPES.includes(answerType)) {
    return [...importanceOptions, MUST_HAVE_OPTION];
  } 

  return importanceOptions;
}

interface IQuestionImportancePickerProps {
  answerType: string;
  importanceData?: string;
  onInput?: (val: string) => void;
}

const QuestionImportancePicker: React.FC<IQuestionImportancePickerProps> = ({
  answerType,
  onInput,
  importanceData
}) => {

  return (
    <SelectInput
      options={getImportanceOptions(answerType)}
      value={importanceData ? importanceData : '1'}
      label='Importance'
      className='mb-3 mt-3'
      onInput={onInput}
      textForTooltip={<QuestionImportanceTooltipText answerType={answerType}/>}
    />
  );
}

export default QuestionImportancePicker;