import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  values: [] as IBaseProp[],
  isLoading: false,
};


export const basePropsSlice = createSlice({
  name: 'baseProps',
  initialState,
  reducers: {
    setBasePropsAction: (state, action: PayloadAction<IBaseProp[]>) => {
      state.values = action.payload;
    },
    setIsBasePropsLoadingAction: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setBasePropsAction ,
  setIsBasePropsLoadingAction,
} = basePropsSlice.actions;

export default basePropsSlice.reducer;