import { httpGet } from '../../services/http.service';
import React, { useEffect, ReactElement } from 'react';
import useBaseProps from '../baseProp/hooks/useBaseProps';
import Loading from '../../components/Loading/Loading';

interface IMainLoaderProps {
  children: ReactElement;
}
const MainLoader: React.FC<IMainLoaderProps> = ({ children }) => {
  // const [isLoading, setLoading] = React.useState<boolean>(true);
  // const { setBaseProps } = useBaseProps();
  //
  //
  // useEffect(() => {
  //   const load = async () => {
  //     const baseProps = await httpGet(`/base-props`);
  //     setBaseProps(baseProps || []);
  //
  //     setLoading(false);
  //   }
  //   load();
  // }, []);
  //
  // if (isLoading) {
  //   return <Loading />;
  // }

  return children;
};
export default MainLoader;