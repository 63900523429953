import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import './Login.scss';
import { Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import LogoVertical from '../../../components/svg/LogoVertical';
import { FaGoogle } from 'react-icons/fa6';
import { useState, ChangeEvent, useEffect } from 'react';
import { auth } from '../../../services/firebase.service';
import { sendPasswordResetEmail } from 'firebase/auth';
import useNotifications from '../../notification/hooks/useNotifications';

const Login = () => {
  const { basicLogin, googleLogin } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isRecoveringPswd, setIsRecoveringPswd] = useState(false);
  const [isRecoverSuccess, setIsRecoverSuccess] = useState(false);
  const { showNotification } = useNotifications();
  const redirectUrl = window.location.href;

  const handleGoogleLogin = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    googleLogin();
  }
  
  const handleEmailLogin = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    basicLogin(email, password);
  }
  
  const handleEmailInput = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }
  
  const handlePasswordInput = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }

  const handleRecoverPassword = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const actionCodeSettings = {
      url: `${redirectUrl}`,
    };

    let isSuccess = false;

    try {
      await sendPasswordResetEmail(auth, email, actionCodeSettings);
      isSuccess = true;
      console.log('Letter has been sent successfully');
    } catch (error: any) {
      const errorCode = error?.code;
      const errorMessage = error?.message;

      if (errorCode === 'auth/user-not-found') {
        showNotification({
          color: 'danger',
          content: 'This email is not registered',
        });
      } else {
        showNotification({
          color: 'danger',
          content: 'Error occurred',
        });
      }

      console.log('error:', errorCode, errorMessage);
    }

    setIsRecoverSuccess(isSuccess)
  };

  const renderBackToSignInBtn = () => {
    return (
      <div className={'back-to-signin-ctn'}>
        <Button
          variant='link'
          className='back-to-signin'
          onClick={() => {
            setIsRecoveringPswd(false);
            setIsRecoverSuccess(false);
            setPassword('');
          }}
        >
          Back to Sign in
        </Button>
      </div>
    )
  }

  useEffect(() => {
    const isDisabled = !password || !email;
    setIsButtonDisabled(isDisabled);
  },[password, email]);
  
  return (
    <Stack gap={3} className={'login-ctn'}>
      <div className="width">
        <div className="logo">
          <LogoVertical colorMode={'dark'}/>
        </div>
        <Button
          variant="light"
          className={'google-btn mb-3'}
          onClick={handleGoogleLogin}
        >
          <FaGoogle /> Sign in with Google
        </Button>
        <hr className={'mb-2'}/>
        {!isRecoveringPswd && <Form className={'login-form'} onSubmit={handleEmailLogin}>
          <Form.Group className="email-group mb-3" controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={handleEmailInput}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <div className='password-ctn mb-2'>
              <Form.Control
                type="password"
                placeholder="Password"
                minLength={6}
                maxLength={40}
                value={password}
                onChange={handlePasswordInput}
              />
            </div>
          </Form.Group>
          
          <Button
            disabled={isButtonDisabled}
            className={'login-btn mb-3'}
            variant="outline-light"
            type="submit"
          >
            Log in
          </Button>
          <div className={'signup-link'}>
            <span>Forgot password?</span>
            <Button
              variant='link'
              className='forgot-password'
              onClick={() => setIsRecoveringPswd(true)}
            >
              Reset
            </Button>
          </div>
          <div className={'signup-link'}>
            <span>Don't have an account?</span>
            <Link to="/signup">
              Sign up
            </Link>
          </div>
        </Form>}
        {isRecoveringPswd && !isRecoverSuccess && <Form className='recover-pswd-form' onSubmit={handleRecoverPassword}>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={handleEmailInput}
              className='mb-3'
              required
            />
          </Form.Group>
          <Button
            className={'recover-pswd-btn mb-2'}
            variant="outline-light"
            type="submit"
          >
            Reset password
          </Button>
          {renderBackToSignInBtn()}
        </Form>}
        {isRecoverSuccess && <div className={'reset-sent'}>
          <p>
            An email has been sent with instructions of how to reset your password
          </p>
          {renderBackToSignInBtn()}
        </div>}
      </div>
      
    </Stack>
  );
};

export default Login;