import React, { useState, useEffect, } from 'react';
import TextInput from "../../../components/TextInput/TextInput";
import './ContactInfoTab.scss';

export type ContactInfoTabDataType = Record<string, any>;

interface IContactInfoTabProps {
  onData: (data: ContactInfoTabDataType) => void;
  data?: any;
}

const ContactInfoTab: React.FC<IContactInfoTabProps> = ({ onData, data }) => {
  const { email: dataEmail, linkedin: dataLinkedin } = data?.contacts || {};

  const [email, setEmail] = useState<string>(dataEmail || '');
  const [linkedin, setLinkedin] = useState<string>(dataLinkedin || '');

  useEffect(() => {
    const contactInfo = {
      email,
      linkedin,
    }

    onData(contactInfo);
  },[email, linkedin, onData])

  return (
    <div className='contact-info'>
      <TextInput 
        label="Email"
        placeholder='Type here'
        className='mb-3'
        value={dataEmail}
        onInput={setEmail}
      />
      <TextInput 
        label="LinkedIn"
        placeholder='Type here'
        className='mb-3'
        value={dataLinkedin}
        onInput={setLinkedin}
      />
    </div>
  )
}

export default ContactInfoTab;