import { httpGet } from '../../services/http.service';
import React, { useEffect, ReactElement } from 'react';
import useBaseProps from '../baseProp/hooks/useBaseProps';
import Loading from '../../components/Loading/Loading';
import useInterviews from './hooks/useInterviews';

interface IQuestionsLoaderProps {
  children: ReactElement;
}
const InterviewsPageLoader: React.FC<IQuestionsLoaderProps> = ({ children }) => {
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const { setBaseProps } = useBaseProps();
  const { setInterviews } = useInterviews();
  
  
  useEffect(() => {
    const load = async () => {
      const promises = [
        httpGet(`/base-props`),
        httpGet(`/interviews/my`),
      ];
      const [baseProps, interviews] = await Promise.all(promises);
      setBaseProps(baseProps || []);
      setInterviews(interviews || []);
      
      setLoading(false);
    }
    load();
  }, []);
  
  if (isLoading) {
    return <Loading />;
  }
  
  return children;
};
export default InterviewsPageLoader;