import { createSlice, PayloadAction } from '@reduxjs/toolkit'
const cacheString = localStorage.getItem('auth');
const cache = cacheString && JSON.parse(cacheString);
interface IInitialUserState {
  user: IUser | null;
}
const initialState: IInitialUserState = {
  user: cache || null,
};

export const authUserSlice = createSlice({
  name: 'authUser',
  initialState,
  reducers: {
    setAuthUserAction: (state, action: PayloadAction<IUser | null>) => {
      localStorage.setItem('auth', JSON.stringify(action.payload));
      if (action.payload) {
        state.user = {
          ...action.payload,
        };
      } else {
        state.user = null;
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { setAuthUserAction } = authUserSlice.actions

export default authUserSlice.reducer