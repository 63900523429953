import React, { ChangeEvent, useState, useCallback, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import InfoHelper from '../InfoHelper/InfoHelper';
import './SelectInput.scss';

export type SelectValueType = string;
export type Option = Record<'val' | 'displayVal' | 'id', SelectValueType>;

interface ISelectInput {
  options?: Option[];
  value?: SelectValueType;
  label?: string;
  className?: string;
  disabled?: boolean;
  defaultSelectedIndex?: number;
  textForTooltip?: string | React.ReactNode;
  
  onInput?: (val: SelectValueType) => void;
}

const SelectInput: React.FC<ISelectInput> = ({
  value,
  label,
  onInput,
  className = '',
  options,
  disabled,
  textForTooltip,
}) => {
  const [inputValue, setInputValue] = useState(value);
  useEffect(() => {
    if (!inputValue && options?.[0]?.val) {
      setInputValue(options[0].val);
      onInput?.(options[0].val);
    }
  }, [inputValue, options]);
  
  const onChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setInputValue(e.target.value);
    onInput?.(e.target.value);
  }, [onInput]);

  return (
    <Form.Group className={`SelectInput ${className}`}>
      {label && <Form.Label>{label}</Form.Label>}
      {label && textForTooltip && <InfoHelper text={textForTooltip} />}
      {inputValue && options?.length && <Form.Select
        value={inputValue}
        onChange={onChange}
        disabled={disabled}
      >
        <OptionsComponent options={options} />
      </Form.Select>}
    </Form.Group>
  )
};

interface IOptions {
  options?: Option[];
}

const OptionsComponent: React.FC<IOptions> = ({ options }) => {
  if (!options?.length) {
    return null;
  }
  
  return (
    <>
      {options.map(({val, displayVal, id}: Option) => {
        return <option key={id} value={val}>
          {displayVal}
        </option>
      })}
    </>
  )
}

export default SelectInput;