import UserAvatar from '../user/UserAvatar';
import './Header.scss';
import PopoverMenu from '../../components/PopoverMenu/PopoverMenu';
import UserMenu from '../user/UserMenu';
import useAuth from '../auth/hooks/useAuth';
import Logo from '../../components/svg/Logo';
import useBilling from '../billing/useBilling';

const Header = () => {
  const { authUser } = useAuth();
  const { isTrial, trialTimeLeft } = useBilling();
  
  const handleLogoClick = () => {
    window.open('https://intrvw.ai', "_blank", "noreferrer");
  }
  
  return (
    <div className={`header-ctn`}>
      <div className="logo-ctn" onClick={handleLogoClick}>
        <Logo />
      </div>
      
      {isTrial && (
        <div className="trial-ctn">
          {`Free Trial - ${trialTimeLeft}`}
        </div>
      )}
      
      <PopoverMenu
        header={authUser?.displayName}
        body={<UserMenu />}
        className={`header-popover`}
      >
        <UserAvatar />
      </PopoverMenu>
    </div>
  );
}

export default Header;