import { httpGet } from '../../services/http.service';
import React, { useEffect, ReactElement } from 'react';
import useBaseProps from '../baseProp/hooks/useBaseProps';
import Loading from '../../components/Loading/Loading';
import useQuestions from './hooks/useQuestions';

interface IQuestionsLoaderProps {
  children: ReactElement;
}
const QuestionsLoader: React.FC<IQuestionsLoaderProps> = ({ children }) => {
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const { setBaseProps } = useBaseProps();
  const { setQuestions } = useQuestions();
  
  
  useEffect(() => {
    const load = async () => {
      const promises = [
        httpGet(`/base-props`),
        // httpGet(`/questions/my`),
      ];
      const [baseProps] = await Promise.all(promises);
      setBaseProps(baseProps || []);
      // setQuestions(questions || []);

      setLoading(false);
    }
    load();
  }, []);
  
  if (isLoading) {
    return <Loading />;
  }
  
  return children;
};
export default QuestionsLoader;