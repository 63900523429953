import React, { useEffect, ReactElement, useState } from 'react';
import { httpGet } from '../../services/http.service';
import Loading from '../../components/Loading/Loading';
import useCurrentCompany from './hooks/useCurrentCompany';

interface ICurrentCompanyLoaderProps {
  children: ReactElement;
}

const CurrentCompanyPageLoader: React.FC<ICurrentCompanyLoaderProps> = ({ children }) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const { setCurrentCompany } = useCurrentCompany();

  useEffect(() => {
    const load = async () => {
      const companyInfo = await httpGet('/company');
      setCurrentCompany(companyInfo || {});

      setLoading(false);
    };

    load();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return children;
};

export default CurrentCompanyPageLoader;

