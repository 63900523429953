import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store';
import { TourProvider } from '@reactour/tour'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <TourProvider
      defaultOpen={false}
      steps={[]}
      showBadge={false} 
      showNavigation={false}
      disableKeyboardNavigation
      scrollSmooth
      padding={{ 
        mask: 5,
      }}
      position={'top'}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </TourProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
