import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { setBasePropsAction, setIsBasePropsLoadingAction } from '../redux/baseProps.slice';
import { httpGet } from '../../../services/http.service';
import { useMemo } from 'react';

const QUESTION_TYPES = ['Text', 'Video'];
const useBaseProps = () => {
  const dispatch = useDispatch();
  const baseProps = useSelector((state: RootState) => state.baseProps.values);
  const isBasePropsLoading = useSelector((state: RootState) => state.baseProps.isLoading);
  
  const setBaseProps = (baseProps: IBaseProp[]) => {
    dispatch(setBasePropsAction(baseProps));
  };
  const setBasePropsLoading = (isLoading: boolean) => {
    dispatch(setIsBasePropsLoadingAction(isLoading));
  };
  const loadBaseProps = async () => {
    const basePropsRes: IBaseProp[] = await httpGet(`/base-props`);
    
    setBaseProps(basePropsRes || []);
  };
  
  const questionTypesOptions = useMemo(() => {
    return baseProps
      .filter(p => QUESTION_TYPES.includes(p.type))
      .map(p => ({
        id: p.id,
        val: p.type,
        displayVal: p.type,
      }));
  }, [baseProps]);
  
  return {
    baseProps,
    setBaseProps,
    loadBaseProps,
    isBasePropsLoading,
    setBasePropsLoading,
    questionTypesOptions,
  };
}

export default useBaseProps;