import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import './SidebarNav.scss';
import { useCallback } from 'react';

const SidebarNav = () => {
  const { pathname } = useLocation();
  
  const getIsActive = useCallback((path: string) => {
    return pathname === path;
  }, [pathname]);
  
  const renderItem = useCallback((path: string, title: string, hidden?: boolean) => {
    if (hidden) {
      return null;
    }
    return (<MenuItem
      active={getIsActive(path)}
      component={<Link to={path}/>}
    >
      {title}
    </MenuItem>);
  }, [getIsActive]);
  
  
  return (
    <Sidebar>
      <Menu
        menuItemStyles={{
          button: {
            [`&.ps-active`]: {
              backgroundColor: '#434b53',
              color: '#eeeeee',
            },
          },
        }}
      >
        {renderItem("/interviews", 'Interviews')}
        {renderItem("/my-company", 'My Company')}
        {renderItem("/dashboard", 'Dashboard', true)}
        {renderItem("/questions", 'Questions', true)}
        {renderItem("/login", 'Login', true)}
      </Menu>
    </Sidebar>
  )
}

export default SidebarNav;
