import React, { useEffect, useState, useRef } from 'react';
import { Duration } from 'luxon';

interface ICountdownProps {
  start: boolean;
  limit?: number; // seconds
  onLimit: () => void;
}

const formatSeconds = (seconds: number) => {
  return Duration.fromObject({ seconds }).toFormat('mm:ss');
}

const Countdown: React.FC<ICountdownProps> = ({
  start,
  limit = 60,
  onLimit,
}) => {
  const [displayTime, setDisplayTime] = useState(formatSeconds(limit));
  const remainingTime = useRef(limit);
  const intervalRef = useRef<any>(null);

  useEffect(() => {
    if (start) {
      intervalRef.current = setInterval(() => {
        remainingTime.current -= 1;
        setDisplayTime(formatSeconds(remainingTime.current));
        
        if (remainingTime.current <= 0) {
          clearInterval(intervalRef.current);
          onLimit?.();
        }
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => {
      clearInterval(intervalRef.current);
      remainingTime.current = limit;
      setDisplayTime(formatSeconds(limit));
    };
  }, [start, limit]);

  return (
    <span className={'countdown-ctn'}>
      {displayTime}
    </span>
  )
}

export default Countdown;
