import React from 'react';
import ConfirmationPopup from '../../components/ConfirmationPopup/ConfirmationPopup';

const message = <>
  <div>This interview contains one or more questions that require to record a video from your webcam.</div>
  <div>You will be asked to turn on your camera and press "Record" button whenever you are ready.</div>
  <div>You will be able to try to record your video answer multiple times.</div>
  <div>After you click "Save" button, video will be saved and reviewed by recruiter who posted this interview to evaluate your answer.</div>
  <div>By pressing button below you give your permission to save and process your video answers.</div>
  <div>If you are disagree - just close this web page.</div>
</>;

interface IVideoRecordingConsentProps {
  onConfirm: () => void;
}

const VideoRecordingConsent: React.FC<IVideoRecordingConsentProps> = ({ onConfirm }) => {
  return (
    <ConfirmationPopup
      isStyled
      show
      message={message}
      onConfirm={onConfirm}
      confirmLabel={`I understand and confirm`}
      size={'lg'}
    />
  )
};

export default VideoRecordingConsent;