import React from 'react';
import {
  createBrowserRouter, Navigate,
} from "react-router-dom";
import '../../App.scss';
import Login from '../auth/login/Login';
import MainPage from '../navigation/MainPage';
import Signup from '../auth/signup/Signup';
import AttendInterview from "../attendInterview/AttendInterview";
import InterviewStatsPage from '../interviews/InterviewStatsPage/InterviewStatsPage';
import InterviewAnswersPage from '../interviews/InterviewAnswersPage/InterviewAnswersPage';
import QuestionsLoader from '../question/QuestionsLoader';
import InterviewsPage from '../interviews/InterviewsPage';
import MyCompany from '../company/MyCompany/MyCompany';
import PublicCompanyPage from '../company/PublicCompanyPage/PublicCompanyPage';
import PublicCompanyPageLoader from '../company/PublicCompanyPageLoader';
import SignupMessage from '../auth/signup/SignupMessage/SignupMessage';

export const defaultRoute = '/interviews';

const Page404 = () => <>404</>;

const Routing = createBrowserRouter([{
  path: '/login',
  element: <Login />,
}, {
  path: '/signup',
  element: <Signup />,
}, {
  path: '/email-verification',
  element: <SignupMessage />,
}, {
  path: '/interview/:id',
  element: <AttendInterview />
}, {
  path: '/company/:companyCode',
  element: <PublicCompanyPageLoader>
    <PublicCompanyPage />
  </PublicCompanyPageLoader>
}, {
  path: '/',
  element: (
    <MainPage />
  ),
  children: [{
    index: true,
    element: <Navigate to={defaultRoute} />,
  }, {
    path: 'interviews',
    element: <InterviewsPage />,
  }, {
    path: 'interview/stats/:id',
    element: <InterviewStatsPage />,
  },{
    path: 'interview/:interviewId/answers/:answerId',
    element: <QuestionsLoader>
      <InterviewAnswersPage />
    </QuestionsLoader>,
  },{
    path: 'my-company',
    element: <MyCompany />,
  }],
}, {
  path: '/*',
  element: <Page404 />,
}]);

export default Routing;
