import { BsLink45Deg } from 'react-icons/bs';
import React from 'react';
import useNotifications from '../../modules/notification/hooks/useNotifications';
import './CopyUrl.scss';

interface ICopyUrlProps {
  path: string;
  title?: string;
  onClick?: () => void;
}

const CopyUrl: React.FC<ICopyUrlProps> = ({ path = '', title = '', onClick }) => {
  const { showNotification } = useNotifications();
  
  const onCopyUrl = () => {
    if (path) {
      const url = `${window.location.origin}${path}`;
      
      navigator.clipboard.writeText(url);
      
      showNotification({
        color: 'success',
        content: 'URL copied!',
      });
    }
  }
  
  if (!path) {
    return null;
  }
  
  return (
    <BsLink45Deg
      className={'copy-btn'}
      onClick={() => {
        onCopyUrl();
        onClick?.();
      }}
      title={title}
    />
  );
}

export default CopyUrl