import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useCallback } from 'react';
import { showNotificationAction, INotificationState } from '../redux/notification.slice';

const useNotifications = () => {
  const dispatch = useDispatch();
  const {
    content, header, color
  } = useSelector((state: RootState) => state.notifications);
  
  const showNotification = useCallback((params: INotificationState) => {
    dispatch(showNotificationAction(params));
  }, []);
  
  const clearNotification = useCallback(() => {
    dispatch(showNotificationAction({
      content: null,
      header: null,
    }));
  }, []);
  
  return {
    showNotification,
    clearNotification,
    content,
    header,
    color,
  }
}

export default useNotifications;