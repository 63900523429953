import React, { ReactElement, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from './hooks/useAuth';
import useBilling from '../billing/useBilling';

interface IAuthProps {
  children: ReactElement;
}

const Auth: React.FC<IAuthProps> = ({ children }) => {
  const { authUser } = useAuth();
  const { getBillingPlans, getCurrentPlan } = useBilling();
  
  useEffect(() => {
    if (authUser?.uid) {
      getBillingPlans();
      getCurrentPlan(authUser.uid);
    }
  }, [authUser?.uid]);
  
  return authUser?.email && authUser?.isEmailVerified ? children :  <Navigate to="/login" /> ;
}

export default Auth;
