import * as amplitude from '@amplitude/analytics-browser';
import { useCallback } from 'react';

amplitude.init(process.env.REACT_APP_AMPLITUDE_TOKEN ?? '', {
  defaultTracking: {
    attribution: true,
    fileDownloads: false,
    formInteractions: false,
    
    pageViews: true,
    sessions: true,
  }
});

const useProductTracking = () => {
  
  const track = useCallback((eventName: string, data?: Record<string, any>) => {
    amplitude.track(eventName, data);
  }, []);
  
  const setUserId = useCallback((userId?: string) => {
    amplitude.setUserId(userId);
  }, []);
  
  return {
    track,
    setUserId,
  };
}

export default useProductTracking;