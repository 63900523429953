import './InterviewStatsPage.scss';
import { useParams, Link } from 'react-router-dom';
import React, { useEffect, useState, useCallback } from 'react';
import { httpGet, httpPut } from '../../../services/http.service';
import Loading from '../../../components/Loading/Loading';
import { Table, ProgressBar } from 'react-bootstrap';
import { TbListDetails } from 'react-icons/tb';
import SelectInput from '../../../components/SelectInput/SelectInput';
import { SORTS } from '../InterviewsToolbar/InterviewsToolbar';
import CheckboxGroup from '../../../components/CheckboxGroup/CheckboxGroup';
import TypeaheadInput from '../../../components/TypeaheadInput/TypeaheadInput';
import { MdCheck, MdClear, MdOutlineQuestionMark } from 'react-icons/md';
import ChangeStatusButtons, { FILTERS_BY_STATUS } from '../../../components/ChangeStatusButtons/ChangeStatusButtons';
import TooltipComponent from '../../../components/Tooltip/TooltipComponent';



const filterByStatusOptions = [{
  val: FILTERS_BY_STATUS.EMPTY,
  displayVal: 'New',
  id: FILTERS_BY_STATUS.EMPTY,
  checked: true,
}, {
  val: FILTERS_BY_STATUS.NOT_SURE,
  displayVal: 'Check later',
  id: FILTERS_BY_STATUS.NOT_SURE,
  checked: false,
}, {
  val: FILTERS_BY_STATUS.QUALIFIED,
  displayVal: 'Qualified',
  id: FILTERS_BY_STATUS.QUALIFIED,
  checked: false,
}, {
  val: FILTERS_BY_STATUS.REJECTED,
  displayVal: 'Rejected',
  id: FILTERS_BY_STATUS.REJECTED,
  checked: false,
}];

const sortStats = (a: any, b: any) => {
  return Number(b?.normalizedScore) - Number(a?.normalizedScore);
};

const getStatusText = (status: string) => {
  return filterByStatusOptions.find((option: any) => option.id === status)?.displayVal || 'New';
}

const InterviewStatsPage = () => {
  const {id} = useParams();
  const [stats, setStats] = useState<any>(null);
  const [interview, setInterview] = useState<any>(null);
  const [statusFilters, setStatusFilters] = useState(filterByStatusOptions.filter((o: any) => o.checked));
  
  useEffect(() => {
    const load = async () => {
      const data = await httpGet(`/interview-results/${id}`);
      if (data && !data?.error) {
        setInterview(data?.interview || {});
        setStats(data?.stats || []);
      }
    }
    load();
  }, [id]);
  
  const handleFilterByStatusChanged = (checkedOptions: any) => {
    setStatusFilters(checkedOptions)
  }
  
  const onStatusUpdated = (resultId: string, status: string) => {
    setStats((prevStats: any[]) => prevStats.map((stat: any) => {
        if (stat.id === resultId) {
          return {
            ...stat,
            status,
          }
        }
        return stat;
      })
    );
  }
  
  const filterStats = useCallback((stat: any) => {
    console.log('##f', statusFilters, stat.status);
    const filterIds = statusFilters.map((status: any) => status.id);
    
    if (!statusFilters?.length) {
      return true;
    }
    const statusValue = stat.status ?? FILTERS_BY_STATUS.EMPTY;
    
    return filterIds.includes(statusValue);
  }, [statusFilters]);
  
  if (!stats || !interview) {
    return <Loading text={'Loading statistics...'} />
  }
  
  const numberOfResponses = stats?.length || 0;
  
  return (
    <div className={`InterviewStatsPage`}>
      <h3 className={'mb-3'}>
        {interview?.title}
      </h3>
      <div className={`toolbar`}>
        <h6 className={'mb-3'}>
          {numberOfResponses} {`candidate${numberOfResponses !== 1 ? 's' : ''}`}
        </h6>
        <div className={'horizontal-filter mb-3'}>
          <span className={'label'}>
            Filter by status:
          </span>
          <TypeaheadInput
            options={filterByStatusOptions}
            selectedOptions={statusFilters}
            onInput={handleFilterByStatusChanged}
            multiple
            className={`horizontal`}
          />
        </div>
      </div>
      <Table bordered>
        <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Status</th>
          <th>Score</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {stats?.filter(filterStats).sort(sortStats).map((row: any) => {
          return (
            <tr key={row.id}>
              <td>{row.name}</td>
              <td>{row.email}</td>
              <td>{getStatusText(row.status)}</td>
              <td>
                <ProgressBar
                  now={stats.length > 1 ? row.normalizedScore * 100 : 50}
                  variant={'success'}
                  visuallyHidden
                />
              </td>
              <td className={'toolbox-cell'}>
                <Link to={`/interview/${interview.id}/answers/${row.id}`}>
                  <TooltipComponent text={'View detailed answers'}>
                    <TbListDetails />
                  </TooltipComponent>
                </Link>
                <ChangeStatusButtons
                  resultId={row.id}
                  onStatusUpdated={onStatusUpdated}
                  currentStatus={row.status}
                />
                </td>
            </tr>
          )
        })}
        </tbody>
      </Table>
    </div>
  )
}

export default InterviewStatsPage;