import { Table } from 'react-bootstrap';
import CopyUrl from '../../../components/CopyUrl/CopyUrl';
import { MdDeleteForever, MdOutlineQueryStats, MdOutlineContentCopy, MdOutlineEdit } from 'react-icons/md';
import React, { useState, useCallback, FC } from 'react';
import { DateTime } from 'luxon';
import './InterviewsTable.scss';
import { Link } from 'react-router-dom';
import ConfirmationPopup from '../../../components/ConfirmationPopup/ConfirmationPopup';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import InfoHelper from '../../../components/InfoHelper/InfoHelper';
import useInterviews from '../hooks/useInterviews';
import { Sorting, SORTS } from '../InterviewsToolbar/InterviewsToolbar';
import TooltipComponent from '../../../components/Tooltip/TooltipComponent';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import CreateInterview from '../CreateInterview/CreateInterview';
import useAuth from '../../auth/hooks/useAuth';
import useProductTracking from '../../../hooks/useProductTracking';
import useBilling from '../../billing/useBilling';
import useNotifications from '../../notification/hooks/useNotifications';
import { useTour } from '@reactour/tour'

interface IInterviewsTableProps {
  interviews?: any[];
  sort?: Sorting;
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
}

const statusHelperText = `"Active" status means that candidates are allowed to attend the interview. \n "Inactive" - URL is inactive, nobody can access it, used if you want to pause on getting new candidates.`;
const editingWarningText = `This interview already has responses. Edit is supposed to be used for fixing typos and editing content in the introduction section. \n If you are going to change questions - it will affect evaluation process and we are highly recommend to copy this interview or create a new one`;
const DONT_SHOW_EDITING_WARNING_AGAIN = 'dont-show-again'
const dontShowAgainCheckbox = [
  {
    val: `Don't show again`,
    displayVal: `Don't show again`,
    id: DONT_SHOW_EDITING_WARNING_AGAIN,
    checked: false,
  }
]

const getCachedData = (key: string) => {
  const storedData = localStorage.getItem(key);

  if (storedData) {
    try {
      return JSON.parse(storedData);
    } catch (error) {
      console.error('Parsing error in getCachedData:', error);
      return null;
    }
  }

  return null;
}

const saveToLocalStorage = (key: string, data: any) => {
  localStorage.setItem(key, JSON.stringify(data));
}

const InterviewsTable: FC<IInterviewsTableProps> = ({
  interviews = [],
  sort = SORTS.dateDesc,
  onEdit,
  onDelete,
}) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [statusSwitchDisabled, setStatusSwitchDisabled] = useState(false);
  const [interviewIdToDelete, setInterviewIdToDelete] = useState('');
  const [copyInterviewModalShow, setCopyInterviewModalShow] = useState(false);
  const [editInterviewModalShow, setEditInterviewModalShow] = useState(false);
  const [closeConfirmationShow, setCloseConfirmationShow] = useState(false);
  const [editingWarningPopupShow, setEditingWarningPopupShow] = useState(false);
  const [interviewToCopy, setInterviewToCopy] = useState({});
  const [interviewToEdit, setInterviewToEdit] = useState({});
  const [checkedOptions, setCheckedOptions] = useState<string[]>([])
  const { updateInterview } = useInterviews();
  const { authUser } = useAuth()
  const tracker = useProductTracking();
  const userId = authUser?.uid;
  const { isAccessRestricted } = useBilling();
  const { showNotification } = useNotifications();
  const { setIsOpen, setSteps } = useTour();

  const shouldShowEditingWarningPopup = !getCachedData('dontAskEditInterview')?.includes(DONT_SHOW_EDITING_WARNING_AGAIN);

  const sortFunc = useCallback((card1: any, card2: any) => {
    const d1 = DateTime.fromISO(card1.createdAt).toMillis();
    const d2 = DateTime.fromISO(card2.createdAt).toMillis();
    
    switch (sort) {
      case SORTS.dateAsc:
        return d1 - d2;
      case SORTS.dateDesc:
        return d2 - d1;
      default:
        return d2 - d1;
    }
  }, [sort]);

  const deleteInterview = useCallback(() => {
    onDelete?.(interviewIdToDelete);
    setConfirmationModalOpen(false);
  },[onDelete, interviewIdToDelete])
  
  const setIsActive = async (id: string, isActive: boolean) => {
    if (statusSwitchDisabled) {
      return;
    }
    setStatusSwitchDisabled(true);
    await updateInterview({
      id,
      isActive,
    }, true);
    setStatusSwitchDisabled(false);
  }
  
  const onCopyInterviewClose = () => {
    setCopyInterviewModalShow(false);
  }

  const onEditInterviewClose = () => {
    setEditInterviewModalShow(false);
  }

  const onEditingWarningConfirm = () => {
    setEditingWarningPopupShow(false);
    setEditInterviewModalShow(true);

    if (checkedOptions.includes(DONT_SHOW_EDITING_WARNING_AGAIN)) {
      saveToLocalStorage('dontAskEditInterview', checkedOptions);
    }
  }

  const onEditingClick = (a: any) => {
    setInterviewToEdit(a);

    if (a.responsesCount && shouldShowEditingWarningPopup) {
      setEditingWarningPopupShow(true);
    } else {
      setEditInterviewModalShow(true);
    }

    tracker.track(`interview table - edit interview`, {
      userId,
      interviewId: a.id,
    });
  }

  const onCopyInterviewClick = (a: any) => {
    setCopyInterviewModalShow(true);
    setInterviewToCopy(a);

    tracker.track(`interview table - copy interview as new`, {
      userId,
      interviewId: a.id,
    });
  }

  const onCopyUrlClick = (a: any) => {
    tracker.track(`interview table - copy URL`, {
      userId,
      interviewId: a.id,
    });
  }

  const onStatsClick = (a: any) => {
    tracker.track(`interview table - stats`, {
      userId,
      interviewId: a.id,
    });
  }

  const onDeleteClick = (a: any) => {
    setInterviewIdToDelete(a.id);
    setConfirmationModalOpen(true);

    tracker.track(`interview table - delete`, {
      userId,
      interviewId: a.id,
    });
  }
  
  const onSwitchClick = (id: string, newIsActive: boolean) => {
    // todo: replace lib, 'disabled' doesn't work.
    // https://github.com/gitbrent/bootstrap-switch-button-react/issues/29
    
    return () => {
      setIsActive(id, newIsActive);
    }
  }
  
  return (
    <div className="intrvws-table-ctn">
      <Table bordered>
        <thead>
          <tr>
            <th>Title</th>
            <th>
              <span>Status</span>
              <InfoHelper
                text={statusHelperText}
                position={'right'}
              />
            </th>
            <th>Candidates</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
        {interviews?.slice().sort(sortFunc).map(a => {
          return (
            <tr key={a.id}>
              <td>{a.title}</td>
              <td>
                <BootstrapSwitchButton
                  disabled={statusSwitchDisabled}
                  checked={a.isActive}
                  onlabel='Active'
                  offlabel='Inactive'
                  width={90}
                  height={24}
                  onstyle="success"
                  offstyle="secondary"
                  onChange={onSwitchClick(a.id, !a.isActive)}
                />
              </td>
              <td>{a.responsesCount || 0}</td>
              <td className={'toolbox-cell'}>
                <Link to={`/interview/stats/${a.id}`}>
                  <TooltipComponent text={'Stats'}>
                    <MdOutlineQueryStats onClick={() => onStatsClick(a)}/>
                  </TooltipComponent>
                </Link>
                {!isAccessRestricted && <TooltipComponent text={'Copy URL to the interview page'}>
                  <CopyUrl 
                    path={`/interview/${a.id}`} 
                    onClick={() => {
                      onCopyUrlClick(a);
                      setIsOpen?.(false);
                      setSteps?.([]);
                    }} 
                  />
                </TooltipComponent>}
                {!isAccessRestricted && <TooltipComponent text={'Edit interview'}>
                  <MdOutlineEdit style={{fontSize: 24}} onClick={() => onEditingClick(a)}
                  />
                </TooltipComponent>}
                {!isAccessRestricted && <TooltipComponent text={'Copy interview as new'}>
                  <MdOutlineContentCopy style={{fontSize: 20}} onClick={() => onCopyInterviewClick(a)} />
                </TooltipComponent>}
                {onDelete && (
                    <TooltipComponent text={'Delete'}>
                      <MdDeleteForever onClick={() => {onDeleteClick(a)}}
                      />
                    </TooltipComponent>
                  )
                }
                {onDelete && <ConfirmationPopup
                  show={confirmationModalOpen}
                  onConfirm={deleteInterview}
                  onCancel={() => setConfirmationModalOpen(false)}
                  message='Are you sure you want to delete this interview?'
                  confirmLabel='Delete'
                  confirmColor='danger'
                />}
              </td>
            </tr>
          )
        })}
        </tbody>
      </Table>
      <ModalComponent
        hasHeader
        title='Copy Interview as new'
        size="lg"
        backdrop={'static'}
        show={copyInterviewModalShow}
        onClose={() => setCloseConfirmationShow(true)}
      >
          <CreateInterview onClose={onCopyInterviewClose} initialValue={interviewToCopy} />
      </ModalComponent>
      <ModalComponent
        hasHeader
        title='Edit Interview'
        size="lg"
        backdrop={'static'}
        show={editInterviewModalShow}
        onClose={() => setCloseConfirmationShow(true)}
      >
        <CreateInterview
          isEdit
          onClose={onEditInterviewClose}
          initialValue={interviewToEdit}
        />
      </ModalComponent>
      <ConfirmationPopup 
        show={closeConfirmationShow}
        message='Are you sure you want to close this window? The created interview will not be saved'
        onConfirm={() => {
          setCopyInterviewModalShow(false);
          setCloseConfirmationShow(false);
          setEditInterviewModalShow(false)
        }}
        onCancel={() => setCloseConfirmationShow(false)}
      />
      <ConfirmationPopup 
        show={editingWarningPopupShow}
        message={editingWarningText}
        confirmLabel='I understand'
        size='lg'
        checkboxesOptions={dontShowAgainCheckbox}
        onCheckboxCheck={setCheckedOptions}
        onConfirm={onEditingWarningConfirm}
        onCancel={() => setEditingWarningPopupShow(false)}
      />
    </div>
  )
}

export default InterviewsTable;