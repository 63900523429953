import React, { useState, useEffect, } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import ContentTab from '../ContentTab/ContentTab';
import ContactInfoTab, { ContactInfoTabDataType } from '../ContactInfoTab/ContactInfoTab';
import ColorsTab from '../ColorsTab/ColorsTab';
import { ContentTabDataType } from '../ContentTab/ContentTab';
import { ColorsTabDataType } from '../ColorsTab/ColorsTab';
import './MyCompany.scss';
import CompanyProfileLayout from '../CompanyProfileLayout/CompanyProfileLayout';
import useCurrentCompany from '../hooks/useCurrentCompany';
import Loading from '../../../components/Loading/Loading';
import CurrentCompanyPageLoader from '../CurrentCompanyPageLoader';
import useInterviews from '../../interviews/hooks/useInterviews';
import InterviewsPageLoader from '../../interviews/InterviewsPageLoader';

type Tab = 'content' | 'contact-info' | 'colors';

const MyCompany = () => {
  const [currentTab, setCurrentTab] = useState<Tab>('content');
  const [isPreview, setIsPreview] = useState(false);
  const [contentTabData, setContentTabData] = useState<ContentTabDataType>({});
  const [contactInfoTabData, setContactInfoTabData] = useState<ContactInfoTabDataType>({});
  const [colorsTabData, setColorsTabData] = useState<ColorsTabDataType>({});
  const [companyData, setCompanyData] = useState({});
  const { interviews } = useInterviews();

  const { 
    createCurrentCompany, 
    isCurrentCompanyLoading,
    updateCompany,
    companyInfo,
  } = useCurrentCompany();

  const doesCompanyExist = !!Object.keys(companyInfo).length;

  useEffect(() => {
    const companyData = {
      id: companyInfo?.id,
      title: contentTabData.companyName,
      code: contentTabData.companyCode,
      tagline: contentTabData.taglineText,
      description: contentTabData.companyDescription,
      logoUrl: contentTabData.logoUrl,
      contacts: {
        email: contactInfoTabData.email,
        linkedin: contactInfoTabData.linkedin,
      },
      colors: {
        banner: colorsTabData.bannerColor,
        background: colorsTabData.bgColor,
        text: colorsTabData.textColor,
        backgroundImage: colorsTabData.bgImageUrl,
        icons: colorsTabData.iconsColor,
        typeOfBanner: colorsTabData.bannerType,
        contentBackground: colorsTabData.contentBgColor,
        buttons: colorsTabData.buttonsColor,
        buttonsText: colorsTabData.buttonsTextColor,
      },
      interviews,
    };  

    setCompanyData(companyData);
  }, [
    contentTabData, 
    contactInfoTabData, 
    colorsTabData,
    companyInfo,
    interviews,
  ]);

  const handleSaveClick = async () => {
    const isSuccess = await createCurrentCompany(companyData);
    if (isSuccess) {
      console.log('saved successfuly');
    }
  }

  const handleUpdateClick = async () => {
    const isSuccess = await updateCompany(companyData);
    if (isSuccess) {
      console.log('updated successfuly');
    }
  }
  
  return (
    <CurrentCompanyPageLoader>
      <InterviewsPageLoader>
        <div className={`my-company-ctn`}>
          <Button className='preview-btn' onClick={() => setIsPreview(!isPreview)}>
            {isPreview ? 'Close preview' : 'Show preview'}
          </Button>
          <Button
          variant="success"
          className='mb-3'
          onClick={() => {
            doesCompanyExist ? handleUpdateClick() : handleSaveClick();
          }}>
            {doesCompanyExist ? 'Update' : 'Save'}
          </Button>
          <Tabs
            id="company"
            activeKey={currentTab}
            onSelect={(tab) => tab && setCurrentTab(tab as Tab)}
            className="mb-3"
          >
            <Tab eventKey="content" title="Content">
              <ContentTab onData={setContentTabData} data={companyInfo}/>
            </Tab>
            <Tab eventKey="contact-info" title="Contact Info">
              <ContactInfoTab onData={setContactInfoTabData} data={companyInfo}/>
            </Tab>
            <Tab eventKey="colors" title="Colors">
              <ColorsTab onData={setColorsTabData} data={companyInfo}/>
            </Tab>
          </Tabs>
          {isPreview && <CompanyProfileLayout isPreview data={companyData} />}
          {isCurrentCompanyLoading && <Loading text={`Saving...`} />}
        </div>
      </InterviewsPageLoader>
    </CurrentCompanyPageLoader>
  );
}

export default MyCompany;