import React, {ChangeEvent, useState} from "react";
import Form from 'react-bootstrap/Form';
import InfoHelper from '../InfoHelper/InfoHelper';

interface ITextAreaInputProps {
  value?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  onInput?: (val: string) => void;
  disabled?: boolean;
  rows?: number;
  helperContent?: string | React.ReactNode;
  maxLength?: number;
}

const TextAreaInput: React.FC<ITextAreaInputProps> = ({
  value, label,
  placeholder, className,
  onInput, disabled, 
  rows = 3, helperContent,
  maxLength,
 }) => {
  const [inputValue, setInputValue] = useState(value || '');

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    onInput?.(e.target.value);
  }

 return (
  <>
    <Form.Group className={`${className}`}>
      {label && <Form.Label className={'mb-3'}>
        <span>{label}</span>
        {helperContent && <InfoHelper text={helperContent} />}
      </Form.Label>}
      <Form.Control
        as="textarea"
        rows={rows}
        disabled={disabled}
        placeholder={placeholder ?? ''}
        value={inputValue}
        onChange={onInputChange}
        maxLength={maxLength}
      />
      {maxLength && (
        <div style={{ fontSize: 12, textAlign: 'right', marginTop: '5px' }}>
          {`${inputValue.length} / ${maxLength}`} 
        </div>
      )}
    </Form.Group>
  </>
 ) 
}

export default TextAreaInput;