import React, { ChangeEvent, useEffect } from 'react';
import {Option} from "../SelectInput/SelectInput";
import Form from 'react-bootstrap/Form';

interface IRadioInputProps {
  options: Option[];
  name: string;
  label?: string;
  className?: string;
  disabled?: boolean;
  onInput?: (value: string) => void;
  selectedByDefault?: string;
}

const RadioInput: React.FC<IRadioInputProps> = ({
  options, label,
  className = '', name,
  disabled, onInput,
  selectedByDefault,
}) => {
  
  useEffect(() => {
    if (selectedByDefault) {
      const option = options?.find((o: Option) => o.id === selectedByDefault);
      if (option) {
        onInput?.(option.id);
      }
    } else {
      onInput?.(options?.[0]?.id);
    }
  }, []);

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    onInput?.(e.target.id.toString());
  }

  const renderRadioInputs = () => {
    return options.map((option, index) => (
      <Form.Check
        key={option.id}
        type='radio'
        id={option.id.toString()}
        label={option.displayVal}
        disabled={disabled}
        name={name}
        onChange={handleRadioChange}
        className={'mb-2'}
        defaultChecked={selectedByDefault ? option?.id === selectedByDefault : index === 0}
      />
    ))
  }

  return (
    <Form>
      <Form.Group key={options?.length} className={`mb-3 ${className}`}>
        {label && <Form.Label>{label}</Form.Label>}
        {options?.length && renderRadioInputs()}
      </Form.Group>
    </Form>
  )
}

export default RadioInput;