import React, { useState, useEffect } from 'react';
import CheckboxInput from '../CheckboxInput/CheckboxInput'
import Form from 'react-bootstrap/Form';

export interface CheckboxOption {
  val: string | number;
  displayVal: string;
  id: string;
  checked: boolean;
  isDisabled?:boolean;
}

interface ICheckboxGroupProps {
  options: CheckboxOption[];
  onInput?: (checkedIds: string[]) => void;
  className?: string;
  disabled?: boolean;
  value?: string[] | string;
}

const CheckboxGroup: React.FC<ICheckboxGroupProps> = ({
  options,
  onInput,
  className = '',
  disabled,
  value,
}) => {
  const [allCheckboxes, setAllCheckboxes] = useState<CheckboxOption[]>(options);
  
  const handleCheckboxChange = (checkboxOptions: CheckboxOption) => {
    const idOfChangedCheckbox = checkboxOptions.id;
    
    const updatedCheckboxes = allCheckboxes.map((checkbox: CheckboxOption) => {
      if (checkbox.id === idOfChangedCheckbox) {
        return {
          ...checkbox,
          checked: checkboxOptions.checked,
        };
      }

      return checkbox;
    });

    setAllCheckboxes(updatedCheckboxes);
    const checkedCheckboxesIds = updatedCheckboxes
      .filter((checkbox: CheckboxOption) => checkbox.checked)
      .map((checkbox: CheckboxOption) => checkbox.id);
    onInput?.(checkedCheckboxesIds);
  }
  
  useEffect(() => {
    if (value?.length && options?.length) {
      const updatedCheckboxes = allCheckboxes.map((checkbox: CheckboxOption) => {
        return {
          ...checkbox,
          checked: (value?.length ? value.includes(checkbox.id) : value === checkbox.id) || checkbox.checked,
        }
        
        return checkbox;
      });
      
      setAllCheckboxes(updatedCheckboxes);
    }
  }, [value]);

  const renderCheckboxGroup = () => {
    return allCheckboxes?.map((option) => (
      <CheckboxInput
        key={option.id}
        id={option.id}
        label={option.displayVal}
        checked={option.checked}
        val={option.val}
        onInput={handleCheckboxChange}
        disabled={option.isDisabled ?? disabled}
      />
    ))
  }
  
  return (
    <div className={`checkbox-group ${className}`}>
      <Form>
        {renderCheckboxGroup()}
      </Form>
    </div>
  );
};

export default CheckboxGroup;
