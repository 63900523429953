import React, { useState, useLayoutEffect } from 'react';
import Auth from '../auth/Auth';
import Loading from '../../components/Loading/Loading';
import InterviewsTable from './InterviewsTable/InterviewsTable';
import InterviewsPageLoader from './InterviewsPageLoader';
import InterviewsToolbar, { Sorting, SORTS } from './InterviewsToolbar/InterviewsToolbar';
import useInterviews from './hooks/useInterviews';
import './InterviewsPage.scss';
import { useTour } from '@reactour/tour';
import { Button } from 'react-bootstrap';
import { interviewsTutorialSteps, InterviewsTutorialSteps } from '../tutorials/interviewsTutorial';

const InterviewsPage = () => {
  const {
    interviews, deleteInterview,
    isInterviewLoading,
  } = useInterviews();
  const [sort, setSort] = useState<Sorting>(SORTS.dateDesc);
  const { setIsOpen, setCurrentStep, setSteps } = useTour();

  useLayoutEffect(() => {
    setIsOpen(true);
  }, []);
  
  const onCardDeleteClick = async (id: string) => {
    const isSuccess = await deleteInterview(id);
  }
  
  const onTutorialStartClick = () => {
    setSteps?.(interviewsTutorialSteps);
    setCurrentStep(InterviewsTutorialSteps.INTRO);
    setIsOpen(true);
  }
  
  return (
    <Auth>
      <InterviewsPageLoader>
        <>
          {isInterviewLoading && <Loading fullscreen />}
          <div className={`interviews-page-ctn`} >
            <InterviewsToolbar onSortChanged={setSort} />
            {!interviews?.length && 
              <>
                <Button
                  className={'tour-btn'}
                  variant='success'
                  onClick={onTutorialStartClick}
                >
                  Let's take a tour
                </Button>
              </>
            }
            {!!interviews?.length && <InterviewsTable
              interviews={interviews}
              sort={sort}
              onDelete={onCardDeleteClick}
            />}
          </div>
        </>
      </InterviewsPageLoader>
    </Auth>
  );
}

export default InterviewsPage;