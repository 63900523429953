import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { httpPost, httpPut } from '../../../services/http.service';
import { RootState } from '../../../store';
import { setIsCurrentCompanyLoadingAction, setCurrentCompanyInfoAction } from '../redux/currentCompany.slice';

const useCurrentCompany = () => {
  const dispatch = useDispatch();
  const companyInfo = useSelector((state: RootState) => state.currentCompany.companyInfo);
  const isCurrentCompanyLoading = useSelector((state: RootState) => state.currentCompany.isLoading);

  const createCurrentCompany = useCallback(async (data: any) => {
    setCurrentCompanyLoading(true);
    let isSuccess = false;
    const response = await httpPost(
      '/company', 
      data,
      {
        successMessage: `Company's page successfully created!`,
      },
    );

    if (response) {
      isSuccess = true;
      setCurrentCompany(response);
    }

    setCurrentCompanyLoading(false);

    return isSuccess;
  }, []);

  const updateCompany = useCallback(async (data: any, skipLoading: boolean = false) => {
    if (!skipLoading) {
      setCurrentCompanyLoading(true);
    }
    let isSuccess = false;
    const response = await httpPut(
      `/company/${data.id}`,
      data,
      {
        successMessage: `Company's page successfully updated!`,
      },
    );

    if (response) {
      isSuccess = true;
      setCurrentCompany(response);
    }

    setCurrentCompanyLoading(false);

    return isSuccess;
  }, []);
  
  const setCurrentCompany = useCallback((companyData: ICurrentCompany) => {
    dispatch(setCurrentCompanyInfoAction(companyData));
  }, []);

  const setCurrentCompanyLoading = useCallback((loadingState:boolean) => {
    dispatch(setIsCurrentCompanyLoadingAction(loadingState));
  }, []);

  return {
    companyInfo,
    isCurrentCompanyLoading,
    createCurrentCompany,
    updateCompany,
    setCurrentCompanyLoading,
    setCurrentCompany,
  };
};

export default useCurrentCompany;

