import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  companyInfo: {} as ICurrentCompany,
  isLoading: false,
};

export const currentCompanySlice = createSlice({
  name: 'currentCompany',
  initialState,
  reducers: {
    setCurrentCompanyInfoAction: (state, action: PayloadAction<ICurrentCompany>) => {
      state.companyInfo = action.payload;
    },
    setIsCurrentCompanyLoadingAction: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { 
  setCurrentCompanyInfoAction, 
  setIsCurrentCompanyLoadingAction,
} = currentCompanySlice.actions;

export default currentCompanySlice.reducer;
