import React, { FC, ReactNode } from 'react';
import SidebarNav from '../../components/SidebarNav/SidebarNav';
import { Outlet } from 'react-router-dom';
import './MainPage.scss';
import Header from '../header/Header';
import Auth from '../auth/Auth';
import MainLoader from './MainLoader';

interface IMainPageProps {
}
const MainPage: FC<IMainPageProps> = ({}) => {
  
  return (
    <Auth>
      <MainLoader>
        <div className={'MainPage'}>
          <div className="top">
            <Header />
          </div>
          <div className="left">
            <SidebarNav />
          </div>
          <div className="right">
            <Outlet />
          </div>
        </div>
      </MainLoader>
    </Auth>
  )
}

export default MainPage;
