import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import TextInput from '../TextInput/TextInput';
import {SelectValueType} from "../SelectInput/SelectInput";
import "./CreateOptions.scss";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { TfiClose } from 'react-icons/tfi';

type Option = {
  val: SelectValueType;
  displayVal: SelectValueType;
  id: SelectValueType;
  isCorrect: boolean;
}

interface ICreateOptions {
  onQuestions: (val: Option[]) => void;
  isAssessmentTypeQuestion: boolean;
  initialOptions?: any[];
}

const getInitialValue = () => {
  const id = uuidv4();
  
  return {
    displayVal: '' ,
    id,
    val: id,
    isCorrect: false,
  }
}

const CreateOptions: React.FC<ICreateOptions> = ({ onQuestions, initialOptions, isAssessmentTypeQuestion }) => {
  const [inputFields, setInputFields] = useState<Option[]>(initialOptions?.length ? initialOptions : [ getInitialValue() ]);
  
  const addTextInput = () => {
    setInputFields([...inputFields, getInitialValue()]);
  };

  const removeTextInput = (id: SelectValueType) => {
    const updatedFields = inputFields.filter((field) => field.id !== id);
    setInputFields(updatedFields);
    onQuestions(updatedFields);
  };

  const handleTextInputChange = (displayVal: string, id: SelectValueType) => {
    const updatedFields = inputFields.map((field) => {
      if (field.id === id) {
        return { 
          ...field,
          val: id,
          displayVal
        };
      }
      return field;
    });
    setInputFields(updatedFields);
    onQuestions(updatedFields);
  };

  const handleSwitchChange = (isChecked:boolean, id: SelectValueType) => {
    const updatedFields = inputFields.map((field) => {
      if (field.id === id) {
        return {
          ...field,
          isCorrect: isChecked,
        }
      }
      return field;
    })
    setInputFields(updatedFields);
    onQuestions(updatedFields);
  }

  const resetSwitchButtons = useCallback(() => {
    const updatedFields = inputFields.map((field) => {
      if (!isAssessmentTypeQuestion) {
        return {
          ...field,
          isCorrect: false,
        }
      }
      
      return field;
    })
    setInputFields(updatedFields);
    onQuestions(updatedFields);
  }, [inputFields, isAssessmentTypeQuestion, onQuestions])

  useEffect (() => {
    resetSwitchButtons();
  },[isAssessmentTypeQuestion]);

  const renderInputs = () => {
   return inputFields.map((inputField) => (
      <div key={inputField.id} className='input-row'>
        <TextInput
          placeholder='Type option here'
          onInput={(displayVal) => handleTextInputChange(displayVal, inputField.id)}
          value={initialOptions ? inputField.displayVal.toString() : ''}
        />
        {inputFields.length > 1 && (
          <>
             {isAssessmentTypeQuestion && (
              <BootstrapSwitchButton
                checked={inputField.isCorrect}
                onlabel='Correct'
                offlabel='Incorrect'
                width={90} 
                height={30}
                onstyle="success" 
                offstyle="secondary"
                onChange={isChecked => handleSwitchChange(isChecked, inputField.id)}
              />
            )}
            <Button
              variant="danger"
              className='button-outline'
              size="sm"
              type="button"
              onClick={() => removeTextInput(inputField.id)}
            >
              <TfiClose/>
            </Button>
          </>
        )}
      </div>
    ))
  }

  return (
    <Form.Group>
      <Form.Label>Add options:</Form.Label>
      {renderInputs()}
      <Button 
        variant="primary" 
        className='button-outline'
        size="sm"
        type="button"
        onClick={addTextInput}
        >
         +
      </Button>
    </Form.Group>
  );
};

export default CreateOptions;
