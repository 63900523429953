import React, { useState, useEffect} from 'react';
import ColorPicker from "../../../components/ColorPicker/ColorPicker";
import FileUpload from '../../fileUpload/FileUpload';
import RadioInput from '../../../components/RadioInput/RadioInput';

const COLOR_ID = 'color-id';
const IMAGE_ID = 'image-id';

const options = [
  {
    val: 'Color',
    displayVal: 'Color',
    id: COLOR_ID
  }, {
    val: 'Image',
    displayVal: 'Image',
    id: IMAGE_ID
  }
]

export type ColorsTabDataType = Record<string, any>;

interface IColorsTabProps {
  onData: (data: ColorsTabDataType) => void;
  data?: any;
}

const ColorsTab: React.FC<IColorsTabProps> = ({ onData, data }) => {
  const {
    banner, background,
    text, backgroundImage,
    icons, typeOfBanner,
    contentBackground, buttons,
    buttonsText,
  } = data?.colors || {};

  const [bannerColor, setBannerColor] = useState(banner || '');
  const [bgColor, setBgColor] = useState(background || '');
  const [textColor, setTextColor] = useState(text || '');
  const [bgImageUrl, setBgImageUrl] = useState(backgroundImage || '');
  const [bannerType, setBannerType] = useState(typeOfBanner || '');
  const [iconsColor, setIconsColor] = useState(icons || '')
  const [contentBgColor, setContentBgColor] = useState(contentBackground || '');
  const [buttonsColor, setButtonsColor] = useState(buttons || '');
  const [buttonsTextColor, setButtonsTextColor] = useState(buttonsText || '');

  useEffect(() => {
    const colorsInfo = {
      bannerColor, bgColor,
      textColor, bgImageUrl,
      iconsColor, bannerType,
      contentBgColor,
      buttonsColor,
      buttonsTextColor,
    }

    onData(colorsInfo);
  },[
    bannerColor, bgColor,
    textColor, bgImageUrl,
    onData, iconsColor,
    bannerType, contentBgColor,
    buttonsColor, buttonsTextColor,
  ])

  return (
    <>
      <div className="mb-3">
        <ColorPicker 
          onData={setBgColor} 
          label='Pick background color'
          defaultColor={data?.colors ? bgColor : '#fff'}
        />
      </div>
      <div className="mb-3">
        <ColorPicker 
          onData={setContentBgColor} 
          label='Pick content background color'
          defaultColor={data?.colors ? contentBgColor : '#fff'}
        />
      </div>
      <div className="mb-3">
        <ColorPicker 
          onData={setTextColor} 
          label='Pick main text color'
          defaultColor={data?.colors ? textColor : '#333'}
        />
      </div>
      <div className="mb-3">
      <ColorPicker 
          onData={setIconsColor} 
          label={`Pick icons' color`}
          defaultColor={data?.colors ? iconsColor : '#002956'}
        />
      </div>
      <div className="mb-3">
      <ColorPicker 
          onData={setButtonsColor} 
          label={`Pick buttons' color`}
          defaultColor={data?.colors ? buttonsColor : '#198754'}
        />
      </div>
      <div className="mb-5">
        <ColorPicker 
          onData={setButtonsTextColor} 
          label={`Pick buttons' text color`}
          defaultColor={data?.colors ? buttonsTextColor : '#dee2e6'}
        />
      </div>
      <div className="mb-3">
      <RadioInput 
        options={options}
        onInput={setBannerType}
        selectedByDefault={data?.colors ? bannerType : `${COLOR_ID}`}
        name='banner-bg'
        label='Choose banner type'
      />
        {bannerType === COLOR_ID && <ColorPicker 
          onData={setBannerColor} 
          label='Pick banner color'
          defaultColor={data?.colors ? bannerColor : '#002956'}
        />}
        {bannerType === IMAGE_ID && <FileUpload 
          onData={setBgImageUrl} 
          label='Choose banner image'
          value={data?.colors ? bgImageUrl : ''}
          types={['image']}
        />}
      </div>
    </>
  )
}

export default ColorsTab;