import React, { useCallback, useEffect, useRef, useState } from 'react';
import './VoiceInput.scss';
import { Button, Spinner } from 'react-bootstrap';
import useFileUpload, { IUploadCallbackData } from '../../modules/fileUpload/useFileUpload';
import useAuth from '../../modules/auth/hooks/useAuth';
import { transcribeVideo } from '../../services/aiService';
import useProductTracking from '../../hooks/useProductTracking';
import { HiMicrophone } from "react-icons/hi2";
import { FaRegStopCircle } from "react-icons/fa";
import TooltipComponent from '../Tooltip/TooltipComponent';
import useNotifications from '../../modules/notification/hooks/useNotifications';

interface IVoiceInputProps {
  className?: string;
  onAudioUrl?: (url: string) => void;
  onSpeechRecognized?: (text: string) => void;
  onRecognizingProgress?: (isInProgress: boolean) => void;
  disabled?: boolean;
  initialValue?: string;
}

const VoiceInput: React.FC<IVoiceInputProps> = ({
  className = '',
  onSpeechRecognized,
  onRecognizingProgress,
  onAudioUrl,
  disabled,
  initialValue,
}) => {
  const [capturing, setCapturing] = useState<boolean>(false);
  const [transcribing, setTranscribing] = useState<boolean>(false);
  const [recordedChunks, setRecordedChunks] = useState<any[]>([]);
  const [audioUrl, setAudioUrl] = useState('');
  const [remoteUrl, setRemoteUrl] = useState(initialValue || '');
  const [fileObj, setFileObj] = useState<File | null>(null);
  const [textVersion, setTextVersion] = useState<string | null>(null);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [hasPermission, setHasPermission] = useState(false);

  const { authUser } = useAuth();
  const { upload, progress } = useFileUpload();
  const { showNotification } = useNotifications();
  const tracker = useProductTracking();
  const mediaRecorderRef = useRef<any>(null);
  const mimeType = MediaRecorder.isTypeSupported("audio/webm") ? 'audio/webm' : "audio/mp4";
  const isRecognizingInProgress = !!progress || transcribing;

  useEffect(() => {
    onRecognizingProgress?.(isRecognizingInProgress)
  },[isRecognizingInProgress])
  
  useEffect(() => {
    tracker.track(`Audio recording showed`, {
      email: authUser?.email || 'Candidate',
    });
    return () => {
      tracker.track(`Audio recording closed`, {
        email: authUser?.email || 'Candidate',
      });
      wipeLocalData();
    }
  }, []);
  
  useEffect(() => {
    if (typeof textVersion === 'string') {
      onSpeechRecognized?.(textVersion);
    }
  }, [textVersion]);
  
  useEffect(() => {
    if (recordedChunks.length && !capturing) {
      const filename = `audio`;
      const file = new File(recordedChunks, filename, {
        type: mimeType,
      });
      const url = URL.createObjectURL(file);
      
      tracker.track(`Audio recording local file created`, {
        email: authUser?.email || 'Candidate',
      });
      setAudioUrl(url);
      setFileObj(file);
    }
  }, [capturing, recordedChunks.length]);
  
  const handleDataAvailable = React.useCallback(({ data }: any) => {
    if (data.size) {
      setRecordedChunks((prev) => prev.concat(data));
    }
  }, []);
  
  const handleStartCaptureClick = React.useCallback(async () => {
    setCapturing(true);
    tracker.track(`Audio recording - started`, {
      email: authUser?.email || 'Candidate',
    });
    console.log('mime ', mimeType);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setMediaStream(stream);
      setHasPermission(true);
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType,
      });
      
      mediaRecorderRef.current?.addEventListener('dataavailable', handleDataAvailable);
      
      mediaRecorderRef.current?.addEventListener('stop', () => {
        if (mediaRecorderRef.current?.state !== 'recording') {
          setCapturing(false);
        }
      });
      mediaRecorderRef.current?.start();
    } catch (error) {
      console.log(error);
      setHasPermission(false);
      setCapturing(false);

      showNotification({
        color: 'danger',
        content: 'You have to give permission to use the microphone',
      });
    }
  }, [handleDataAvailable]);
  
  const handleStopCaptureClick = React.useCallback(() => {
    tracker.track(`Audio recording - stopped`, {
      email: authUser?.email || 'Candidate',
    });

    if (mediaStream) {
      mediaStream.getTracks().forEach(track => track.stop());
    }

    mediaRecorderRef.current?.stop();
  }, [mediaStream]);
  
  const onUploadComplete = async ({ url }: IUploadCallbackData) => {
    setRemoteUrl(url);
    onAudioUrl?.(url);
    wipeLocalData();
    
    tracker.track(`Audio recording - got remote url`, {
      email: authUser?.email || 'Candidate',
    });
    
    if (onSpeechRecognized) {
      setTranscribing(true);
      const res = await transcribeVideo(url);
      console.log('##transcribed:', res);
      
      if (res?.fullText) {
        setTextVersion(res.fullText);
      }
      setTranscribing(false);
    }
  }
  
  useEffect(() => {
    handleSaveAudio();
  }, [fileObj])
  
  const handleSaveAudio = React.useCallback(() => {
    if (fileObj) {
      const folder = authUser?.uid || 'candidates';
      
      tracker.track(`Audio recording - saving`, {
        email: authUser?.email || 'Candidate',
      });
    
      upload(fileObj, onUploadComplete, `audios/${folder}`);
    }
  }, [authUser?.uid, fileObj, onUploadComplete, upload]);
  
  const wipeLocalData = () => {
    if (audioUrl) {
      URL.revokeObjectURL(audioUrl);
      setAudioUrl('');
    }
    setRecordedChunks([]);
    setFileObj(null);
    
    if (mediaStream) {
      mediaStream.getTracks().forEach(track => track.stop());
    }
  }

  return (
    <div>
      {!isRecognizingInProgress && 
      <TooltipComponent text='Dictate your question instead of typing it'>
        <button 
          type='button'
          onClick={capturing ? handleStopCaptureClick : handleStartCaptureClick}
          className={`record-btn ${className}`}
        >
          {capturing ? <FaRegStopCircle /> : <HiMicrophone />}
        </button>
      </TooltipComponent>}
      {isRecognizingInProgress && <Spinner className='mb-3' animation={'border'} />}
    </div>
  );
};

export default VoiceInput;
