import { FC } from 'react';

export const themeLogo = {
  light: {
    dot: '#EA5C6D',
    text: '#002956',
    bg: 'white',
    textSecondary: 'white',
  },
  dark: {
    dot: '#EA5C6D',
    text: 'white',
    bg: '#002956',
    textSecondary: 'white',
  }
};

interface ILogoProps {
  colorMode?: 'dark' | 'light';
  width?: string;
  height?: string;
}
const Logo: FC<ILogoProps> = ({
  colorMode = 'dark',
  width = '100%',
  height = '100%',
}) => {
  const { dot, text, bg, textSecondary } = themeLogo[colorMode];
  
  return (
    <svg 
      width={width}
      height={height}
      viewBox="0 0 1097 220" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_236_72)">
        <path 
          d="M185.9 219.6H34.1498C15.6798 219.6 0.569824 204.49 0.569824 186.02V34.2699C0.569824 15.7999 15.6798 0.689941 34.1498 0.689941H185.9C204.37 0.689941 219.48 15.7999 219.48 34.2699V186.01C219.48 204.49 204.37 219.6 185.9 219.6Z" 
          fill={text}
        />
        <path 
          d="M181.1 59.5601H207.56L151.49 161.38H130.46L105.74 116.52L117.8 94.3801L140.97 133.44L181.1 59.5601Z" 
          fill={bg}
        />
        <path 
          d="M103.38 59.5601H129.55L74.1 161.38H53.2999L12.46 91.7001H38.63L63.7 134.16L103.38 59.5601Z" 
          fill={bg}
        />
        <path 
          d="M12.46 85.63V59.24H38.64V85.63H12.46Z" 
          fill={dot}
        />
        <path 
          d="M256.1 161.33V59.79H273.02V161.33H256.1Z" 
          fill={textSecondary}
        />
        <path 
          d="M372.96 161.33L310.08 90.86V161.33H290.2V59.79H309.15L371.85 130.19V59.79H391.73V161.33H372.96Z" 
          fill={textSecondary}
        />
        <path 
          d="M464.36 79.8501V161.34H444.48V79.8501H403.69V59.8H505.23V79.8501H464.36Z" 
          fill={textSecondary}
        />
        <path 
          d="M603.81 161.33V135.07C603.81 125.44 598.99 120.63 589.34 120.63H542.21V161.33H522.16V59.79H605.59C617.66 59.79 623.7 65.83 623.7 77.9V94.23C623.7 99.48 619.67 104.52 611.6 109.38C619.67 111.24 623.7 117.08 623.7 126.9V161.34H603.81V161.33ZM603.81 79.8501H542.21V100.58H589.4C596.06 100.58 600.36 98.5001 602.28 94.3501C603.36 91.9201 603.87 87.0901 603.81 79.8501Z" 
          fill={textSecondary}
        />
        <path 
          d="M696.28 161.33H677.5L635.19 59.79H655.53L686.89 130.63L718.24 59.79H738.59L696.28 161.33Z" 
          fill={textSecondary}
        />
        <path 
          d="M810.58 161.33H791.8L749.49 59.79H769.83L801.19 130.63L832.54 59.79H852.89L810.58 161.33Z" 
          fill={textSecondary}
        />
        <path 
          d="M925.44 160.87V140.05H946.17V160.87H925.44Z" 
          fill={textSecondary}
        />
        <path 
          d="M1042.98 161.33V120.46H981.29V161.33L961.24 161.25V77.9C961.24 65.83 967.3 59.79 979.43 59.79H1044.75C1056.82 59.79 1062.86 65.83 1062.86 77.93V161.33H1042.98ZM1042.98 79.8501H981.29V100.58H1042.98V79.8501Z" 
          fill={textSecondary}
        />
        <path 
          d="M1080.04 161.33V59.79H1096.96V161.33H1080.04Z" 
          fill={textSecondary}
        />
        <path 
          d="M892.17 59.79L860.82 130.63L848.72 103.3L837.69 128.38L851.42 161.33H870.21L912.52 59.79H892.17Z" 
          fill={textSecondary}
        />
      </g>
      <defs>
        <clipPath id="clip0_236_72">
          <rect width="1097" height="220" fill={bg}/>
        </clipPath>
      </defs>
    </svg>
  
  );
};

export default Logo;