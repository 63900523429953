import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import "../Signup.scss";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import LogoVertical from "../../../../components/svg/LogoVertical";
import { FaGoogle } from "react-icons/fa6";
import { useState } from "react";
import Countdown from "../../../../components/Countdown/Countdown";

const SignupMessage = () => {
  const { googleLogin, resendVerificationEmail, signOut } = useAuth();
  const [isTimerStart, setIsTimerStart] = useState(true);
  const [canResend, setCanResend] = useState(false);

  const handleGoogleLogin = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    googleLogin();
  };

  const handleResend = () => {
    resendVerificationEmail();
  };

  return (
    <Stack gap={3} className={"sign-up-ctn"}>
      <div className="logo">
        <LogoVertical colorMode={"dark"} />
      </div>
      <Button
        variant="light"
        className={"google-btn mb-3"}
        onClick={handleGoogleLogin}
      >
        <FaGoogle /> Sign up with Google
      </Button>
      <hr className={"mb-2"} />
      <p className="mb-3">
        Verification email has been sent. Please follow instructions from email
        to proceed
      </p>
      <p className={"mb-1"}>
        Didn't receive an email?{" "}
        {!canResend && <span>
          {" "}
          You can resend it after{" "}
          <Countdown
            start={isTimerStart}
            onLimit={() => {
              setCanResend(true);
              setIsTimerStart(false);
            }}
          />
        </span>}
      </p>
      <Button
        variant="link"
        style={{ padding: 0, border: 0 }}
        onClick={() => {
          handleResend();
          setIsTimerStart(true);
          setCanResend(false);
        }}
        disabled={!canResend}
      >
        Send again
      </Button>
      <Link to="/login" onClick={signOut}>
        Log in
      </Link>
    </Stack>
  );
};

export default SignupMessage;
