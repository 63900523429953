import './PopoverMenu.scss';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import React, { FC } from 'react';
import { Placement } from 'react-bootstrap/types';

interface IPopoverMenuProps {
  children: React.ReactNode | React.ReactNode[];
  body: React.ReactNode | React.ReactNode[];
  header?: React.ReactNode | React.ReactNode[];
  placement?: Placement;
  className?: string;
}
const PopoverMenu: FC<IPopoverMenuProps> = ({
  children,
  body,
  header = '',
  placement = 'bottom',
  className = '',
                     }) => {

  return (
    <OverlayTrigger
      trigger="click"
      key={placement}
      placement={placement}
      overlay={
        <Popover className={className}>
          {header && <Popover.Header as="h3">
            {header}
          </Popover.Header>}
          <Popover.Body>
            {body}
          </Popover.Body>
        </Popover>
      }
    >
      <div>
        {children}
      </div>
    </OverlayTrigger>
  )
}

export default PopoverMenu;