import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { httpGet } from '../../../services/http.service';
import Loading from '../../../components/Loading/Loading';
import DynamicInput from '../../../components/DynamicInput';
import { Accordion } from 'react-bootstrap';
import './InterviewAnswersPage.scss';
import useNotifications from '../../notification/hooks/useNotifications';
import { MdContentCopy } from 'react-icons/md';
import ChangeStatusButtons from '../../../components/ChangeStatusButtons/ChangeStatusButtons';
import TooltipComponent from '../../../components/Tooltip/TooltipComponent';

const InterviewAnswersPage = () => {
  const { interviewId, answerId } = useParams();
  const [answer, setAnswer] = useState<any>(null);
  const [interview, setInterview] = useState<any>(null);
  const [status, setStatus] = useState<any>(null);
  const { showNotification } = useNotifications();

  useEffect(() => {
    const load = async () => {
      const data = await httpGet(`/interview-results/${interviewId}`);
      if (data && !data?.error) {
        setInterview(data?.interview || {});
        const answerData = data?.stats.find((s: any) => s.id === answerId);
        setAnswer(answerData || null);
        setStatus(answerData?.status);
      }
    }
    load();
  }, []);
  
  if (!answer || !interview) {
    return <Loading text={'Loading answers...'} />
  }
  
  const allContacts = answer?.allContacts;
  const id = answer?.id;

  const onCopy = (text: string, field: string) => {
    navigator.clipboard.writeText(text);
    
    showNotification({
      color: 'success',
      content: `${field} copied!`,
    });
  }
  
  const onStatusUpdated = (id: string, status: string) => {
    setStatus(status)
  };

  const renderContacts = () => {
    const contactsElements = [];

    for (const contact in allContacts) {
      if (allContacts.hasOwnProperty(contact)) {
        contactsElements.push(
          <div className={'mb-2'} key={contact}>
            <span>{`${contact}: `}</span>
              {allContacts[contact]}
            <TooltipComponent text={`Copy ${contact.toLowerCase()}`} >
              <MdContentCopy onClick={
                () => onCopy(allContacts[contact], `${contact}`)} 
              />
            </TooltipComponent>
          </div>
        );
      }
    }

    return contactsElements;
  }
  
  return (
    <div className="InterviewAnswersPage">
        <div className="top-bar">
          <div className={'candidate-details'}>
            {renderContacts()}
          </div>
          <ChangeStatusButtons
            currentStatus={status}
            onStatusUpdated={onStatusUpdated}
            resultId={id}
          />
        </div>
      <Accordion defaultActiveKey={'0'}>
        {answer.responses.map((a: any, i: number) => {
          const questionData = interview?.questions?.find((q: any)=> q.id === a.question);
          const question = {
            ...questionData?.question,
            disabled: true,
            className: 'mb-3',
          }
          const answer = {
            ...questionData?.answer,
            type: a.answerType,
            value: a.answerVal,
            disabled: true,
            className: 'mb-3',
          }
          
          const qType = questionData?.question?.type;
          const isVideoQuestion = qType === 'Video';
          const qText = isVideoQuestion ? 'Video question' : questionData?.question?.value;
          
          return (
            <Accordion.Item
              eventKey={`${i}`}
              key={a.id}
            >
              <Accordion.Header>
                #{i+1} {qText}
              </Accordion.Header>
              <Accordion.Body>
                <h4>Question:</h4>
                <DynamicInput
                  data={question}
                />
                <h4>Answer:</h4>
                <DynamicInput
                  data={answer}
                />
              </Accordion.Body>
            </Accordion.Item>
          )
        })}
      </Accordion>
    </div>
  )
}

export default InterviewAnswersPage;