import './CardMenu.scss';
import React from 'react';
import { MdDeleteForever, MdEdit, MdArrowDownward, MdDownload } from 'react-icons/md';

interface ICardMenuProps {
  onDelete?: () => void;
  onEdit?: () => void;
  onDownload?: () => void;
}

const CardMenu: React.FC<ICardMenuProps> = ({ onDelete, onEdit, onDownload }) => {
  const hasDeleteAction = !!onDelete;
  const hasEditAction = !!onEdit;
  const hasDownloadAction = !!onDownload;

  return (
    <div className={`CardMenu`}>
      {hasDownloadAction && <div
        className={`left-action ${hasDownloadAction && !hasDeleteAction ? 'full-width' : ''}`}
        onClick={onDownload}
      >
        <MdDownload />
      </div>}
      {hasEditAction && <div
        className={`left-action ${hasEditAction && !hasDeleteAction ? 'full-width' : ''}`}
        onClick={onEdit}
      >
        <MdEdit />
      </div>}
      {hasDeleteAction && <div
        className={`right-action ${hasDeleteAction && !hasEditAction ? 'full-width' : ''}`}
        onClick={onDelete}
      >
        <MdDeleteForever />
      </div>}
    </div>
  );
}

export default CardMenu;
