import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  values: [] as any[],
  isLoading: false,
};


export const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setQuestionsAction: (state, action: PayloadAction<any[]>) => {
      state.values = action.payload;
    },
    setIsQuestionsLoadingAction: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setQuestionsAction ,
  setIsQuestionsLoadingAction,
} = questionsSlice.actions;

export default questionsSlice.reducer;