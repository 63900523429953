import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  plans: {} as Record<string, any>,
  currentPlan: null as null | Record<string, any>,
  isLoading: false,
};

export const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setCurrentPlanAction: (state, action: PayloadAction<Record<string, any> | null>) => {
      state.currentPlan = action.payload;
    },
    setPlansAction: (state, action: PayloadAction<Record<string, any>>) => {
      state.plans = action.payload;
    },
    setIsBillingLoadingAction: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setPlansAction,
  setCurrentPlanAction,
  setIsBillingLoadingAction,
} = billingSlice.actions;

export default billingSlice.reducer;
