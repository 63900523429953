import React, { ChangeEvent, useState, useCallback, useEffect } from 'react';
import Form from 'react-bootstrap/Form';

type ValueType = string;
interface INumberInput {
  value?: ValueType;
  defaultValue?: ValueType;
  label?: string;
  placeholder?: string;
  className?: string;
  onInput?: (val: ValueType) => void;
  disabled?: boolean;
  required?: boolean;
  validationLabel?: string;
  maxValue?: number;
  minValue?: number;
}

const NumberInput: React.FC<INumberInput> = ({
  value, defaultValue, label,
  placeholder, onInput,
  className = '', disabled,
  required = false, validationLabel,
  maxValue, minValue,
}) => {
  const [inputValue, setInputValue] = useState(value);
  
  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onInput?.(newValue);
  }, [onInput]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);
  
  return (
    <Form.Group className={`NumberInput ${className}`}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        style={{ width: '100px' }}
        type='number'
        required={required}
        disabled={disabled}
        value={inputValue}
        defaultValue={defaultValue}
        onChange={onChange}
        min={minValue}
        max={maxValue}
      />
      {validationLabel && <Form.Control.Feedback type="invalid">
        {validationLabel}
      </Form.Control.Feedback>}
    </Form.Group>
  )
};

export default NumberInput;
