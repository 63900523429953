import React, { ChangeEvent, useState, useCallback, useEffect } from 'react';
import Form from 'react-bootstrap/Form';

interface CheckboxOption {
  val: string | number;
  displayVal: string;
  id: string;
  checked: boolean;
}

interface ICheckboxInputProps {
  label: string;
  className?: string;
  disabled?: boolean;
  onInput?: (option: CheckboxOption) => void;
  id: string;
  checked: boolean;
  val: string | number;
}

const CheckboxInput: React.FC<ICheckboxInputProps> = ({
  label, onInput, 
  id, className, disabled, 
  checked, val,
 }) => {

  const [isChecked, setIsChecked] = useState(checked);
  
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleCheckboxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newChecked = e.target.checked;
    setIsChecked(newChecked);
    const updatedOption: CheckboxOption = { 
      val: label, 
      displayVal: label, 
      id,
      checked: newChecked
    };
    onInput?.(updatedOption);
  }, [label, onInput, id, val]);

  return (
    <Form.Check className={`CheckboxInput mb-3 ${className}`}
      type="checkbox"
      id={id}
      label={label}
      checked={disabled ? checked : isChecked}
      onChange={handleCheckboxChange}
      disabled={disabled}
    />
  );
};

export default CheckboxInput;
