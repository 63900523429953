import { useCallback } from 'react';
import { httpPost, httpDelete, httpPut } from '../../../services/http.service';
import { setQuestionsAction, setIsQuestionsLoadingAction } from '../redux/questions.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';

const useQuestions = () => {
  const dispatch = useDispatch();
  const questions = useSelector((state: RootState) => state.questions.values);
  const isQuestionsLoading = useSelector((state: RootState) => state.questions.isLoading);
  
  const createQuestion = useCallback(async (data: any) => {
    setQuestionsLoading(true);
    let isSuccess = false;
    const questionsResponse = await httpPost(
      '/questions',
      data,
      {
        successMessage: 'Question successfully created!'
      },
    );
    if (questionsResponse) {
      isSuccess = true;
      setQuestions(questionsResponse);
    }
    setQuestionsLoading(false);
    
    return isSuccess;
  }, []);
  
  const updateQuestion = useCallback(async (data: any) => {
    setQuestionsLoading(true);
    let isSuccess = false;
    const questionsResponse = await httpPut(
      `/questions/${data?.id}`,
      data,
      {
        successMessage: 'Question successfully updated!',
      },
    );
    if (questionsResponse) {
      isSuccess = true;
      setQuestions(questionsResponse);
    }
    setQuestionsLoading(false);
    
    return isSuccess;
  }, []);
  
  const deleteQuestion = useCallback(async (id: string) => {
    setQuestionsLoading(true);
    let isSuccess = false;
    const questionsResponse = await httpDelete(
      `/questions/${id}`,
      {
        successMessage: 'Question successfully deleted!',
      },
    );
    if (questionsResponse) {
      isSuccess = true;
      setQuestions(questionsResponse);
    }
    setQuestionsLoading(false);
    
    return isSuccess;
  }, []);
  
  const setQuestions = useCallback((questions: any) => {
    dispatch(setQuestionsAction(questions));
  }, []);
  
  const setQuestionsLoading = useCallback((isLoading: boolean) => {
    dispatch(setIsQuestionsLoadingAction(isLoading));
  }, []);
  
  const getQuestionById = useCallback((id: string) => {
    console.log('getQuestionById', id, questions);
    return questions.find((q: IQuestion) => q.id === id);
  }, [questions]);
  
  return {
    questions,
    isQuestionsLoading,
    createQuestion,
    setQuestions,
    setQuestionsLoading,
    deleteQuestion,
    updateQuestion,
    getQuestionById,
  };
}

export default useQuestions;