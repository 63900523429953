import React, { useState, useEffect, } from 'react';
import { SketchPicker } from 'react-color';
import './ColorPicker.scss';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface IColorPickerProps {
  label?: string;
  onData: (color: string) => void;
  defaultColor?: string;
}

const ColorPicker: React.FC<IColorPickerProps> = ({ 
  label, onData,
  defaultColor = '#fff',
}) => {
  const [color, setColor] = useState<string>(defaultColor);

  const handleColorChange = (color: any) => {
    setColor(color.hex);
  }

  useEffect(() => {
    onData(color);
  }, [color, onData]);


  const popover = (
    <Popover id="color-picker-popover">
      <Popover.Body>
        <SketchPicker color={color} onChange={handleColorChange} />
      </Popover.Body>
    </Popover>
  );

  return ( 
    <div className='picker-ctn'>
      {label && <label>{label}</label>}
      <OverlayTrigger trigger="click" rootClose placement="auto" overlay={popover}>
        <div className='button' style={{ backgroundColor: color }} />
      </OverlayTrigger>
    </div>
  )
}

export default ColorPicker;
