import React, { useState, useCallback, useEffect } from "react";
import RadioInput from "../RadioInput/RadioInput";
import FileUpload from "../../modules/fileUpload/FileUpload";
import VideoInput from "../VideoInput/VideoInput";
import InfoHelper from '../InfoHelper/InfoHelper';

const UPLOAD_VIDEO_ID = 'upload-video-id';
const RECORD_VIDEO_ID = 'record-video-id';

const optionsVideoAttachment = [{
  val:'Upload video',
  displayVal:'Upload video',
  id: UPLOAD_VIDEO_ID,
}, {
  val: 'Record video',
  displayVal: 'Record video',
  id: RECORD_VIDEO_ID,
}];

interface IVideoAttachmentProps {
  onVideoUrl?: (url: string) => void;
  helperContent?: string | React.ReactNode;
}

const VideoAttachment: React.FC<IVideoAttachmentProps> = ({ onVideoUrl, helperContent }) => {
  const [type, setType] = useState('');
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl('');
  },[type])

  useEffect(() => {
    if (url && onVideoUrl) {
      onVideoUrl(url);
    }
  }, [url, onVideoUrl]);

  const renderAttachmentInputByType = useCallback(() => {
    switch (type) {
      case UPLOAD_VIDEO_ID:
        return (
          <span style={{marginBottom: '24px'}}>
            <FileUpload onData={setUrl} types={['video']}/>
          </span>
        )
      case RECORD_VIDEO_ID:
        return <VideoInput onVideoUrl={setUrl}/>
    }
  },[type])

  return (
    <>
      <label style={{marginBottom: '16px'}}>
        <span>Attach video (200MB max)</span>
        {helperContent && <InfoHelper text={helperContent} />}
      </label>
      <RadioInput 
        options={optionsVideoAttachment} 
        name='videoAttachment'
        onInput={setType}
      />
      {renderAttachmentInputByType()}
    </>
  )
}

export default VideoAttachment;