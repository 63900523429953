import React from 'react';

const firstStepContent = (
  <div>
    <p>
      Welcome to Intrvw.ai! This tutorial will help you to understand
      how to create Interview and use it for candidates screening.
    </p>
    <p>
      Let's create a new Interview by clicking this button
    </p>
  </div>
);

const secondStepContent = (
  <div>
    <p>
      Let's start with position's title and introduction - it could be job description or responsibilities described.
    </p>
    <p>
      Also, you can attach an intro video from recruiter or manager.
    </p>
    <p>
      Press "Continue" button when done
    </p>
  </div>
);

export enum InterviewsTutorialSteps {
  INTRO,
  MODAL_FIRST_STEP,
  MODAL_SECOND_STEP,
  MODAL_THIRD_STEP,
  MODAL_FOURTH_STEP,
  FINAL,
}

export const interviewsTutorialSteps: any[] = [{
  selector: '#interview-tutorial-first-step',
  content: firstStepContent,
  position: 'right',
}, {
  selector: '.modal-content',
  content: secondStepContent,
  position: [0, 0], // can't disable 'transform' via styles, since it will be overwritten later by library
  styles: {
    popover: (styles: any) => {
      return {
        ...styles,
        margin: '0 auto',
        right: 0,
        top: 15,
      }
    },
  },
}, {
  selector: '.modal-content',
  content: `Here, select the contacts that you are interested in receiving from the candidate`,
  position: [0, 0],
  styles: {
    popover: (styles: any) => {
      return {
        ...styles,
        margin: '0 auto',
        right: 0,
        top: 15,
      }
    },
  },
}, {
  selector: '.modal-content',
  content: `You can paste a job description here so that AI can come up with questions for candidates. Once you've pasted job description, just hit the "Continue" button`,
  position: [0, 0],
  styles: {
    popover: (styles: any) => {
      console.log(styles);
      return {
        ...styles,
        margin: 'auto 0',
        right: 15,
        left: 'auto',
        bottom: 0,
        top: 0,
        maxHeight: 200,
      }
    },
  },
}, {
  selector: '.modal-content',
  content: `Please make sure to review generated questions if any. You can edit them or create your own questions. Click the "Save Interview" button whenever you ready.`,
  position: [0, 0],
  styles: {
    popover: (styles: any) => {
      return {
        ...styles,
        margin: 'auto 0',
        left: 15,
        right: 'auto',
        bottom: 0,
        top: 0,
        maxHeight: 200,
      }
    },
  },
}, {
  selector: '.copy-btn',
  content: `Great! You've created your first interview.
  Now let's copy the link and open it in a new tab to see what we've got.
  After that you can post this link on recruitments platforms or social media
  or just invite a specific candidate for an asynchronous interview by sharing this link.`,
  position: [0, 0],
  styles: {
    popover: (styles: any) => {
      return {
        ...styles,
        top: 280,
        left: '70vw',
      }
    },
  },
}];