import { Placement } from 'react-bootstrap/types';
import React from 'react';
import './InfoHelper.scss';
import TooltipComponent from '../Tooltip/TooltipComponent';
import { MdHelpOutline } from 'react-icons/md';

interface IInfoHelperProps {
  text: string | React.ReactNode;
  position?: Placement;
  marginLeft?: string;
  marginRight?: string;
  iconColor?: string;
}

const InfoHelper: React.FC<IInfoHelperProps> = ({
  text,
  position,
  marginLeft = '10px',
  marginRight = 0,
  iconColor = '#ccc',
}) => {
  const iconStyle = {
    color: iconColor,
    cursor: 'pointer',
    fontSize: 20,
  };

  return (
    <span className={'helper-ctn'} style={{ marginLeft, marginRight }}>
      <TooltipComponent
        text={text}
        position={position}
      >
        <span style={{ width: 20 }}>
          <MdHelpOutline style={iconStyle} />
        </span>
      </TooltipComponent>
    </span>
  );
}

export default InfoHelper;
