import React, { useEffect, useRef, useState } from 'react';
import DevicePicker from '../../modules/opentok/DevicePicker/DevicePicker';
import { Button } from 'react-bootstrap';
import Webcam from 'react-webcam';
import useFileUpload, { IUploadCallbackData } from '../../modules/fileUpload/useFileUpload';
import useAuth from '../../modules/auth/hooks/useAuth';
import './VideoInput.scss';
import Timer from '../Timer/Timer';
import { fixVideoDuration } from '../../modules/media/mediaUtils';
import { transcribeVideo } from '../../services/aiService';
import useProductTracking from '../../hooks/useProductTracking';
import useNotifications from "../../modules/notification/hooks/useNotifications";
import {downloadFile} from "../../modules/fileUpload/FileUpload";

const defaultVideoConstraints = {
  width: {
    ideal: 1280,
    max: 1920,
  },
  height: {
    ideal: 720,
    max: 1080
  },
  facingMode: 'user',
  frameRate: 60,
  resizeMode: "crop-and-scale",
  deviceId: 'default',
};
const defaultAudioConstraints = {
  noiseSuppression: false,
  echoCancellation: true,
  sampleRate: 44100,
  sampleSize: 16,
  autoGainControl: false,
  suppressLocalAudioPlayback: true,
  deviceId: 'default',
}

const getDurationInSeconds = (durationInMinutes: number) => durationInMinutes * 60;

interface IVideoInputProps {
  className?: string;
  onVideoUrl?: (url: string) => void;
  onSpeechRecognized?: (text: string) => void;
  noPreview?: boolean;
  initialValue?: string;
  disabled?: boolean;
  maxDuration?: number;
}

const VideoInput: React.FC<IVideoInputProps> = ({
  className = '',
  onVideoUrl,
  onSpeechRecognized,
  noPreview,
  initialValue,
  disabled,
  maxDuration = 1, //minutes
}) => {
  const { authUser } = useAuth();
  const tracker = useProductTracking();
  const { upload, progress } = useFileUpload();
  const webcamRef = useRef<any>(null);
  const mediaRecorderRef = useRef<any>(null);
  const previewRef = useRef<any>(null);
  const [capturing, setCapturing] = useState<boolean>(false);
  const [transcribing, setTranscribing] = useState<boolean>(false);
  const [hasPermissions, setHasPermissions] = useState<boolean>(false);
  const [recordedChunks, setRecordedChunks] = useState<any[]>([]);
  const [videoConstraints, setVideoConstraints] = useState<any>(defaultVideoConstraints);
  const [audioConstraints, setAudioConstraints] = useState<any>(defaultAudioConstraints);
  const [isVideoError, setIsVideoError] = useState(false);
  const { showNotification } = useNotifications();

  const [videoUrl, setVideoUrl] = useState('');
  const [remoteUrl, setRemoteUrl] = useState(initialValue || '');
  const [fileObj, setFileObj] = useState<File | null>(null);
  
  const [textVersion, setTextVersion] = useState<string | null>(null);
  const mimeType = MediaRecorder.isTypeSupported("video/mp4") ? 'video/mp4' : "video/webm";

  useEffect(() => {
    tracker.track(`Video recording showed`, {
      email: authUser?.email || 'Candidate',
    });
    return () => {
      tracker.track(`Video recording closed`, {
        email: authUser?.email || 'Candidate',
      });
      wipeLocalData();
    }
  }, []);
  
  useEffect(() => {
    if (typeof textVersion === 'string') {
      onSpeechRecognized?.(textVersion);
    }
  }, [textVersion]);
  
  useEffect(() => {
    if (recordedChunks.length && !capturing) {
      const filename = `video`;
      const file = new File(recordedChunks, filename, {
        type: mimeType,
      });
      const url = URL.createObjectURL(file);
      
      tracker.track(`Video recording local file created`, {
        email: authUser?.email || 'Candidate',
      });
      setVideoUrl(url);
      setFileObj(file);
    }
  }, [capturing, recordedChunks.length]);
  
  const handleDataAvailable = React.useCallback(({ data }: any) => {
    if (data.size) {
      setRecordedChunks((prev) => prev.concat(data));
    }
  }, []);
  
  const handleStartCaptureClick = React.useCallback(() => {
    if (!webcamRef.current?.stream) {
      tracker.track(`Error - no video stream`, {
        email: authUser?.email || 'Candidate',
      });
      console.log('Error: no stream to start recording');
      return;
    }
    setCapturing(true);
    tracker.track(`Video recording - started`, {
      email: authUser?.email || 'Candidate',
    });
    console.log('mime ', mimeType);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current?.stream, {
      mimeType,
    });

    mediaRecorderRef.current?.addEventListener('dataavailable', handleDataAvailable);
    
    mediaRecorderRef.current?.addEventListener('stop', () => {
      if (mediaRecorderRef.current?.state !== 'recording') {
        setCapturing(false);
      }
    });
    mediaRecorderRef.current?.start();
  }, [handleDataAvailable]);
  
  const handleStopCaptureClick = React.useCallback(() => {
    tracker.track(`Video recording - stopped`, {
      email: authUser?.email || 'Candidate',
    });
    mediaRecorderRef.current?.stop();
  }, []);
  
  const onUploadComplete = async ({ url }: IUploadCallbackData) => {
    setRemoteUrl(url);
    wipeLocalData();
    onVideoUrl?.(url);
    
    tracker.track(`Video recording - got remote url`, {
      email: authUser?.email || 'Candidate',
    });
    
    if (onSpeechRecognized) {
      setTranscribing(true);
      const res = await transcribeVideo(url);
      console.log('##transcribed:', res);
      
      if (res?.fullText) {
        setTextVersion(res.fullText);
      }
      setTranscribing(false);
    }
  }

  const handleSaveVideo = React.useCallback(() => {
    const folder = authUser?.uid || 'candidates';
    
    tracker.track(`Video recording - saving`, {
      email: authUser?.email || 'Candidate',
    });
    
    if (fileObj) {
      upload(fileObj, onUploadComplete, `videos/${folder}`);
    }
  }, [authUser?.uid, fileObj, onUploadComplete, upload]);

  const wipeLocalData = () => {
    if (videoUrl) {
      URL.revokeObjectURL(videoUrl);
      setVideoUrl('');
    }
    setRecordedChunks([]);
    setFileObj(null);
  }
  
  const reset = () => {
    wipeLocalData();
    setRemoteUrl('');
    onVideoUrl?.('');
    setTextVersion(null);
    
    tracker.track(`Video recording - reset`, {
      email: authUser?.email || 'Candidate',
    });
  }
  
  const onPreviewLoad = (e: any) => {
    fixVideoDuration(previewRef.current);
  }
  
  const onMicChange = async (deviceId: string) => {
    const newOptions: any = {...defaultAudioConstraints};
    newOptions.deviceId = {
      exact: deviceId
    };
    setAudioConstraints(newOptions);
  }

  const onCamChange = async (deviceId: string) => {
    const newOptions: any = {...defaultVideoConstraints};
    newOptions.deviceId = {
      exact: deviceId
    };
    setVideoConstraints(newOptions);
  }
  
  const onUserMedia = () => {
    console.log('onUserMedia');
    setHasPermissions(true);
    
    tracker.track(`Video recording - onUserMedia`, {
      email: authUser?.email || 'Candidate',
    });
  }
  
  const onUserMediaError = (error: any) => {
    console.log('onUserMediaError', error);
    
    tracker.track(`Video recording - onUserMediaError`, {
      email: authUser?.email || 'Candidate',
    });
  }
  
  const audioFix = (e: any) => {
    e.target.volume = 0;
  }
  
  const currentUrl = remoteUrl || videoUrl || '';

  const onCopyVideoUrl = () => {
    navigator.clipboard.writeText(currentUrl);

    showNotification({
      color: 'success',
      content: 'URL copied!',
    });
  }

  const onVideoDownload = () => {
    downloadFile(currentUrl);
  }

  return (
    <div className={`video-input-ctn ${className}`}>
      {hasPermissions && !currentUrl && !disabled && <DevicePicker
        onMicChange={onMicChange}
        onCameraChange={onCamChange}
        disabled={capturing}
      />}
      <div className="video-frame mb-3">
        {!currentUrl && !disabled && <Webcam
          width={'100%'}
          audio={true}
          mirrored={false}
          ref={webcamRef}
          videoConstraints={videoConstraints}
          audioConstraints={audioConstraints}
          onUserMedia={onUserMedia}
          onUserMediaError={onUserMediaError}
          onLoadStart={audioFix}
          onLoadedData={audioFix}
          onLoadedMetadata={audioFix}
        />}
        {!(noPreview && remoteUrl) && !capturing && currentUrl && <div>
          <video
            width={'100%'}
            src={currentUrl}
            controls
            ref={previewRef}
            onLoadedMetadata={onPreviewLoad}
            onError={() => setIsVideoError(true)}
          />
          {/*<Button onClick={onVideoDownload}>Download video</Button>*/}
          {isVideoError && <div className={'mb-2'}>
            Current browser can't show this video.
            Please, change browser or copy URL and paste into another browser (Chrome preferably).
            <div>
              <Button onClick={onCopyVideoUrl}>Copy video URL</Button>
            </div>
          </div>}
        </div>}
        {!remoteUrl && !capturing && !!progress && <div className="progress-label">
          Uploading video {`${Math.round(progress)}%`}
        </div>}
      </div>
      {!disabled && hasPermissions && <div className="controls mb-3">
        {!currentUrl && <>
          {capturing ? (
            <Button onClick={handleStopCaptureClick}>
              Stop recording
            </Button>
          ) : (
            <Button onClick={handleStartCaptureClick}>
              Start recording
            </Button>
          )}
          <Timer start={capturing} onLimit={handleStopCaptureClick} limit={getDurationInSeconds(maxDuration)} />
        </>}
        
        {recordedChunks.length > 0 && !capturing && (
          <Button className={'save-btn btn'} onClick={handleSaveVideo}>
            Save video
          </Button>
        )}
        {currentUrl && !capturing && (
          <Button className='delete-video-btn' onClick={reset}>
            Delete and record again
          </Button>
        )}
      </div>}
      {!disabled && !remoteUrl && hasPermissions && <p className={'text-center'}>
        Make sure to press "Save video" after successful recording
      </p>}
      {!disabled && !hasPermissions && <p className={'text-center'}>
        You need to provide access to your microphone and camera to proceed.
      </p>}
      {/*<div className={`text-results`}>*/}
      {/*  {transcribing && <span>Transcribing video, please wait...</span>}*/}
      {/*  {typeof textVersion === 'string' && !transcribing &&*/}
      {/*    <TextAreaInput*/}
      {/*      value={textVersion}*/}
      {/*      onInput={setTextVersion}*/}
      {/*      disabled={disabled}*/}
      {/*    />*/}
      {/*  }*/}
      {/*</div>*/}
    </div>
  );
}

export default VideoInput;