import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useState } from 'react';
import { storage } from '../../services/firebase.service';

export interface IUploadCallbackData {
  url: string;
  gsUrl: string;
}
const useFileUpload = () => {
  const [progress, setProgress] = useState(0);
  
  const upload = async (file: File, cb: (data: IUploadCallbackData) => void, folder = 'images') => {
    const metadata = {
      contentType: file.type,
    };
    const filePath = `${folder}/${file.name}_${Date.now()}`;
    const storageRef = ref(storage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);
    
    uploadTask.on('state_changed', (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            console.log('User doesn\'t have permission to access the object');
            break;
          case 'storage/canceled':
            console.log('User canceled the upload');
            break;
          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            console.log('Unknown upload error occurred', error);
            break;
        }
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          const gsUrl = uploadTask.snapshot.ref.toString();
          
          cb({
            url: downloadURL,
            gsUrl,
          });
          setProgress(0);
        });
      }
    );
  }

  return {
    upload,
    progress,
  }
  
}

export default useFileUpload;