import React, { FC, useState, useMemo, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import QuestionPreview from '../../question/QuestionPreview/QuestionPreview';
import Button from 'react-bootstrap/Button';
import useNotifications from '../../notification/hooks/useNotifications';

interface IQuestionsCarouselProps {
  questions: any[];
  handleAnswerChange: (answerType: IBasePropType, questionId: string, val: string) => void;
  answersMap: Record<string, any>;
  assignCurrentQuestionIndex: (index: number) => void;
  defaultQuestionIndex?: number;
}

const QuestionsCarousel: FC<IQuestionsCarouselProps> = ({
  questions,
  handleAnswerChange,
  answersMap, assignCurrentQuestionIndex,
  defaultQuestionIndex,
}) => {
  const [index, setIndex] = useState(defaultQuestionIndex || 0);
  const { showNotification } = useNotifications();

  useEffect(() => {
    assignCurrentQuestionIndex(index);
  }, [index]);
  
  const isCurrentStepHasValue: boolean = useMemo(() => {
    const currQuestion = questions[index];
    
    return !!answersMap[currQuestion?.id]?.answerVal;
  }, [questions, index, answersMap]);
  
  const onNextClicked = () => {
    if (isCurrentStepHasValue) {
      setIndex(index + 1);
    } else {
      showNotification({
        color: 'primary',
        content: 'Please, answer current question first',
      });
    }
  }
  
  return (
    <div className="questions-ctn">
      <Carousel
        activeIndex={index}
        slide={false}
        controls={false}
        indicators={false}
        keyboard={false}
      >
        {questions?.map((q: any, i: number) => {
          q.answer.disabled = i !== index;

          const storedAnswer = answersMap[q.id]?.answerVal
          
          return <Carousel.Item key={q.id}>
            <QuestionPreview
              questionEntity={q}
              onInput={handleAnswerChange}
              storedAnswer={storedAnswer}
            />
          </Carousel.Item>
        })}
      </Carousel>
      <div className="questions-list-controls mb-3">
        <Button
          variant={'secondary'}
          onClick={() => setIndex(index - 1)}
          disabled={index === 0}
        >
          Prev
        </Button>
        <span className="num">
          {index + 1} / {questions?.length}
        </span>
        <Button
          variant={'secondary'}
          onClick={onNextClicked}
          disabled={index === questions?.length - 1}
        >
          Next
        </Button>
      </div>
    </div>
  )
}

export default QuestionsCarousel;