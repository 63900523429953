import React, { useMemo, FC } from 'react';
import SelectInput, { SelectValueType } from '../../components/SelectInput/SelectInput';
import useBaseProps from './hooks/useBaseProps';

interface IBasePropsPicker {
  onInput?: (type: SelectValueType) => void;
  label?: string;
  initialValue?: SelectValueType;
  className?: string;
}

const BasePropsPicker: FC<IBasePropsPicker> = ({ onInput, label, initialValue, className='' }) => {
  const { baseProps} = useBaseProps();
  
  const options = useMemo(() => {
    return baseProps?.map(({id, type}) => ({
      id,
      val: type,
      displayVal: type,
    }))
  }, [baseProps]);
  
  return (
    <>
      <SelectInput
        className={className}
        value={initialValue}
        options={options}
        onInput={onInput}
        label={label ?? ''}
      />
    </>
  );
};

export default BasePropsPicker;