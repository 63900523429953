import { configureStore } from '@reduxjs/toolkit';
import authUser from './modules/auth/redux/user.slice';
import baseProps from './modules/baseProp/redux/baseProps.slice';
import questions from './modules/question/redux/questions.slice';
import notifications from './modules/notification/redux/notification.slice';
import interviews from './modules/interviews/redux/interviews.slice';
import currentCompany from './modules/company/redux/currentCompany.slice'
import billing from './modules/billing/redux/billing.slice';

export const store = configureStore({
  reducer: {
    authUser,
    baseProps,
    questions,
    notifications,
    interviews,
    currentCompany,
    billing,
  },
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;