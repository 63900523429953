import React, { FC, ReactNode, useState, useCallback, useEffect } from 'react';
import Toast from 'react-bootstrap/Toast';
import { Variant } from 'react-bootstrap/types';
import './Notification.scss';
import useNotifications from './hooks/useNotifications';

interface INotificationProps {

}
const Notification: FC<INotificationProps> = () => {
  const [show, setShow] = useState(false);
  const { content, header, color, clearNotification} = useNotifications();
  
  const hide = useCallback(() => {
    setShow(false);
    clearNotification();
  }, [])
  
  useEffect(() => {
    if (content) {
      setShow(true);
    }
  }, [content])
  
  return (
    <Toast
      className={`notification-ctn d-inline-block m-1 ${color}`}
      bg={color}
      onClose={hide}
      onClick={hide}
      show={show}
      autohide
    >
      {header && <Toast.Header>
        {header}
      </Toast.Header>}
      <Toast.Body className={`notification-content`}>
        {content}
      </Toast.Body>
    </Toast>
  );
}

export default Notification;